import StatusFsf from "@views/components/status_of_fsf/status_of_fsf";
import { Box } from "native-base";
import React from "react";

const StatusOfFsfPage = () => {
  return (
    <Box>
      <StatusFsf />
    </Box>
  );
};
export default StatusOfFsfPage;
