import LfadAct from "@views/components/lfad_act_component/lfad_act";
import { Box } from "native-base";
import React from "react";

const LfadActPage = () => {
  return (
    <Box>
      <LfadAct />
    </Box>
  );
};
export default LfadActPage;
