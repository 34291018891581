import { Card } from "antd";
import { Box, Circle, HStack, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import Manual from "@assets/images/Manual.png";
import { useDispatch } from "react-redux";
import { get_cms_rti_query } from "@services/redux/slices/graphql/graphql_cms_rti_query";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { FilterItems } from "@helpers/constants";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import PageLoading from "../about_us/page_loading";

const Rti = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [rti_data, setRtiData] = useState([]);

  const { items: rti_items, loading: rti_loading } =
    useDynamicSelector("get_cms_rti");

  const get_rti_query = () => {
    let keys = [{ key: "get_cms_rti", loading: true }];
    let variables = {
      json: {
        system_cms_key: "rti",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_rti_query, variables));
  };

  useEffect(() => {
    get_rti_query();
  }, []);
  useEffect(() => {
    let values = FilterItems(rti_items);
    setRtiData(values);
  }, [rti_items]);

  const Contents = [
    {
      title: "Right to information manual:",
      text1: " http://rti.gov.in/rti-act.pdf",
      text2: " http://rti.gov.in/webactrti.htm",
    },
  ];
  return (
    <Box variant="contents_box">
      <Card
        style={{
          backgroundColor: "#fcfcfc",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        }}
      >
        <Box variant="heading_box">
          <Circle bg={"#fcfcfc"} size={"140px"}>
            <img src={Manual} width={"65px"} />
          </Circle>
          <Text variant={"rti_heading_text"}>{t("rti")}</Text>
        </Box>
        <VStack space={"20px"}>
          {rti_loading ? (
            <PageLoading />
          ) : (
            <>
              {rti_data?.map((e) => {
                return (
                  <>
                    <Text>{ReactHtmlParser(e?.content?.value)}</Text>
                    {/* <Box style={{ gap: "10px", marginTop: "20px" }}>
                  <Text variant={"rti_title_text"}>{e.title}</Text>
                  <HStack space="5px">
                    <Text variant={"format_text"}>{"1.pdf format:"}</Text>
                    <a href={""} target="_blank">
                      <Text variant={"rti_description"} underline>
                        {e.text1}
                      </Text>
                    </a>
                  </HStack>
                  <HStack space="5px">
                    <Text variant={"format_text"}>{"2.jsp format"}</Text>
                    <a href={""} target="_blank">
                      <Text variant={"rti_description"} underline>
                        {e.text2}
                      </Text>
                    </a>
                  </HStack>
                </Box> */}
                  </>
                );
              })}
            </>
          )}
        </VStack>
      </Card>
    </Box>
  );
};
export default Rti;
