import { Card } from "antd";
import { Box, Circle, Text } from "native-base";
import React, { useEffect, useState } from "react";
import ListOfPioTable from "./list_pio_table";
import AppellateTable from "./appellate_table";
import ListImage from "@assets/images/List.png";
import { useDispatch } from "react-redux";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { FilterItems } from "@helpers/constants";
import { get_cms_list_of_pio_apio_query } from "@services/redux/slices/graphql/graphql_cms_list_of_pio_apio_query";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import PageLoading from "../about_us/page_loading";

const ListOfPioApio = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [pio_data, setPioData] = useState([]);

  const { items: pio_items, loading: pio_loading } = useDynamicSelector(
    "get_cms_list_pio_apio"
  );

  const get_list_pio_apio_query = () => {
    let keys = [{ key: "get_cms_list_pio_apio", loading: true }];
    let variables = {
      json: {
        system_cms_key: "list_of_pio_apio",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_list_of_pio_apio_query, variables));
  };

  useEffect(() => {
    get_list_pio_apio_query();
  }, []);
  useEffect(() => {
    let values = FilterItems(pio_items);
    setPioData(values.sort((a, b) => a?.order?.value - b?.order?.value));
  }, [pio_items]);
  return (
    <Box px="100px" py="100px">
      <Card
        style={{
          backgroundColor: "#fcfcfc",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        }}
      >
        <Box
          alignItems={"center"}
          style={{
            marginTop: "-90px",
          }}
        >
          <Circle bg={"#fcfcfc"} size={"140px"}>
            <img src={ListImage} width={"65px"} />
          </Circle>
          <Text
            variant={"list_of_pio_heading"}
          >
            {t("list_of_pio_apio")}
          </Text>
        </Box>
        {pio_loading ? (
          <PageLoading />
        ) : (
          <>
            {pio_data?.map((e) => {
              return (
                <>
                  <Box alignItems={"center"} py={"25px"}>
                    <Text
                    variant={"list_of_pio_sub_text"}
                      // underline
                    >
                      {e?.title?.value}
                    </Text>
                    <Text>{ReactHtmlParser(e?.content?.value)}</Text>
                  </Box>
                  <Box padding={"20px"}>{/* <ListOfPioTable /> */}</Box>
                  {/* <Box alignItems={"center"} py={"25px"}>
          <Text
            fontSize={"18px"}
            color={"#002555"}
            fontFamily={"Montserrat-Medium"}
            // underline
          >
            List of Appellate Authorities:
          </Text>
        </Box>
        <Box padding={"20px"}>
          <AppellateTable />
        </Box> */}
                </>
              );
            })}
          </>
        )}
      </Card>
    </Box>
  );
};
export default ListOfPioApio;
