import Functions from "@views/components/functions_page/functions";
import { Box } from "native-base";
import React from "react";

const FunctionPage = () => {
  return (
    <Box>
      <Functions />
    </Box>
  );
};
export default FunctionPage;
