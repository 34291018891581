import AuditFees from "@views/components/audit_fees/audit_fees";
import { Box } from "native-base";
import React from "react";

const AuditFeesPage = () => {
  return (
    <Box>
      <AuditFees />
    </Box>
  );
};
export default AuditFeesPage;
