import { Card } from "antd";
import { Box, Circle, HStack, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import Document from "@assets/files/LFAD-AuditorMaster.xlsx";
import Document1 from "@assets/files/LFAD-Institution Master.xlsx";
import Document2 from "@assets/files/Establishment.xlsx";
import Document3 from "@assets/files/Probation-Regularization-details.xlsx";
import Document4 from "@assets/files/Offices-Served.xlsx";
import LinkImage from "@assets/images/important links.png";
import { useDispatch } from "react-redux";
import { get_cms_important_links_query } from "@services/redux/slices/graphql/graphql_cms_important_links_query";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { FilterItems } from "@helpers/constants";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import PageLoading from "../about_us/page_loading";

const ImportantLinks = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [important_data, setImportantData] = useState([]);
  const { items: important_items, loading: important_loading } =
    useDynamicSelector("get_cms_important_links");

  const get_important_links_query = () => {
    let keys = [{ key: "get_cms_important_links", loading: true }];
    let variables = {
      json: {
        system_cms_key: "important_links",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_important_links_query, variables));
  };
  useEffect(() => {
    get_important_links_query();
  }, []);

  useEffect(() => {
    let values = FilterItems(important_items);
    setImportantData(values);
  }, [important_items]);
  const Contents = [
    {
      text1: "AIMS -Auditor Master (Applicable for LF & IA departments)",
      text2: "AIMS -Institution Master (Applicable for LF departments only)",
      text3: "Establishment List",
      text4: "Probation-Regularization-details",
      text5: "Offices-Served",
    },
  ];
  return (
    <Box variant="contents_box">
      <Card
        style={{
          backgroundColor: "#fcfcfc",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        }}
      >
        <Box variant="heading_box">
          <Circle bg={"#fcfcfc"} size={"140px"}>
            <img src={LinkImage} width={"65px"} />
          </Circle>
          <Text variant={"important_links_heading_text"}>
            {t("important_links")}
          </Text>
        </Box>
        <VStack space={"20px"}>
          {important_loading ? (
            <PageLoading />
          ) : (
            <>
              {important_data?.map((e) => {
                return (
                  <>
                    <Box style={{ gap: "10px", marginTop: "20px" }}>
                      <Text>{ReactHtmlParser(e?.content?.value)}</Text>
                      {/* <HStack space="5px">
                    <Text>{"●"}</Text>
                    <a href={Document} target="_blank">
                      <Text variant={"important_link_text"} underline>
                        {e.text1}
                      </Text>
                    </a>
                  </HStack>
                  <HStack space="5px">
                    <Text>{"●"}</Text>
                    <a href={Document1} target="_blank">
                      <Text variant={"important_link_text"} underline>
                        {e.text2}
                      </Text>
                    </a>
                  </HStack>
                  <HStack space="5px">
                    <Text>{"●"}</Text>
                    <a href={Document2} target="_blank">
                      <Text variant={"important_link_text"} underline>
                        {e.text3}
                      </Text>
                    </a>
                  </HStack>
                  <HStack space="5px">
                    <Text ml="25px">{"○"}</Text>
                    <a href={Document3} target="_blank">
                      <Text
                        variant={"important_link_text"}
                        underline

                        // ml="25px"
                      >
                        {e.text4}
                      </Text>
                    </a>
                  </HStack>
                  <HStack space="5px">
                    <Text ml="25px">{"○"}</Text>
                    <a href={Document4} target="_blank">
                      <Text
                        variant={"important_link_text"}
                        underline

                        // ml="25px"
                      >
                        {e.text5}
                      </Text>
                    </a>
                  </HStack> */}
                    </Box>
                  </>
                );
              })}
            </>
          )}
        </VStack>
      </Card>
    </Box>
  );
};
export default ImportantLinks;
