import { gql } from "@apollo/client";

export const get_cms_query = gql`
  query getSystemCmss($json: get_all_system_cms_input) {
    getSystemCmss(json: $json) {
      items {
        id
        key
        type
        system_cms_names {
          name
          system_cms_id
          system_cms_language_id
        }
        system_cms_fields {
          id
          key
          type
          system_cms_field_names {
            id
            name
            system_cms_language {
              id
              key
            }
            system_cms_language_id
          }
          system_cms_field_options {
            id
            key
            system_cms_field_option_values {
              id
              value
              system_cms_language_id
            }
          }
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_one_cms_query = gql`
  query getSystemCms($json: get_system_cms_input!) {
    getSystemCms(json: $json) {
      id
      key
      type
      system_cms_names {
        id
        name
        system_cms_id
        system_cms_language_id
      }
      system_cms_fields {
        id
        key
        type
        system_cms_field_names {
          id
          name
          system_cms_language {
            id
            key
          }
          system_cms_language_id
        }
        system_cms_field_options {
          id
          key
          system_cms_field_option_values {
            id
            value
            system_cms_language_id
          }
        }
      }
    }
  }
`;

export const deletecmsMutation = gql`
  mutation deleteCMS($json: delete_system_cms_input!) {
    deleteSystemCms(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const createcmsMutation = gql`
  mutation createCMS($json: create_system_cms_input) {
    createSystemCms(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const updatecmsMutation = gql`
  mutation updateCMS($json: update_system_cms_input) {
    updateSystemCms(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const fileQuery = gql`
  query getUploadUrl($type: String, $fileName: String) {
    getUploadUrl(type: $type, fileName: $fileName) {
      status
      url
      error {
        message
      }
    }
  }
`;
export const get_cms_languages_query = gql`
  query getAllCMSLanguages {
    getSystemLanguages {
      items {
        id
        name
        key
      }
    }
  }
`;
