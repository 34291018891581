import { Card } from "antd";
import { Box, Circle, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import TrainingImage from "@assets/images/training.png";
import { get_cms_training_query } from "@services/redux/slices/graphql/graphql_cms_training_query";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { useDispatch } from "react-redux";
import { FilterItems } from "@helpers/constants";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import PageLoading from "../about_us/page_loading";


const Training = () => {
  const {t}=useTranslation();
  const dispatch= useDispatch();
  const [training_data, setTrainingData]=useState([]);
  const { items: training_items, loading: training_loading } = useDynamicSelector(
    "get_cms_training"
  );
  const TrainingContent = [
    {
      title: "Training",
      description1:
        "The Director of Local Fund Audit being the cadre controlling authority for both the Department of Local Fund Audit and the Internal Audit & Statutory Boards Audit Department, trainings are conducted to the combined staff from both the departments.",
      description2:
        "Mainly two kinds of trainings are imparted at the basic level.",
      description3:
        "The training programmes are coordinated by Deputy Director (Training), designated in the Directorate.",
    },
  ];
  const InductionContent = [
    {
      title: "Induction Training",
      description1:
        "The Assistant Inspectors are the basic audit staff in the department. So, the Assistant Inspectors recruited through TNPSC were given three month of class room and field training on the basics of auditing before inducting into actual work.",
      description2:
        "The training programme covers fundamentals of Establishment rules, office procedures and various aspects of auditing principles. An overall guideline on the syllabus for department tests is offered in addition.",
      description3:
        "The instructors are chosen from the cadre of Inspector, who exemplify good teaching skills along with proven record of excellent auditing practices.",
    },
  ];

  const PerformanceContent = [
    {
      title: "Performance â€“ enhancement Training.",
      description1:
        "There is an inevitable need for the auditors to keep themselves updated to adapt to the corresponding changes in auditable institutions. An exclusive state-of-the art air-conditioned class room for training is allotted in the Directorate.",
      description2:
        "Different training programmes aimed at equipping the auditing personnel is conducted from time to time. The Programmes are designed in such a way to eliminate the feeling of being out-dated to new changes. So the auditors are trained with the examples of real-time situations and are exposed to different challenges. Such trainings prove to be beneficial in enhancing the performance of auditors and improving the standard of audit.",
    },
  ];

  const get_training_query = () => {
    let keys = [{ key: "get_cms_training", loading: true }];
    let variables = {
      json: {
        system_cms_key: "training_content",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_training_query, variables));
  };

  useEffect(()=>{
    get_training_query();
  },[])
  useEffect(() => {
    let values = FilterItems(training_items);
    setTrainingData(values);
  }, [training_items]);

  return (
    <Box variant={"contents_box"}>
      <Card
        style={{
          backgroundColor: "#fcfcfc",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        }}
      >
        <Box variant={"heading_box"}>
          <Circle bg={"#fcfcfc"} size={"140px"}>
            <img src={TrainingImage} width={"65px"} />
          </Circle>
          <Text variant={"training_heading_text"}>{t("training")}</Text>
        </Box>
        <VStack space="20px" p="20px" marginTop={"10px"}>
          <Box
            style={{
              width: "100%",
              gap: "20px",
            }}
          >
            {training_loading ? <PageLoading/> : (
              <>
             
            {training_data.map((e) => {
              return (
                <>
                  <Text>{ReactHtmlParser(e?.content?.value)}</Text>
                  {/* <Text variant={"training_title_text"}>{e.title}</Text>
                  <Text variant={"training_description_text"}>
                    {e.description1}
                  </Text>
                  <Text variant={"training_description_text"}>
                    {e.description2}
                  </Text>
                  <Text variant={"training_description_text"}>
                    {e.description3}
                  </Text> */}
                </>
              );
            })}
              </>
          )}
          </Box>
          {/* <Box
            style={{
              width: "100%",
              gap: "20px",
            }}
          >
            {InductionContent.map((e) => {
              return (
                <>
                  <Text variant={"training_title_text"}>{e.title}</Text>
                  <Text variant={"training_description_text"}>
                    {e.description1}
                  </Text>
                  <Text variant={"training_description_text"}>
                    {e.description2}
                  </Text>
                  <Text variant={"training_description_text"}>
                    {e.description3}
                  </Text>
                </>
              );
            })}
          </Box>
          <Box
            style={{
              width: "100%",
              gap: "20px",
            }}
          >
            {PerformanceContent.map((e) => {
              return (
                <>
                  <Text variant={"training_title_text"}>{e.title}</Text>
                  <Text variant={"training_description_text"}>
                    {e.description1}
                  </Text>
                  <Text variant={"training_description_text"}>
                    {e.description2}
                  </Text>
                </>
              );
            })}
          </Box> */}
        
        </VStack>
      </Card>
    </Box>
  );
};
export default Training;
