import Authority from "@views/components/authority_page/authority_page";
import Mission from "@views/components/mission_page/mission";
import { Box } from "native-base";
import React from "react";

const AuthorityPage = (props) => {
  const { title, description1, description2 ,authority_data} = props;
  return (
    <Box height={{xs:"600px",md:"700px",lg:"400px"}}>
      <Authority title={title} description1={description1} authority_data={authority_data} />
    </Box>
  );
};
export default AuthorityPage;
