import CryptoJS from "crypto-js";
import axios from "axios";
import i18n from "i18next";
import { t } from "i18next";

import { message as antdMessage } from "antd";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuidv4 } from "uuid";

import { QueryRequest } from "@services/apollo/api_service";
import { historyRef } from "@views/routes/Router";
import { fileQuery } from "./constants";
import moment from "moment";
import { Text } from "native-base";

export function getUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const encryptData = (data, salt) =>
  CryptoJS.AES.encrypt(JSON.stringify(data), salt).toString();

export const decryptData = (cipher_text, salt) => {
  const bytes = CryptoJS.AES.decrypt(cipher_text, salt);
  try {
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (err) {
    return null;
  }
};

export function storeItem(key, data) {
  const salt = process.env.REACT_APP_SALT || "Ytdnjdfdkfnkdfhkdfkdfldfh37djrk";
  let value = JSON.stringify(data);
  let encryptedKey = window.btoa(key);
  let encryptedValue = encryptData(value, salt);
  window.localStorage.setItem(encryptedKey, encryptedValue);
  window.dispatchEvent(new Event(`${key}`));
}

export function retrieveItem(key) {
  const salt = process.env.REACT_APP_SALT || "Ytdnjdfdkfnkdfhkdfkdfldfh37djrk";
  try {
    let encryptedKey = window.btoa(key);
    let value = window.localStorage.getItem(encryptedKey);
    const decryptedValue = decryptData(value, salt);
    return JSON.parse(decryptedValue);
  } catch (e) {
    return null;
  }
}

export function clearLocalStorage() {
  try {
    window.localStorage.clear();
  } catch (e) {
    return;
  }
}

export async function fileUpload(file, storageName) {
  let url = await getURL(storageName, file?.name);

  let final_url = url?.split("?")[0];
  let success = true;
  await axios
    .put(url, file, {
      headers: { "Content-Type": file?.type },
    })
    .then(async (res) => {
      if (res.status.toString() === "200") {
        // return final_url;
      } else {
        success = false;
      }
    });
  if (success) {
    return final_url;
  }

  async function getURL(type, fileName) {
    let { data } = await QueryRequest(fileQuery, { type, fileName });
    return data.getUploadUrl.url;
  }
}

export function useIsRTL() {
  const localeRTLList = ["ar", "he"];
  let locale = i18n.language || "en";
  if (locale && localeRTLList.includes(locale)) {
    return { isRTL: true, alignLeft: "right", alignRight: "left" };
  }
  return { isRTL: false, alignLeft: "left", alignRight: "right" };
}

export const navigate = (path) => {
  historyRef.current.push(path);
};

export const navigateWithProps = (path, props) => {
  historyRef.current.push({
    pathname: path,
    props_state: props,
  });
};

export const navigateBack = () => {
  historyRef.current.goBack();
};

export function showNotification({ type, message, duration = 3 }) {
  switch (type) {
    case "success":
      antdMessage.success(message, duration);
      break;
    case "warn":
      antdMessage.warning(message, duration);
      break;
    case "error":
      antdMessage.error(message, duration);
      break;
    default:
      antdMessage.info(message, duration);
  }
}

export function showToast({ type, message, duration = 5000, id = uuidv4() }) {
  if (id && toast.isActive(id)) {
    return;
  }

  switch (type) {
    case "info":
      toast.info(message, {
        toastId: id,
        position: "top-right",
        autoClose: duration,
        closeOnClick: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      break;
    case "success":
      toast.success(message, {
        toastId: id,
        position: "top-right",
        autoClose: duration,
        closeOnClick: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      break;
    case "warn":
      toast.warn(message, {
        toastId: id,
        position: "top-right",
        autoClose: duration,
        closeOnClick: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      break;
    case "error":
      toast.error(message, {
        toastId: id,
        position: "top-right",
        autoClose: duration,
        closeOnClick: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      break;
    default:
      toast(message, {
        toastId: id,
        position: "top-right",
        autoClose: duration,
        closeOnClick: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
  }
}

export function validateEmail(email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

export function getProfileUrl(url, member_gender) {
  if (url && member_gender) {
    return url;
  } else if (member_gender === "f" && !url) {
    return "https://cdn.standardmatrimony.com/female-avatar.png";
  } else if (member_gender === "m" && !url) {
    return "https://cdn.standardmatrimony.com/male-avatar.png";
  }
}
export function getAmountFormat(amount) {
  if (amount) {
    return amount
      .toLocaleString("en-IN", {
        style: "currency",
        currency: "INR",
      })
      .replace("₹", "");
  } else {
    return `0.00`;
  }
}

export const formatDate = (date) => {
  if (!date) {
    return "-";
  }
  return moment(date).format("DD/MM/YYYY");
};

export const formatDateTime = (date) => {
  if (!date) {
    return "-";
  }
  return moment(date).format("DD/MM/YYYY hh:mm a");
};

export const hasExceededDate = (checkDate) => {
  const currentDate = moment();
  const exceeded = currentDate.isAfter(moment(checkDate));
  return exceeded;
};

export const getTransitionStatus = (status) => {
  if (!status) {
    return "-";
  }
  if (status === "submitted_by_institution") {
    return t("submitted_by_institution");
  } else if (status === "submitted_by_zone") {
    return t("submitted_by_zone");
  } else if (status === "approved_by_district") {
    return t("approved_by_district");
  } else if (status === "rejected_by_district") {
    return t("rejected_by_district");
  } else if (status === "approved_by_zone") {
    return t("approved_by_zone");
  } else if (status === "rejected_by_zone") {
    return t("rejected_by_zone");
  } else if (status === "approved_by_assistant") {
    return t("approved_by_assistant");
  } else if (status === "rejected_by_assistant") {
    return t("rejected_by_assistant");
  } else if (status === "approved_by_head_accountant") {
    return t("approved_by_head_accountant");
  } else if (status === "rejected_by_head_accountant") {
    return t("rejected_by_head_accountant");
  } else if (status === "approved_by_senior_accountant") {
    return t("approved_by_senior_accountant");
  } else if (status === "rejected_by_senior_accountant") {
    return t("rejected_by_senior_accountant");
  } else if (status === "approved_by_ceo") {
    return t("approved_by_ceo");
  } else if (status === "rejected_by_ceo") {
    return t("rejected_by_ceo");
  } else if (status === "Draft") {
    return t("draft");
  } else {
    return "-";
  }
};
export const getTransitionLogStatus = (items) => {
  const role = retrieveItem("role");
  let can_approve = false;
  let temp = items?.[0]?.entity_state?.name;
  if (role === "Inspector" && temp === "submitted_by_institution") {
    can_approve = true;
    return can_approve;
  } else if (role === "Inspector" && temp === "rejected_by_zone") {
    can_approve = true;
    return can_approve;
  } else if (role === "Superintendent" && temp === "approved_by_district") {
    can_approve = true;
    return can_approve;
  } else if (role === "Superintendent" && temp === "rejected_by_assistant") {
    can_approve = true;
    return can_approve;
  } else if (role === "Assistant" && temp === "approved_by_zone") {
    can_approve = true;
    return can_approve;
  } else if (role === "Assistant" && temp === "submitted_by_zone") {
    can_approve = true;
    return can_approve;
  } else if (role === "Assistant" && temp === "rejected_by_head_accountant") {
    can_approve = true;
    return can_approve;
  } else if (role === "Head Accountant" && temp === "approved_by_assistant") {
    can_approve = true;
    return can_approve;
  } else if (
    role === "Head Accountant" &&
    temp === "rejected_by_senior_accountant"
  ) {
    can_approve = true;
    return can_approve;
  } else if (
    role === "Senior Accountant" &&
    temp === "approved_by_head_accountant"
  ) {
    can_approve = true;
    return can_approve;
  } else if (role === "Senior Accountant" && temp === "rejected_by_ceo") {
    can_approve = true;
    return can_approve;
  } else if (role === "CEO" && temp === "approved_by_senior_accountant") {
    can_approve = true;
    return can_approve;
  }
  return can_approve;
};

export const getTransitionLogCustomMessage = (items) => {
  const role = retrieveItem("role");
  let temp = items?.[0]?.entity_state?.name;
  if (role === "Inspector" && temp === "approved_by_district") {
    return t("sent_to_zone");
  } else if (role === "Inspector" && temp === "rejected_by_district") {
    return t("forwarded_to_institution");
  } else if (role === "Inspector" && temp === "approved_by_zone") {
    return t("pending_assistant_approval");
  } else if (role === "Inspector" && temp === "approved_by_assistant") {
    return t("pending_head_accountant_approval");
  } else if (role === "Inspector" && temp === "rejected_by_assistant") {
    return t("forwarded_to_zone");
  } else if (role === "Inspector" && temp === "approved_by_head_accountant") {
    return t("sent_to_senior_accountant");
  } else if (role === "Inspector" && temp === "rejected_by_head_accountant") {
    return t("forwarded_to_assistant");
  } else if (role === "Inspector" && temp === "approved_by_senior_accountant") {
    return t("sent_to_ceo");
  } else if (role === "Inspector" && temp === "rejected_by_senior_accountant") {
    return t("forwarded_to_head_accountant");
  } else if (role === "Inspector" && temp === "approved_by_ceo") {
    return t("payment_pending");
  } else if (role === "Inspector" && temp === "rejected_by_ceo") {
    return t("forwarded_to_senior_accountant");
  } else if (role === "Superintendent" && temp === "submitted_by_institution") {
    return t("pending_district_approval");
  } else if (role === "Superintendent" && temp === "submitted_by_zone") {
    return t("pending_assistant_approval");
  } else if (role === "Superintendent" && temp === "rejected_by_district") {
    return t("forwarded_to_institution");
  } else if (role === "Superintendent" && temp === "approved_by_zone") {
    return t("sent_to_assistant");
  } else if (role === "Superintendent" && temp === "rejected_by_zone") {
    return t("forwarded_to_district");
  } else if (role === "Superintendent" && temp === "approved_by_assistant") {
    return t("sent_to_head_accountant");
  } else if (
    role === "Superintendent" &&
    temp === "approved_by_head_accountant"
  ) {
    return t("sent_to_senior_accountant");
  } else if (
    role === "Superintendent" &&
    temp === "rejected_by_head_accountant"
  ) {
    return t("forwarded_to_assistant");
  } else if (
    role === "Superintendent" &&
    temp === "approved_by_senior_accountant"
  ) {
    return t("sent_to_ceo");
  } else if (
    role === "Superintendent" &&
    temp === "rejected_by_senior_accountant"
  ) {
    return t("forwarded_to_head_accountant");
  } else if (role === "Superintendent" && temp === "approved_by_ceo") {
    return t("payment_pending");
  } else if (role === "Superintendent" && temp === "rejected_by_ceo") {
    return t("forwarded_to_senior_accountant");
  } else if (role === "Assistant" && temp === "submitted_by_institution") {
    return t("pending_district_approval");
  } else if (role === "Assistant" && temp === "approved_by_district") {
    return t("pending_zone_approval");
  } else if (role === "Assistant" && temp === "rejected_by_district") {
    return t("forwarded_to_institution");
  } else if (role === "Assistant" && temp === "rejected_by_zone") {
    return t("forwarded_to_district");
  } else if (role === "Assistant" && temp === "approved_by_assistant") {
    return t("sent_to_head_accountant");
  } else if (role === "Assistant" && temp === "rejected_by_assistant") {
    return t("forwarded_to_zone");
  } else if (role === "Assistant" && temp === "approved_by_head_accountant") {
    return t("sent_to_senior_accountant");
  } else if (role === "Assistant" && temp === "approved_by_senior_accountant") {
    return t("sent_to_ceo");
  } else if (role === "Assistant" && temp === "rejected_by_senior_accountant") {
    return t("forwarded_to_head_accountant");
  } else if (role === "Assistant" && temp === "approved_by_ceo") {
    return t("payment_pending");
  } else if (role === "Assistant" && temp === "rejected_by_ceo") {
    return t("forwarded_to_senior_accountant");
  } else if (
    role === "Head Accountant" &&
    temp === "submitted_by_institution"
  ) {
    return t("pending_district_approval");
  } else if (role === "Head Accountant" && temp === "approved_by_district") {
    return t("pending_zone_approval");
  } else if (role === "Head Accountant" && temp === "rejected_by_district") {
    return t("forwarded_to_institution");
  } else if (role === "Head Accountant" && temp === "approved_by_zone") {
    return t("pending_assistant_approval");
  } else if (role === "Head Accountant" && temp === "submitted_by_zone") {
    return t("pending_assistant_approval");
  } else if (role === "Head Accountant" && temp === "rejected_by_zone") {
    return t("forwarded_to_district");
  } else if (role === "Head Accountant" && temp === "rejected_by_assistant") {
    return t("forwarded_to_zone");
  } else if (
    role === "Head Accountant" &&
    temp === "approved_by_head_accountant"
  ) {
    return t("sent_to_senior_accountant");
  } else if (
    role === "Head Accountant" &&
    temp === "rejected_by_head_accountant"
  ) {
    return t("forwarded_to_assistant");
  } else if (
    role === "Head Accountant" &&
    temp === "approved_by_senior_accountant"
  ) {
    return t("sent_to_ceo");
  } else if (role === "Head Accountant" && temp === "approved_by_ceo") {
    return t("payment_pending");
  } else if (role === "Head Accountant" && temp === "rejected_by_ceo") {
    return t("forwarded_to_senior_accountant");
  } else if (
    role === "Senior Accountant" &&
    temp === "submitted_by_institution"
  ) {
    return t("pending_district_approval");
  } else if (role === "Senior Accountant" && temp === "approved_by_district") {
    return t("pending_zone_approval");
  } else if (role === "Senior Accountant" && temp === "rejected_by_district") {
    return t("forwarded_to_institution");
  } else if (role === "Senior Accountant" && temp === "approved_by_zone") {
    return t("pending_assistant_approval");
  } else if (role === "Senior Accountant" && temp === "submitted_by_zone") {
    return t("pending_assistant_approval");
  } else if (role === "Senior Accountant" && temp === "rejected_by_zone") {
    return t("forwarded_to_district");
  } else if (role === "Senior Accountant" && temp === "approved_by_assistant") {
    return t("pending_head_accountant_approval");
  } else if (role === "Senior Accountant" && temp === "rejected_by_assistant") {
    return t("forwarded_to_zone");
  } else if (
    role === "Senior Accountant" &&
    temp === "rejected_by_head_accountant"
  ) {
    return t("forwarded_to_assistant");
  } else if (
    role === "Senior Accountant" &&
    temp === "approved_by_senior_accountant"
  ) {
    return t("sent_to_ceo");
  } else if (
    role === "Senior Accountant" &&
    temp === "rejected_by_senior_accountant"
  ) {
    return t("forwarded_to_head_accountant");
  } else if (role === "Senior Accountant" && temp === "approved_by_ceo") {
    return t("payment_pending");
  } else if (role === "CEO" && temp === "submitted_by_institution") {
    return t("pending_district_approval");
  } else if (role === "CEO" && temp === "approved_by_district") {
    return t("pending_zone_approval");
  } else if (role === "CEO" && temp === "rejected_by_district") {
    return t("forwarded_to_institution");
  } else if (role === "CEO" && temp === "approved_by_zone") {
    return t("pending_assistant_approval");
  } else if (role === "CEO" && temp === "submitted_by_zone") {
    return t("pending_assistant_approval");
  } else if (role === "CEO" && temp === "rejected_by_zone") {
    return t("forwarded_to_district");
  } else if (role === "CEO" && temp === "approved_by_assistant") {
    return t("pending_head_accountant_approval");
  } else if (role === "CEO" && temp === "rejected_by_assistant") {
    return t("forwarded_to_zone");
  } else if (role === "CEO" && temp === "approved_by_head_accountant") {
    return t("pending_senior_accountant_approval");
  } else if (role === "CEO" && temp === "rejected_by_head_accountant") {
    return t("forwarded_to_assistant");
  } else if (role === "CEO" && temp === "rejected_by_senior_accountant") {
    return t("forwarded_to_head_accountant");
  } else if (role === "CEO" && temp === "approved_by_ceo") {
    return t("payment_pending");
  } else if (role === "CEO" && temp === "rejected_by_ceo") {
    return t("forwarded_to_senior_accountant");
  } else if (
    role === "Institution Incharge" &&
    temp === "submitted_by_institution"
  ) {
    return t("pending_district_approval");
  } else if (
    role === "Institution Incharge" &&
    temp === "approved_by_district"
  ) {
    return t("pending_zone_approval");
  } else if (role === "Institution Incharge" && temp === "approved_by_zone") {
    return t("pending_assistant_approval");
  } else if (role === "Institution Incharge" && temp === "rejected_by_zone") {
    return t("pending_district_approval");
  } else if (
    role === "Institution Incharge" &&
    temp === "approved_by_assistant"
  ) {
    return t("pending_head_accountant_approval");
  } else if (
    role === "Institution Incharge" &&
    temp === "rejected_by_assistant"
  ) {
    return t("pending_zone_approval");
  } else if (
    role === "Institution Incharge" &&
    temp === "approved_by_head_accountant"
  ) {
    return t("pending_senior_accountant_approval");
  } else if (
    role === "Institution Incharge" &&
    temp === "rejected_by_head_accountant"
  ) {
    return t("pending_assistant_approval");
  } else if (
    role === "Institution Incharge" &&
    temp === "approved_by_senior_accountant"
  ) {
    return t("pending_ceo_approval");
  } else if (
    role === "Institution Incharge" &&
    temp === "rejected_by_senior_accountant"
  ) {
    return t("pending_head_accountant_approval");
  } else if (role === "Institution Incharge" && temp === "approved_by_ceo") {
    return t("payment_pending");
  } else if (role === "Institution Incharge" && temp === "rejected_by_ceo") {
    return t("pending_senior_accountant_approval");
  } else {
    return null;
  }
};
