import Rti from "@views/components/rti_component/rti";
import { Box } from "native-base";
import React from "react";

const RtiPage = () => {
  return (
    <Box>
      <Rti />
    </Box>
  );
};
export default RtiPage;
