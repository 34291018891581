import React, { useEffect, useState } from "react";
import { Box, Hidden, Text, Stack, Center } from "native-base";
import { useTranslation } from "react-i18next";

const LatestNews = ({latest_data}) => {
  const { t } = useTranslation();
  // const news_data = [
  //   {
  //     news_name: "Latest News",
  //   },
  // ];


  return (
    <Box>
      <Stack display="flex" flexDirection={{ xs: "column", lg: "column" }}>
        {latest_data?.map((e) => {
          return (
            <Box
              padding="14px"
              bgColor="gray.100"
              height="50px"
            >
              <Text
                fontSize={"16px"}
                font-family="'Inter', sans-serif"
                color="black"
              >
                <Center>
                <marquee direction="left">
                  {e?.event_name?.value}
                  </marquee>
                </Center>
              </Text>
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};
export default LatestNews;
