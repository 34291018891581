import { Card } from "antd";
import { Box, Circle, Text } from "native-base";
import React, { useEffect, useState } from "react";
import Audit from "@assets/images/audit.png";
import { get_cms_auditable_institutions_query } from "@services/redux/slices/graphql/graphql_autitable_institutions_query";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { useDispatch } from "react-redux";
import { FilterItems } from "@helpers/constants";
import PageLoading from "../about_us/page_loading";
import ReactHtmlParser from "react-html-parser";

const AuditableInstitution = () => {
  const dispatch = useDispatch();
  const [institution_data, setInstitutionData] = useState([]);
  const { items: institution_items, loading: institution_loading } =
    useDynamicSelector("get_cms_auditable_institutions");
  const get_auditable_institutions_query = () => {
    let keys = [{ key: "get_cms_auditable_institutions", loading: true }];
    let variables = {
      json: {
        system_cms_key: "autitable_institutions",
      },
    };
    dispatch(
      dynamicRequest(keys, get_cms_auditable_institutions_query, variables)
    );
  };
  useEffect(() => {
    get_auditable_institutions_query();
  }, []);

  useEffect(() => {
    let values = FilterItems(institution_items);
    setInstitutionData(values);
  }, [institution_items]);
  return (
    <Box variant="contents_box">
      {institution_loading ? (
        <PageLoading />
      ) : (
        <>
          {institution_data?.map((item) => {
            return (
              <Card
                style={{
                  backgroundColor: "#fcfcfc",
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                }}
              >
                <Box variant="heading_box">
                  <Circle bg={"#fcfcfc"} size={"140px"}>
                    <img src={Audit} width={"65px"} />
                  </Circle>
                  <Text variant={"auditable_institution_heading"}>
                    {item?.title?.value}
                  </Text>
                </Box>
                <Box padding="20px">
                  {ReactHtmlParser(item?.content?.value)}
                  {/* <InstitutionTable table_list={item?.content?.value} /> */}
                </Box>
              </Card>
            );
          })}
        </>
      )}
    </Box>
  );
};
export default AuditableInstitution;
