import StatusOfHealth from "@views/components/status_of_health_fund/status_of_health";
import { Box } from "native-base";
import React from "react";

const StatusOfHealthPage = () => {
  return (
    <Box>
      <StatusOfHealth />
    </Box>
  );
};
export default StatusOfHealthPage;
