import React from "react";
import { Box, Image } from "native-base";
import TamilNadu from "@assets/images/governmentlogo.png";

const SidebarHeader = (props) => {
  return (
    <Box w={"100%"} h={"100%"} justifyContent="center" alignItems="center">
      <Image
        height="80px"
        width="100%"
        alt="logo"
        resizeMode={"contain"}
        source={TamilNadu}
      />
    </Box>
  );
};

export default SidebarHeader;
