import Mission from "@views/components/mission_page/mission";
import { Box } from "native-base";
import React from "react";

const MissionPage = (props) => {
  const { mission_data } = props;
  console.log("focus1", focus);
  return (
    <Box height="auto">
      <Mission mission_data={mission_data} />
    </Box>
  );
};
export default MissionPage;
