import ListOfPioApio from "@views/components/list_of_pio_apio/list_of_pio_apio";
import { Box } from "native-base";
import React from "react";

const ListOfPioApioPage = () => {
  return (
    <Box>
      <ListOfPioApio />
    </Box>
  );
};
export default ListOfPioApioPage;
