import { Card } from "antd";
import { Box, Circle, Text } from "native-base";
import React, { useEffect, useState } from "react";
import ServiceRule from "@assets/images/service rules.png";
import { useDispatch } from "react-redux";
import { get_cms_service_rules_query } from "@services/redux/slices/graphql/graphql_cms_service_rules_query";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { FilterItems } from "@helpers/constants";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import PageLoading from "../about_us/page_loading";

const ActsRules = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [service_data, setServiceData] = useState([]);
  const { items: service_items, loading: service_loading } = useDynamicSelector(
    "get_cms_service_rules"
  );

  const get_service_rules_query = () => {
    let keys = [{ key: "get_cms_service_rules", loading: true }];
    let variables = {
      json: {
        system_cms_key: "service_rules",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_service_rules_query, variables));
  };
  useEffect(() => {
    get_service_rules_query();
  }, []);

  useEffect(() => {
    let values = FilterItems(service_items);
    setServiceData(values);
  }, [service_items]);
  const Contents = [
    {
      title: "SERVICE RULES",
      subtitle: "SPECIAL RULES FOR TAMIL NADU LOCAL FUND AUDIT SERVICE RULES",
      text1:
        "1) Constitution: The Service shall consist of the following categories of posts, namely :-",
      text2: "    Category I: (1) Examiner of Local Fund Accounts",
      text3: "(2) Chief Auditor, State Trading Schemes Department",
      text4: "    Category II: Joint Examiner of Local Fund Accounts",
      text5: "    Category III: Deputy Examiner of Local Fund Accounts",
      text6:
        "  Category IV : (1) Assistant Examiner of Local Fund Accounts (2) Assistant Chief Auditor, State Trading Schemes Department ( including Personal Assistant to Chief Auditor, State Trading Schemes Department ).",
      text7: "    Category V: Inspector of Local Fund Accounts",
      text8: "2) Appointment:",
      text9:
        "   (1) Appointment to the categories specified in column(1) of the Table below, shall be made by the methods specified in the corresponding entries in column (2) thereof :-",
      text10: "    THE TABLE Categories Method of Appointment",
      text11: "     (1) (2) Category I :",
      text12:
        "     i) By promotion from among the holders of the post in Category II or",
      text13:
        "     ii) By recruitment by transfer from Joint Secretaries to Government (NON-IAS) in the Finance Department; or",
      text14:
        "   iii) By appointment of an Officer of the Indian Audit and Accounts Service on terms agreed upon with the Comptroller and Auditor General of India /2/ Categories",
      text15: "    Method of Appointment (1) (2)",
      text16:
        "    Category II By promotion from among the holders of the post in Category III",
      text17:
        "    Category III By promotion from among the holders of the posts in Category IV",
      text18:
        "    Category IV By promotion from among the holders of the posts in Category V",
      text19:
        "   (1) (i) By recruitment by transfer from among the holders of the post of Deputy Inspector in Category I of the Tamil Nadu Local Fund Audit Subordinate Service.",
      text20:
        "   Category V (1) (ii) If suitable person is not available, for appointment under clause (i), by recruitment by transfer from among the holders of the post of Assistant Inspector in Category 2 of the Tamil Nadu Local Fund Audit Subordinate Services.",
      text21:
        "  (2) Appointment by promotion, to the post in any category, shall be made on grounds of merit and ability, seniority being considered only where merit and ability are approximately equal ;",
      text22:
        "    Provided that for filling up of a single vacancy, the claims of the first two qualified persons in the seniority list in a category shall be considered and that the same proportion shall be maintained in making selection for more than a single vacancy.",
      text23:
        "    Provided further that if the first two qualified persons, after consideration of their claims, are found not suitable for the post, the claims of the next two qualified persons shall be considered.",
      text24:
        "    Provided also that for filling up of a single vacancy, in the posts of Examiner of Local Fund Accounts and the Chief Auditor, State Trading Schemes Department (Head of the Department), the claims of the first five qualified persons in the seniority list in a class, category or service shall be considered following the procedure laid down in the above provisos. (G.O. Ms.No. 45, Personnel and Administrative Reforms ( Per.P ) Department, dated. 20/02/1987 )",
      text25:
        "  3) Appointing Authority. - Appointments to the categories specified in column (1) of the Table below shall be made by the authorities specified in the corresponding entries in column ( 2 ) thereof :--",
      text26:
        "     THE TABLE Categories Appointing Authorities (1) (2) Category I",
      text27: "     (1) Examiner of Local Fund Accounts",
      text28:
        "     (2) Chief Auditor, State Trading Schemes Department Government Category II",
      text29:
        "     Joint Examiner of Local Fund Accounts Government Category III",
      text30: "     Deputy Examiner of Local Fund Accounts Government",
      text31:
        "     Assistant Examiner of Local Fund Accounts in the Local Fund Audit",
      text32:
        "    Category IV Department Assistant Chief Auditor, State Trading Schemes Department ( including Personal Assistant to Chief Auditor, State Trading Schemes Department ) Government",
      text33:
        "     Inspector of Local Fund Accounts in the Local Fund Audit Department Examiner of Local Fund Accounts",
      text34:
        "     Category V Inspector of Local Fund Accounts in the State Trading Schemes Department",
      text35: "     Chief Auditor, State Trading Schemes Dept.",
      text36: "     4) Preparation of annual list of approved Candidates:-",
      text37:
        "      (1) For the purpose of preparation of an annual list of approved candidates for appointment, by promotion or by recruitment by transfer, any of the post in the service, the crucial date on which the candidates shall possess the qualifications prescribed for such a appointment shall be the 1st September of every year",
      text38:
        "      (2) Consultation with the Tamil Nadu Public Service Commission shall not be necessary for making appointment to the posts in the service except to the posts in Category IV",
      text39: "     5) Qualifications:",
      text40:
        "      No person shall be eligible for appointment to the post of Inspector of Local Fund Accounts in Category V unless he possesses the following qualifications, namely:",
      text41:
        "      (1) Must have passed the Subordinate Accounts Services Examination conducted by the Tamil Nadu Public Service Commission in Part I and II in full.",
      text42:
        "      (2) Consultation with the Tamil Nadu Public Service Commission shall not be necessary for making appointment to the posts in the service except to the posts in Category IV",
      text43: "     6) Probation:",
      text44:
        "      (1) Every person appointed to the post of Inspector of Local Fund Accounts in Category V shall from the date on which he joins duty be on probation for a total period of one year on duty within a continuous period of two years.",
      text45:
        "      (2) The persons appointed to the posts in Categories I, II, III and IV shall not be required to undergo a fresh period of probation.",
      text46: "     7) Posting and Transfers:",
      text47:
        "      (1) Posting and Transfer among the holders of the posts in Categories I and II shall be made by the Government",
      text48:
        "      (2) Subject to Sub-rule (3) Posting and transfer among the holders of the posts in categories III, IV and V shall be made by the Examiner of Local Fund Accounts",
      text49:
        "      (3) Posting and Transfer among the holders of the posts in Categories IV and V in the State Trading Schemes Department shall be made by the Chief Auditor, State Trading Schemes Department.",
    },
  ];
  return (
    <Box variant="contents_box">
      <Card
        style={{
          backgroundColor: "#fcfcfc",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        }}
      >
        <Box variant="heading_box">
          <Circle bg={"#fcfcfc"} size={"140px"}>
            <img src={ServiceRule} width={"65px"} />
          </Circle>
          <Text variant={"service_rules_heading_text"}>
            {t("service_rules")}
          </Text>
        </Box>
        <Box
          style={{
            width: "100%",
            gap: "20px",
            marginTop: "10px",
          }}
        >
          {service_loading ? (
            <PageLoading />
          ) : (
            <>
              {service_data.map((e) => {
                return (
                  <>
                    <Text>{ReactHtmlParser(e.content.value)}</Text>
                  </>
                  // <>
                  //   <Text
                  //    variant={"service_rules_title_text"}
                  //   >
                  //     {e.title}
                  //   </Text>
                  //   <Text
                  //     variant={"service_rules_subtitle_text"}
                  //   >
                  //     {e.subtitle}
                  //   </Text>

                  //   <Text
                  //    variant={"service_rules_description_text"}
                  //   >
                  //     {e.text1}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text2}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text3}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text4}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text5}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text6}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text7}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text8}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text9}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text10}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text11}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text12}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text13}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text14}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text15}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text16}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text17}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text18}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text19}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text20}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text21}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text22}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text23}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text24}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text25}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text26}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text27}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text28}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text29}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text30}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text31}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text32}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text33}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text34}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text35}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text36}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text37}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text38}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text39}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text40}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text41}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text42}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text43}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text44}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text45}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text46}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text47}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text48}
                  //   </Text>
                  //   <Text
                  //   variant={"service_rules_description_text"}
                  //   >
                  //     {e.text49}
                  //   </Text>
                  // </>
                );
              })}
            </>
          )}
        </Box>
      </Card>
    </Box>
  );
};
export default ActsRules;
