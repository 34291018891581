import Objective from "@views/components/objective_page/objective_page";
import { Box } from "native-base";
import React from "react";

const ObjectivePage = (props) => {
  const { objective_data } = props;
  console.log("focus1", focus);
  return (
    <Box height="auto">
      <Objective objective_data={objective_data} />
    </Box>
  );
};
export default ObjectivePage;
