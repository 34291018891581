import { gql } from "@apollo/client";

export const get_system_cms_field_stadium_query = gql`
  query getSystemCmsFields($json: get_all_system_cms_field_input) {
    stadium_list: getSystemCmsFields(json: $json) {
      items {
        id
        key
        type
        system_cms_values {
          id
          value
          row_number
          system_cms_field_id
          system_cms_language_id
          system_cms_field_option_value_id
          system_cms_field {
            type
          }
        }
      }
    }
  }
`;
