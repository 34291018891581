import { gql } from "@apollo/client";

export const login_query = gql`
  query login(
    $username: String!
    $password: String!
    $captcha: String!
    $id: String!
  ) {
    login(
      username: $username
      password: $password
      captcha: $captcha
      id: $id
    ) {
      data
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const query_request_seed = gql`
  query requestSeed {
    requestSeed {
      data
    }
  }
`;
