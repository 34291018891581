import SpecialRules from "@views/components/acts_and_rules/special_rules";
import { Box } from "native-base";
import React from "react";

const SpecialRulesPage = () => {
  return (
    <Box>
      <SpecialRules />
    </Box>
  );
};
export default SpecialRulesPage;
