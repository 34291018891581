import PostAndStaffDetails from "@views/components/post_and_staff/post_and_staff";
import { Box } from "native-base";
import React from "react";

const PostAndStaffPage = () => {
  return (
    <Box>
      <PostAndStaffDetails />
    </Box>
  );
};
export default PostAndStaffPage;
