import { Card } from "antd";
import { Box, Circle, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import PensionFormImage from "@assets/images/pension forms.png";
import { useDispatch } from "react-redux";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { FilterItems } from "@helpers/constants";
import { get_cms_pension_forms_query } from "@services/redux/slices/graphql/graphql_cms_pension_forms_query";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import PageLoading from "@views/components/about_us/page_loading";

const PensionForms = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [pension_data, setPensionData] = useState([]);
  const { items: pension_items, loading: pension_loading } = useDynamicSelector(
    "get_cms_pension_forms"
  );

  const get_pension_forms_query = () => {
    let keys = [{ key: "get_cms_pension_forms", loading: true }];
    let variables = {
      json: {
        system_cms_key: "pension_form",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_pension_forms_query, variables));
  };

  useEffect(() => {
    get_pension_forms_query();
  }, []);
  useEffect(() => {
    let values = FilterItems(pension_items);
    setPensionData(values);
  }, [pension_items]);
  const Contents = [
    {
      title: "Preface",
      text1: "Benefits at a Glance",
      text2: "Pension",
      text3: "Dearness Allowance",
      text4: "Commuted Portion of Pension",
      text5: "Death cum Retirement Gratuity",
      text6: "Medical Facilities",
      text7: "Other Benefits",
      text8: "Recovery of Government dues, Loss to Government etc..",
      text9: "Festival Advance",
      text10:
        "Benefits to Family members after the death of Government employee",
      text11: "Tamil Nadu Government Pensioners Family Security Fund",
      text12: "Procedures",
      text13: "Grievances",
      text14: "Entitlement of Pension - Format",
    },
  ];
  return (
    <Box variant="contents_box">
      <Card
        style={{
          backgroundColor: "#fcfcfc",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        }}
      >
        <Box variant="heading_box">
          <Circle bg={"#fcfcfc"} size={"140px"}>
            <img src={PensionFormImage} width={"65px"} />
          </Circle>
          <Text variant={"pension_form_heading_text"}>
            {t("pension_forms")}
          </Text>
        </Box>
        <VStack space={"20px"}>
          {pension_loading ? (
            <PageLoading />
          ) : (
            <>
              {pension_data?.map((e) => {
                return (
                  <>
                    <Text variant={"pension_sub_text"} underline>
                      {e?.title?.value}
                    </Text>
                    <Box style={{ gap: "10px" }}>
                      <Text>{ReactHtmlParser(e?.content?.value)}</Text>
                      {/* <a href="" target="_blank">
                    <Text
                      fontSize={{ lg: "16px", xs: "12px" }}
                      textAlign={"justify"}
                      fontFamily={"Montserrat-Medium"}
                      underline
                      className="form-color"
                    >
                      {e.text1}
                    </Text>
                  </a> */}

                      {/* <a href="" target="_blank">
                    <Text
                      fontSize={{ lg: "16px", xs: "12px" }}
                      textAlign={"justify"}
                      fontFamily={"Montserrat-Medium"}
                      underline
                      className="form-color"
                    >
                      {e.text2}
                    </Text>
                  </a>
                  <a href="" target="_blank">
                    <Text
                      fontSize={{ lg: "16px", xs: "12px" }}
                      textAlign={"justify"}
                      fontFamily={"Montserrat-Medium"}
                      underline
                      className="form-color"
                    >
                      {e.text3}
                    </Text>
                  </a>
                  <a href="" target="_blank">
                    <Text
                      fontSize={{ lg: "16px", xs: "12px" }}
                      textAlign={"justify"}
                      fontFamily={"Montserrat-Medium"}
                      underline
                      className="form-color"
                    >
                      {e.text4}
                    </Text>
                  </a>
                  <a href="" target="_blank">
                    <Text
                      fontSize={{ lg: "16px", xs: "12px" }}
                      textAlign={"justify"}
                      fontFamily={"Montserrat-Medium"}
                      underline
                      className="form-color"
                    >
                      {e.text5}
                    </Text>
                  </a>
                  <a href="" target="_blank">
                    <Text
                      fontSize={{ lg: "16px", xs: "12px" }}
                      textAlign={"justify"}
                      fontFamily={"Montserrat-Medium"}
                      underline
                      className="form-color"
                    >
                      {e.text6}
                    </Text>
                  </a>
                  <a href="" target="_blank">
                    <Text
                      fontSize={{ lg: "16px", xs: "12px" }}
                      textAlign={"justify"}
                      fontFamily={"Montserrat-Medium"}
                      underline
                      className="form-color"
                    >
                      {e.text7}
                    </Text>
                  </a>
                  <a href="" target="_blank">
                    <Text
                      fontSize={{ lg: "16px", xs: "12px" }}
                      textAlign={"justify"}
                      fontFamily={"Montserrat-Medium"}
                      underline
                      className="form-color"
                    >
                      {e.text8}
                    </Text>
                  </a>
                  <a href="" target="_blank">
                    <Text
                      fontSize={{ lg: "16px", xs: "12px" }}
                      textAlign={"justify"}
                      fontFamily={"Montserrat-Medium"}
                      underline
                      className="form-color"
                    >
                      {e.text9}
                    </Text>
                  </a>
                  <a href="" target="_blank">
                    <Text
                      fontSize={{ lg: "16px", xs: "12px" }}
                      textAlign={"justify"}
                      fontFamily={"Montserrat-Medium"}
                      underline
                      className="form-color"
                    >
                      {e.text10}
                    </Text>
                  </a>
                  <a href="" target="_blank">
                    <Text
                      fontSize={{ lg: "16px", xs: "12px" }}
                      textAlign={"justify"}
                      fontFamily={"Montserrat-Medium"}
                      underline
                      className="form-color"
                    >
                      {e.text11}
                    </Text>
                  </a>
                  <a href="" target="_blank">
                    <Text
                      fontSize={{ lg: "16px", xs: "12px" }}
                      textAlign={"justify"}
                      fontFamily={"Montserrat-Medium"}
                      underline
                      className="form-color"
                    >
                      {e.text12}
                    </Text>
                  </a>
                  <a href="" target="_blank">
                    <Text
                      fontSize={{ lg: "16px", xs: "12px" }}
                      textAlign={"justify"}
                      fontFamily={"Montserrat-Medium"}
                      underline
                      className="form-color"
                    >
                      {e.text13}
                    </Text>
                  </a>
                  <a href="" target="_blank" className="form-color">
                    <Text
                      fontSize={{ lg: "16px", xs: "12px" }}
                      textAlign={"justify"}
                      fontFamily={"Montserrat-Medium"}
                      underline
                    >
                      {e.text14}
                    </Text>
                  </a> */}
                    </Box>
                  </>
                );
              })}
            </>
          )}
        </VStack>
      </Card>
    </Box>
  );
};
export default PensionForms;
