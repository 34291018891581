import { Card } from "antd";
import { Box, Circle, Text } from "native-base";
import React, { useEffect, useState } from "react";
import PostStaff from "@assets/images/post_and_staff.png";
import LocalFundAuditTable from "./local_fund_audit_table";
import RegionTable from "./region_table";
import { get_cms_post_and_staff_query } from "@services/redux/slices/graphql/graphql_cms_post_and_staff_query";
import { FilterItems } from "@helpers/constants";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PageLoading from "../about_us/page_loading";

const PostAndStaffDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [staff_data, setStaffData] = useState([]);
  const { items: staff_items, loading: staff_loading } = useDynamicSelector(
    "get_cms_post_and_staff"
  );
  const get_post_and_staff_query = () => {
    let keys = [{ key: "get_cms_post_and_staff", loading: true }];
    let variables = {
      json: {
        system_cms_key: "post_and_staff",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_post_and_staff_query, variables));
  };

  useEffect(() => {
    get_post_and_staff_query();
  }, []);
  useEffect(() => {
    let values = FilterItems(staff_items);
    setStaffData(values.sort((a, b) => a?.order?.value - b?.order?.value));
  }, [staff_items]);
  return (
    <Box variant="contents_box">
      <Card
        style={{
          backgroundColor: "#fcfcfc",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        }}
      >
        <Box variant={"heading_box"}>
          <Circle bg={"#fcfcfc"} size={"140px"}>
            <img src={PostStaff} width={"65px"} />
          </Circle>
          <Text
           variant={"post_and_staff_text"}
          >
            {t("post_and_staff")}
          </Text>
        </Box>
        {staff_loading ? (
          <PageLoading />
        ) : (
          <>
            {staff_data?.map((item) => {
              return (
                <>
                  <Box alignItems={"center"} py={"25px"}>
                    <Text
                      variant={"post_and_staff_sub_text"}
                      underline
                    >
                      {item?.title?.value}
                    </Text>
                  </Box>
                  <Box padding={"20px"}>
                    <LocalFundAuditTable table_list={item?.content?.value} />
                  </Box>

                  {/* <Box alignItems={"center"} py={"25px"}>
          <Text
            fontSize={{ lg: "18px", xs: "14px" }}
            color={"#002555"}
            fontFamily={"Montserrat-Medium"}
            underline
          >
            REGIONAL JOINT DIRECTOR OFFICES
          </Text>
        </Box>
        <Box padding="20px">
          <RegionTable />
        </Box> */}
                </>
              );
            })}
          </>
        )}
      </Card>
    </Box>
  );
};
export default PostAndStaffDetails;
