import React from "react";
import { useHistory } from "react-router-dom";
import NotAllowed from "@views/components/common/not_allowed";
import {
  useCheckLogin,
  useShouldChangePassword,
  usePageAccess,
} from "@helpers/auth";

const PrivateRoute = ({ children, pageKey }) => {
  const history = useHistory();
  const isLoggedIn = useCheckLogin();
  // React.useEffect(() => {
  //   if (isLoggedIn === false) history.replace("/login");
  // }, [isLoggedIn]);
  const shouldChangePassword = useShouldChangePassword();
  if (shouldChangePassword) {
    //redirect to change password
  }
  const hasAccess = usePageAccess(pageKey);
  if (isLoggedIn && hasAccess) {
    return <>{children}</>;
  }
  if (!isLoggedIn || !hasAccess) {
    return <NotAllowed />;
  }
};

export default PrivateRoute;
