import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { gql } from "@apollo/client";
// import "antd/dist/antd.min.css";
import _grid from "./grid";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Card,
  Switch,
  Upload,
} from "antd";
import { HStack } from "native-base";
import JoditEditor from "jodit-react";
// import JSONInput from "react-json-editor-ajrm";
// import locale from "react-json-editor-ajrm/locale/en";
// import TagsInput from "react-tagsinput";
// import "react-tagsinput/react-tagsinput.css";
import { useEffect, useState } from "react";
import { QueryRequest } from "@services/apollo/api_service";
import axios from "axios";
import moment from "moment";
import { showToast } from "@helpers/functions";
const { TextArea } = Input;
const { Option } = Select;

const TextBox = (props) => {
  let rules = props.rules;
  let name = props.field;
  // console.log(name);
  if (props.fields && props.field_key) {
    let field = props.fields.find((x) => x.name === props.field_key);
    name = field.id;
    if (field.is_mandatory) {
      rules = [
        {
          required: true,
          message: "Field cannot be empty",
        },
      ];
    }
  }
  return (
    <Form.Item
      style={props.style}
      label={props.label}
      name={name}
      rules={rules}
      labelCol={props.labelCol}
      hidden={props.hidden}
    >
      <Input
        placeholder={props.placeholder}
        disabled={props.isdisabled}
        onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
      />
    </Form.Item>
  );
};

// const TagBox = (props) => {
//   let rules = props.rules;
//   let name = props.field;

//   const _TagInput = (tag_props) => {
//     let value = [];
//     if (tag_props.value && tag_props.format === "string") {
//       value = JSON.parse(tag_props.value);
//     }
//     const handleValueChange = (values) => {
//       if (values && values.length === 0) {
//         tag_props.onChange(null);
//       } else {
//         if (tag_props.format === "string") {
//           tag_props.onChange(JSON.stringify(values));
//         } else {
//           tag_props.onChange(values);
//         }
//       }
//     };
//     return (
//       <TagsInput
//         {...tag_props}
//         inputProps={{
//           placeholder: tag_props.placeholder || "",
//           style: { width: "150px" },
//         }}
//         value={value}
//         onChange={handleValueChange}
//       />
//     );
//   };
//   return (
//     <Form.Item
//       style={props.style}
//       label={props.label}
//       name={name}
//       rules={rules}
//     >
//       <_TagInput placeholder={props.placeholder} format={props.format} />
//     </Form.Item>
//   );
// };
const Number = (props) => {
  return (
    <Form.Item label={props.label} name={props.field} rules={props.rules}>
      <InputNumber
        style={{ width: "100%" }}
        controls={false}
        disabled={props.isdisabled}
      />
    </Form.Item>
  );
};
const FormTextArea = (props) => {
  return (
    <Form.Item label={props.label} name={props.field} rules={props.rules}>
      <TextArea disabled={props.isdisabled} />
    </Form.Item>
  );
};

const FormSelect = (props) => {
  // filterOption={(input, option) =>
  //   option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
  //     0 ||
  //   option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
  // }
  let options = props.options;
  console.log("props.filterOption", props.filterOption);

  return (
    <Form.Item
      label={props.label}
      name={props.field}
      rules={props.rules}

    // style={props.style}
    // hidden={true}
    >
      <Select
        showSearch
        defaultValue={props.defaultValue}
        mode={props.isMulti ? "multiple" : "single"}
        disabled={props.isdisabled}
        allowClear
        // filterOption={props.filterOption}
        filterOption={(input, option) =>
          option.props.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >=
          0 ||
          option.props.value?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
        }
      >
        {options?.map((item, index) => {
          return (
            <Option value={item[props?.valueField]} key={index}>
              {item[props?.labelField]}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};
const Password = (props) => {
  return (
    <Form.Item label={props.label} name={props.field} rules={props.rules}>
      <Input.Password />
    </Form.Item>
  );
};
const Date = (props) => {
  return (
    <Form.Item label={props.label} name={props.field} rules={props.rules}>
      <DatePicker
        style={{ width: "100%" }}
        disabledDate={props.disabledDate}
        // defaultValue={moment(props.defaultValue)}
        disabledTime={props.disabledTime}
        showTime={props.showTime}
        disabled={props.isdisabled}
      />
    </Form.Item>
  );
};
const FormRadio = (props) => {
  {
    let options = props.options.map((item) => {
      return (
        <Radio
          value={item["id"]}
          disabled={props.disabled}
          defaultChecked={item.defaultChecked}
        >
          {item["name"]}
        </Radio>
      );
    });
    return props.type === "button" ? (
      <Form.Item label={props.label} name={props.field} rules={props.rules}>
        <Radio.Group>
          {props?.options?.map((item) => {
            return (
              <Radio.Button value={item["id"]}>{item["name"]}</Radio.Button>
            );
          })}
        </Radio.Group>
      </Form.Item>
    ) : (
      <Form.Item label={props.label} name={props.field} rules={props.rules}>
        <Radio.Group>
          {props.direction === "row"
            ? props?.options?.map((item) => {
              return (
                <Row justify="space-between">
                  <Radio value={item["id"]} disabled={true}>
                    {item["name"]}
                  </Radio>
                </Row>
              );
            })
            : options}
        </Radio.Group>
      </Form.Item>
    );
  }
};
const CheckBox = (props) => {
  let options = props?.options?.map((item) => {
    return <Checkbox value={item["id"]} disabled={props.disabled}></Checkbox>;
  });
  return (
    <Form.Item label={props.label} name={props.field}>
      <Checkbox.Group>
        {props.direction === "row"
          ? props?.options?.map((item) => {
            return (
              <Row justify="space-between">
                <Checkbox
                  value={item["id"]}
                  style={{
                    lineHeight: "32px",
                  }}
                ></Checkbox>
              </Row>
            );
          })
          : options}
      </Checkbox.Group>
    </Form.Item>
  );
};
const FormSwitch = (props) => {
  return (
    <Form.Item label={props.label} name={props.field} valuePropName="checked">
      <Switch />
    </Form.Item>
  );
};
const RichText = (props) => {
  return (
    <Form.Item label={props.label} name={props.field}>
      <JoditEditor />
    </Form.Item>
  );
};
const FormButton = (props) => {
  return (
    <Form.Item>
      <Button
        type="primary"
        htmlType="submit"
        loading={props.isLoading}
        // borderRadius="5px"
        disabled={props.isDisabled}
      >
        {props.children ? props.children : "Submit"}
      </Button>
    </Form.Item>
  );
};
const Grid = (props) => {
  return <_grid {...props} />;
};
// const JSONEditor = (props) => {
//   return (
//     <Form.Item label={props.label} name={props.field}>
//       <JSONInput />
//     </Form.Item>
//   );
// };
const List = (props) => {
  return (
    <Form.List name={props.field_name}>
      {(fields, { add, remove }) => {
        return (
          <div>
            {fields?.map((field, index) => {
              return (
                <HStack key={index}>
                  {props?.children}
                  <Button
                    type="danger"
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                    style={{ marginLeft: "20px" }}
                    icon={<MinusCircleOutlined />}
                  >
                    Remove
                  </Button>
                </HStack>
              );
            })}
            <Form.Item>
              <Button
                type="solid"
                onClick={() => add()}
                style={{ width: "32%", float: "right" }}
              >
                <PlusOutlined /> {props?.add_label}
              </Button>
            </Form.Item>
          </div>
        );
      }}
    </Form.List>
  );
};

export const fileQuery = gql`
  query getUploadUrl($type: String, $fileName: String) {
    getUploadUrl(type: $type, fileName: $fileName) {
      status
      url
      error {
        status_code
        message
      }
    }
  }
`;
const normFile = (e) => {
  // console.log("kdjvksdvk", e);
  // let url = e?.fileList[0]?.originFileObj?.url;
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};
let fileUploadresponse;
const FormFile = (props) => {
  const [fileList, setFileList] = useState([]);
  const [headers, setHeaders] = useState();
  useEffect(() => {
    setFileList(props.value);
  }, [props.value]);
  const handleBeforeUpload = async ({ name, type }) => {
    let fileupload = {
      type: "photo",
      fileName: name,
    };
    fileUploadresponse = await QueryRequest(fileQuery, fileupload);
  };
  const handleFileChanged = ({ file }) => {
    if (file.status === "removed") {
      setFileList([]);
    } else if (file.status === "uploading") {
      setFileList([file]);
    } else if (file.status === "done") {
      const newFile = {
        ...file,
        url: fileUploadresponse?.data?.getUploadUrl?.url,
      };
      setFileList([newFile]);
      console.log("fileList", fileList);
      showToast({
        type: "success",
        message: "Document uploaded successfully",
      });
    }
  };
  const handleUpload = async ({ onSuccess, onError, file }) => {
    axios
      .put(fileUploadresponse?.data?.getUploadUrl?.url, file, {
        headers: { "Content-Type": file?.type },
      })
      .then(async (res) => {
        if (res.status.toString() === "200") {
          file.url = fileUploadresponse?.data?.getUploadUrl?.url.split("?")[0];
          onSuccess(null, file);
        } else {
          onError(err, err, file);
        }
      })
      .catch((err) => {
        onError(err, err, file);
      });
  };
  const action = fileUploadresponse?.data?.getUploadUrl;
  return (
    <Form.Item
      label={props.label}
      name={props.field}
      valuePropName="fileList"
      getValueFromEvent={normFile}
      extra=""
    >
      <Upload
        maxCount={1}
        action={action}
        headers={headers}
        fileList={fileList}
        disabled={props.isdisabled}
        // showUploadList={{ showDownloadIcon: false, showPreviewIcon: true }}
        customRequest={(e) => handleUpload(e)}
        beforeUpload={(args) => handleBeforeUpload(args)}
        onChange={(e) => handleFileChanged(e)}
      >
        <Button icon={<UploadOutlined />}>Click to upload</Button>
      </Upload>
    </Form.Item>
  );
};
const File = (props) => {
  const [fileList, setFileList] = useState([]);
  const [headers, setHeaders] = useState();
  const handleBeforeUpload = async ({ name, type }) => {
    let fileupload = {
      type: "photo",
      fileName: name,
    };
    fileUploadresponse = await QueryRequest(fileQuery, fileupload);
  };
  const handleFileChanged = ({ file }) => {
    if (file.status === "removed") {
      setFileList([]);
    } else if (file.status === "uploading") {
      setFileList([file]);
    } else if (file.status === "done") {
      const newFile = {
        ...file,
        url: fileUploadresponse?.data?.getUploadUrl?.url,
      };
      setFileList([newFile]);
      showToast({
        type: "success",
        message: "Document uploaded successfully",
      });
    }
  };
  const handleUpload = async ({ onSuccess, onError, file }) => {
    axios
      .put(fileUploadresponse?.data?.getUploadUrl?.url, file, {
        headers: { "Content-Type": file?.type },
      })
      .then(async (res) => {
        if (res.status.toString() === "200") {
          file.url = fileUploadresponse?.data?.getUploadUrl?.url.split("?")[0];
          onSuccess(null, file);
        } else {
          onError(err, err, file);
        }
      })
      .catch((err) => {
        onError(err, err, file);
      });
  };
  const action = fileUploadresponse?.data?.getUploadUrl;
  return (
    <Form.Item
      label={props.label}
      name={props.field}
      valuePropName="fileList"
      getValueFromEvent={normFile}
      extra=""
      rules={props.rules}
    >
      <Upload
        maxCount={1}
        action={action}
        headers={headers}
        fileList={fileList}
        disabled={props.isdisabled}
        // showUploadList={{ showDownloadIcon: false, showPreviewIcon: true }}
        customRequest={(e) => handleUpload(e)}
        beforeUpload={(args) => handleBeforeUpload(args)}
        onChange={(e) => handleFileChanged(e)}
      >
        <Button icon={<UploadOutlined />}>Click to upload</Button>
      </Upload>
    </Form.Item>
  );
};
const VForm = (props) => {
  const { children } = props;
  let _form = props.form;
  if (!_form) {
    const [form] = Form.useForm();
    _form = form;
  }
  const [initialValues, setInitialValues] = useState(props.initialValues);
  useEffect(() => {
    _form.setFieldsValue(props.initialValues);
    setInitialValues(props.initialValues);
  }, [_form, props.initialValues]);

  const handleOnValueChange = (value, values) => {
    if (props.onValueChange) {
      props.onValueChange(value, values);
    }
  };

  const handleOnFinish = (values) => {
    if (props.onFinish) {
      props.onFinish(values);
    }
  };
  return (
    <Form
      form={props.form || _form}
      name={props.id}
      layout={"vertical"}
      onFinish={handleOnFinish}
      onValuesChange={handleOnValueChange}
      initialValues={initialValues}
    >
      {children}
    </Form>
  );
};
VForm.Grid = Grid;
VForm.List = List;
VForm.TextBox = TextBox;
// VForm.TagBox = TagBox;
// VForm.JSONEditor = JSONEditor;
VForm.Number = Number;
VForm.TextArea = FormTextArea;
VForm.Password = Password;
// VForm.IntlPhone = IntlPhone;
VForm.Phone = Number;
VForm.Select = FormSelect;
// VForm.Cascade = Cascade;
VForm.RichText = RichText;
VForm.FormFile = FormFile;
VForm.Date = Date;
VForm.Radio = FormRadio;
VForm.CheckBox = CheckBox;
VForm.Button = FormButton;
VForm.Switch = FormSwitch;
VForm.File = File;
export default VForm;
