import { Card } from "antd";
import { Box, Circle, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import CertificateImage from "@assets/images/certificate.png";
import { get_cms_certification_accounts_query } from "@services/redux/slices/graphql/graphql_cms_certificate_accounts_query";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { FilterItems } from "@helpers/constants";
import { useDispatch } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import PageLoading from "../about_us/page_loading";

const CertificateAccount = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [certification_data, setCertificationData] = useState([]);
  const { items: certification_items, loading: certification_loading } =
    useDynamicSelector("get_cms_certification_accounts");

  const get_certification_accounts_query = () => {
    let keys = [{ key: "get_cms_certification_accounts", loading: true }];
    let variables = {
      json: {
        system_cms_key: "certification_of_accounts",
      },
    };
    dispatch(
      dynamicRequest(keys, get_cms_certification_accounts_query, variables)
    );
  };
  useEffect(() => {
    get_certification_accounts_query();
  }, []);

  useEffect(() => {
    let values = FilterItems(certification_items);
    setCertificationData(values);
  }, [certification_items]);
  const Contents = [
    {
      title: "Audit Certificate",
      description1:
        "It is the duty of the auditor to give an opinion and to prepare a report after verifying the Annual Financial Statement (Account) of an institution. The audit opinion is to be prepared in a standard format and is given in the name of Audit Certificate to the concerned Institution. When this Certificate is attached to the Annual Financial Statement, the account becomes an auditor certified accounts. A copy of the Audit Report is also given to the institution.",
    },
  ];

  const Contents1 = [
    {
      title: "Types of Audit Certificates",
      subtitle: "Unqualified Certificate",
      description:
        "If the Auditor is satisfied in all material respects of the Annual Financial Statement, Unqualified Certificate is given. In this case the account will definitely be prepared according to the acts and Rules and as per accepted accounting methods. So the accounts will be reasonably reliable.",
    },
    {
      subtitle: "Qualified Certificate",
      description:
        "If there are serious mistakes in the Annual Financial Statement, Qualified Certificate is given. This may be of two types 1) If there is uncertainty with the amounts, 2) If the auditor has disagreement with the amounts. This difference will be of material nature and will be serious. But this mistake will not be fundamental in understanding the accounts.",
    },
    {
      subtitle: "Disclaimer Certificate",
      description:
        "If it is not possible for the audit to give an opinion about the Annual Financial Statement, Disclaimer Certificate is given. There may be uncertainty even in the fundamental aspects of the Annual Financial Statement.",
    },
    {
      subtitle: "Adverse Certificate",
      description:
        "If the auditor cannot agree with the fundamental aspects of the Annual Financial Statement, Adverse Certificate is given. The auditor makes clear that the Annual Financial Statement is not acceptable.",
    },
  ];
  const Contents2 = [
    {
      title: "Audit Certificate",
      description:
        "Government in G.O. Ms. No.93, Finance (FC IV) Dept. dated: 28-3-2003 have prescribed the following audit certificate to be appended to the audit reports of Corporations and Municipalities. The same has been communicated in circular No.13/2006 (RC No.34964/05/MA05 dt:2-2-2006 ) of the Director of Local Fund Audit .",
    },
  ];

  const Contents3 = [
    {
      title: "Certificate",
      description:
        "I have examined the Receipts and Payments account, Income and Expenditure account for the year ended 31-3- and the balance sheet as on -------------------------- of ----------------------. I have obtained the information and explanations that I have required and subject to the observations in the appended audit report. I certify, as a result of my audit, that in my opinion these accounts and Balance Sheet are properly drawn up so as to exhibit true and fair view of the state of affairs of the ..................................Municipal Corporation according to the best of information and explanation given to me and as shown by the books of the Organisation. Subject to the remarks furnished in Part- --- of AR and Para No. ...... to ......... of the Audit Report for which necessary rectification through JVS is to be done by the Commissioner in the closing balance sheet.",
    },
  ];
  return (
    <Box variant="contents_box">
      <Card
        style={{
          backgroundColor: "#fcfcfc",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        }}
      >
        <Box variant="heading_box">
          <Circle bg={"#fcfcfc"} size={"140px"}>
            <img src={CertificateImage} width={"65px"} />
          </Circle>
          <Text variant={"certificate_heading_text"}>
            {t("certification_of_accounts")}
          </Text>
        </Box>
        <VStack space="20px" padding={"20px"}>
          <Box
            style={{
              width: "100%",
              gap: "20px",
              marginTop: "10px",
            }}
          >
            {certification_loading ? (
              <PageLoading />
            ) : (
              <>
                {certification_data?.map((e) => {
                  return (
                    <>
                      <Text>{ReactHtmlParser(e?.content?.value)}</Text>
                      {/* <Text variant={"certificate_title_text"}>{e.title}</Text>
                  <Text variant={"certificate_description_text"}>
                    {e.description1}
                  </Text> */}
                    </>
                  );
                })}
              </>
            )}
          </Box>
          {/* <Box
            style={{
              width: "100%",
              gap: "20px",
            }}
          >
            {Contents1.map((e) => {
              return (
                <>
                  <Text variant={"certificate_title_text"}>{e.title}</Text>
                  <VStack space="10px">
                    <Text variant={"certificate_subtitle_text"}>
                      {e.subtitle}
                    </Text>
                    <Text variant={"certificate_description_text"}>
                      {e.description}
                    </Text>
                  </VStack>
                </>
              );
            })}
          </Box>
          <Box
            style={{
              width: "100%",
              gap: "20px",
            }}
          >
            {Contents2.map((e) => {
              return (
                <>
                  <Text variant={"certificate_title_text"}>{e.title}</Text>
                  <Text variant={"certificate_description_text"}>
                    {e.description}
                  </Text>
                </>
              );
            })}
          </Box>
          <Box
            style={{
              width: "100%",
              gap: "20px",
            }}
          >
            {Contents3.map((e) => {
              return (
                <>
                  <Text variant={"certificate_title_text"}>{e.title}</Text>
                  <Text variant={"certificate_description_text"}>
                    {e.description}
                  </Text>
                </>
              );
            })}
          </Box> */}
        </VStack>
      </Card>
    </Box>
  );
};
export default CertificateAccount;
