import { gql } from "@apollo/client";

export const get_forgot_password_otp = gql`
  query submitOTPRequestForForgetPassword($username: String!) {
    submitOTPRequestForForgetPassword(username: $username) {
      status
      mobile_status
      error {
        status_code
        message
      }
    }
  }
`;
export const update_forgot_password = gql`
  mutation updateForgotPassword(
    $username: String!
    $otp: String!
    $new_password: String!
  ) {
    updateForgotPassword(
      username: $username
      otp: $otp
      new_password: $new_password
    ) {
      status
      user_id
      session_id
      home_page
      roles {
        id
        name
      }
      page_accesses
      error {
        status_code
        message
      }
    }
  }
`;
