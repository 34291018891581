import { Button, Card } from "antd";
import { Box, Circle, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import Document from "@assets/files/LIFE CERTIFICATE FORM.docx";
import healthImage from "@assets/images/status of health fund.png";
import { get_cms_status_of_health_query } from "@services/redux/slices/graphql/graphql_cms_status_of_health_fund_query";
import { useDispatch } from "react-redux";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { FilterItems } from "@helpers/constants";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import PageLoading from "../about_us/page_loading";

const StatusOfHealth = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [status_data, setStatusData] = useState([]);
  const { items: status_items, loading: status_loading } = useDynamicSelector(
    "get_cms_status_of_health_fund"
  );

  const get_status_of_health_fund_query = () => {
    let keys = [{ key: "get_cms_status_of_health_fund", loading: true }];
    let variables = {
      json: {
        system_cms_key: "Status Of Health Fund",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_status_of_health_query, variables));
  };
  useEffect(() => {
    get_status_of_health_fund_query();
  }, []);

  useEffect(() => {
    let values = FilterItems(status_items);
    setStatusData(values);
  }, [status_items]);

  const handleDownloadClick = (e) => {
    const attachmentValue = e?.attachment?.value;
    if (attachmentValue) {
      window.open(attachmentValue, "_blank");
    } else {
      alert("Attachment value is empty!");
    }
  };
  const Contents = [
    {
      title: "PENSIONERS HEALTH FUND:",
      description1:
        "Pensioners Health Fund scheme has been implemented to provide financial assistance to pensioners and family pensioners for undergoing specialised treatment / Surgery in Accredited /unaccredited Institutions. Pensioners have to contribute Rs.150/- P.M. for this fund.Familypensioners have tocontributeRs .50/- .Financial Assistance to a maximum of Rs.1,00,000/- or 75% of the cost of treatment/Surgery whichever is less, is sanctioned to the pensioners during their life time.Local body Pensioners have to forward applications to the Director of Local Fund Audit.",
    },
  ];
  const Contents1 = [
    {
      title: "HEALTH FUND",
      text1: "Admissibility of assistance:",
      text2:
        "The assistance is admissible to pensioners/family pensioners for treatment / surgery/ specialisedsurgery for the diseases mentioned in G.O. Ms.No.400 Finance (Salaries) Department Dated:- 29.08.2000 at the accredited hospitals private Hospitals and unaccredited private hospitals.GO.Ms. No. 377 dated 13th October 2005 (Updation in Ms. No400 Finance (Salaries) Department Dated:- 29.08.2000 ).",
      text3: "Quantum of assistance:",
      text4:
        "a. The maximum assistance admissible is Rs.1,00,000/- or 75% of the actual expenditure whichever is less (G.O. Ms.No.236 Finance Dated:- 30.03.1995)",
      text5:
        "b. For cancer treatment assistance under this scheme may be allowed for any No. of times not exceeding maximum ceiling of Rs.1,00,000/-.",
      text6:
        "c. In all other normal cases assistance can be availed twice, not exceeding the maximum ceiling of Rs.1,00,000/-",
      text7: "Submission of Application:",
      text8:
        "d. The employee shall prefer the claim to the Director in the prescribed format(vide Govt.Letter No.46490 Finance (Salaries) / 98 99 Dated:- 11.08.1999) along with original vouchers, within 60 days from the date of discharge from the Hospital.",
    },
  ];
  const Contents2 = [
    {
      title: "PENSIONERS FAMILY SECURITY FUND:",
      text1:
        "Pensioners Family Security Fund is operated from 1-1-1998 for which pensioners are now contributing a sum of Rs.80/- P.M.In the case of death of a pensioner after one year of contribution to this fund, a sum of Rs.50,000/- will be paid to the family from this fund.Spouse / nominee / legal heir of the deceased pensioner shall apply to the Director",
    },
    {
      title: "MEDICAL ALLOWANCE:",
      text1:
        "Medical allowance of Rs 100/- is paid along with the monthly pension.",
    },
  ];
  const Contents3 = [
    {
      title: "FESTIVAL ADVANCE:",
      text1:
        "Pensioners are elegible to obtain festival advance . The amount ofadvance is Rs 2000/- and is recoverable in 10 monthly instalments.",
      text2: "a)APPLICATIONS FOR SANCTION OF PENSION:",
      text3:
        "Applications for sanction of pension shall be sent in the prescribed format to the Director through the head of office of the concerned localbody along with relevant documents and Service Register six months prior to the date of retirement.",
      text4:
        "The following documents should invariably accompany the pension claim:",
      text5: "i.Pension applicationform - 2 copies.",
      text6: "ii.Photograph/Joint Photograph- 2 copies.",
      text7: "iii.Slip containing specimen signature/Thumb",
      text8: "impression of the claimant- 2 copies",
      text9: "iv.Slip containing specimen signature /Thumb",
      text10: "impression of the spouse- 2copies",
      text11: "v.Details of the family members- one copy",
      text12: "vi. Nomination for Death-cum-Retirement Gratuity",
      text13: "vii. Statement showing Non-qualifying service",
      text14:
        "Viii. Certificate to the effect that no Judicial/departmental proceedings have been instituted/are pending against the individual",
      text15: "ix. Identification marks of the individual",
      text16: "x. Identification marks of the spouse",
    },
  ];
  const Contents4 = [
    {
      title: "SUBMISSION OF LIFE CERTIFICATE:",
      text1:
        "Every pensioner/ family pensioner has to submit 'Life Certificate' every year during April to June .The certificate shall be duly attested by Revenue Inspector / Deputy Tahsildar / Gazetted Officers of State or Central Government or Bank Managers of the branch where the Pensioner receives pension.",
      text2: "LIFE CERTIFICATE FORM",
    },
  ];
  return (
    <Box variant="contents_box">
      <Card
        style={{
          backgroundColor: "#fcfcfc",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        }}
      >
        <Box variant="heading_box">
          <Circle bg={"#fcfcfc"} size={"140px"}>
            <img src={healthImage} width={"65px"} />
          </Circle>
          <Text variant={"status_health_heading_text"}>
            {t("status_of_health_fund")}
          </Text>
        </Box>
        <VStack space={"20px"} p="20px" mt="10px">
          <Box
            style={{
              width: "100%",
              gap: "20px",
            }}
          >
            {status_loading ? (
              <PageLoading />
            ) : (
              <>
                {status_data?.map((e) => {
                  return (
                    <>
                      <Text>{ReactHtmlParser(e?.content?.value)}</Text>
                      {/* <Text
                   variant={"status_health_title_text"}
                  >
                    {e.title}
                  </Text>
                  <Text
                  variant={"status_health_text"}
                  >
                    {e.description1}
                  </Text> */}
                      <Box w={{ xs: "auto", md: "20%", lg: "20%" }}>
                        <Button onClick={() => handleDownloadClick(e)}>
                          {"Download"}
                        </Button>
                      </Box>
                    </>
                  );
                })}
              </>
            )}
          </Box>
          {/* <Box
            style={{
              width: "100%",
              gap: "20px",
            }}
          >
            {Contents1.map((e) => {
              return (
                <>
                  <Text
                   variant={"status_health_title_text"}
                  >
                    {e.title}
                  </Text>
                  <Text
                   variant={"status_health_text"}
                  >
                    {e.text1}
                  </Text>
                  <Text
                  variant={"status_health_text"}
                  >
                    {e.text2}
                  </Text>
                  <Text
                  variant={"status_health_text"}
                  >
                    {e.text3}
                  </Text>
                  <Text
                  variant={"status_health_text"}
                  >
                    {e.text4}
                  </Text>
                  <Text
                  variant={"status_health_text"}
                  >
                    {e.text5}
                  </Text>
                  <Text
                  variant={"status_health_text"}
                  >
                    {e.text6}
                  </Text>
                  <Text
                  variant={"status_health_text"}
                  >
                    {e.text7}
                  </Text>
                  <Text
                  variant={"status_health_text"}
                  >
                    {e.text8}
                  </Text>
                </>
              );
            })}
          </Box>
          <Box
            style={{
              width: "100%",
              gap: "20px",
            }}
          >
            {Contents2.map((e) => {
              return (
                <>
                  <Text
                   variant={"status_health_title_text"}
                  >
                    {e.title}
                  </Text>
                  <Text
                  variant={"status_health_text"}
                  >
                    {e.text1}
                  </Text>
                </>
              );
            })}
          </Box>
          <Box
            style={{
              width: "100%",
              gap: "20px",
            }}
          >
            {Contents3.map((e) => {
              return (
                <>
                  <Text
                   variant={"status_health_title_text"}
                  >
                    {e.title}
                  </Text>
                  <Text
                  variant={"status_health_text"}
                  >
                    {e.text1}
                  </Text>
                  <Text
                  variant={"status_health_text"}
                  >
                    {e.text2}
                  </Text>
                  <Text
                  variant={"status_health_text"}
                  >
                    {e.text3}
                  </Text>
                  <Text
                  variant={"status_health_text"}
                  >
                    {e.text4}
                  </Text>
                  <Text
                  variant={"status_health_text"}
                  >
                    {e.text5}
                  </Text>
                  <Text
                  variant={"status_health_text"}
                  >
                    {e.text6}
                  </Text>
                  <Text
                  variant={"status_health_text"}
                  >
                    {e.text7}
                  </Text>
                  <Text
                  variant={"status_health_text"}
                  >
                    {e.text8}
                  </Text>
                  <Text
                  variant={"status_health_text"}
                  >
                    {e.text9}
                  </Text>
                  <Text
                  variant={"status_health_text"}
                  >
                    {e.text10}
                  </Text>
                  <Text
                  variant={"status_health_text"}
                  >
                    {e.text11}
                  </Text>
                  <Text
                  variant={"status_health_text"}
                  >
                    {e.text12}
                  </Text>
                  <Text
                  variant={"status_health_text"}
                  >
                    {e.text13}
                  </Text>
                  <Text
                  variant={"status_health_text"}
                  >
                    {e.text14}
                  </Text>
                  <Text
                  variant={"status_health_text"}
                  >
                    {e.text15}
                  </Text>
                </>
              );
            })}
          </Box>
          <Box
            style={{
              width: "100%",
              gap: "20px",
            }}
          >
            {Contents4.map((e) => {
              return (
                <>
                  <Text
                   variant={"status_health_title_text"}
                  >
                    {e.title}
                  </Text>
                  <Text
                  variant={"status_health_text"}
                  >
                    {e.text1}
                  </Text>
                  <Text
                  variant={"status_health_text"}
                    bold
                  >
                    {e.text2}
                  </Text>
                  <Box w="50%">
                    <div>
                      <Button href={Document} target="_blank">
                        {"Download"}
                      </Button>
                    </div>
                  </Box>
                </>
              );
            })}
          </Box> */}
        </VStack>
      </Card>
    </Box>
  );
};
export default StatusOfHealth;
