import { gql } from "@apollo/client";

export const update_user_password = gql`
  mutation updateUserPassword(
    $current_password: String!
    $new_password: String!
  ) {
    updateUserPassword(
      current_password: $current_password
      new_password: $new_password
    ) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
