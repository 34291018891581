import StatusPension from "@views/components/status_of_pension/status_pension";
import { Box } from "native-base";
import React from "react";

const StatusPensionPage = () => {
  return (
    <Box>
      <StatusPension />
    </Box>
  );
};
export default StatusPensionPage;
