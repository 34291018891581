import { Card } from "antd";
import { Box, Circle, Text } from "native-base";
import React from "react";

const StatusFsf = () => {
  return (
    <Box variant="contents_box">
      <Card
        style={{
          backgroundColor: "#fcfcfc",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        }}
      >
        <Box
         variant="heading_box"
        >
          <Circle bg={"#fcfcfc"} size={"140px"}>
            {/* <img src={Audit} width={"65px"} /> */}
          </Circle>
          <Text
           variant={"status_fsf_heading_text"}
          >
            Status Of FSF
          </Text>
        </Box>
      </Card>
    </Box>
  );
};
export default StatusFsf;
