import ContactUs from "@views/components/contact_us/contact_us";
import { Box } from "native-base";
import React from "react";

const ContactUsPage = () => {
  return (
    <Box>
      <ContactUs />
    </Box>
  );
};
export default ContactUsPage;
