import { Box } from "native-base";
import React from "react";
import ReactHtmlParser from "react-html-parser";

const DepartmentTable = (props) => {
  const { table_data } = props;
  const TableData = [
    {
      year: 1880,
      description:
        "Local audit staff under the control of President of Local Fund Board which was periodically reviewed by Inspecting Staff under the control of Accountant General, Madras.",
    },
    {
      year: 1885,
      description:
        "Local audit staff transferred from the control of President of Local Fund Board to Treasury Deputy Directors.",
    },
    {
      year: 1885,
      description:
        "System of audit of Municipal Councils came under the review of Government (earlier carried out by non-official auditors). Examiner of Local Fund Accounts under the control of Accountant General, Madras was appointed as the Inspecting Officer.",
    },
    {
      year: 1903,
      description:
        "Detailed audit of receipts and expenditure of Municipal Councils conducted every half year by Examiner of Local Fund Board.",
    },
    {
      year: 1904,
      description:
        "Amalgamation of Local Fund Account Establishment with Audit Establishment (The objective was to ensure the correct classification of receipts and expenditure before the accounts compiled). In addition to the initial audit, test audit was conducted by the Examiner from Accountant General office.",
    },
    {
      year: 1920,
      description:
        "Finance Relations Committee was called and it recommended that the local audit staff should come under Examiner of Local Fund Board. Audit of expenditure was centralized in Madras under the supervision of the Examiner and audit of receipts was performed at the respective headquarters.",
    },
    {
      year: 1920,
      description:
        "Accounts Committee was formed and it recommended a system of local concurrent audit under the purview of a trained and independent supervisor to secure maximum degree of efficiency in audit of local and municipal expenditure.",
    },
    {
      year: 1921,
      description:
        "Local Fund Audit Department was placed under the control of Finance Department of Government of Madras.",
    },
    {
      year: 1922,
      description:
        "Local Fund Audit Department came into existence on the basis of recommendations put forth by the Accounts Committee.",
    },
    {
      year: 1945,
      description:
        "The audit of State Trading Scheme was entrusted to this department and the Examiner of Local Fund Board was appointed as the Chief Auditor.",
    },
    {
      year: 1952,
      description:
        "Examiner of Local Fund Board was appointed as the Treasurer of Charitable Endowments.",
    },
    {
      year: 1969,
      description:
        "Creation of State Trading Scheme Wing from Local Fund Audit Department and appointment of Chief Auditor.",
    },
    {
      year: 1992,
      description:
        "State Trading Scheme was named as Internal Audit Department under the control of Chief Internal Auditor and Chief Auditor of statutory boards.",
    },
    {
      year: 1995,
      description:
        "Designation of Examiner of Local Fund Accounts was changed as Director of Local Fund Audit.",
    },
  ];
  return (
    <>
      {table_data?.map((e) => {
        return (
          <Box>
            {ReactHtmlParser(e?.table?.value)}
            <div>
              {/* <table class="table">
          <thead className="table-header">
            <tr>
              <th className="radius-right">{"Year"}</th>
              <th className="radius-left">{"Department / Autority"}</th>
            </tr>
          </thead>
          <tbody>
            {TableData?.map((tablelist, index) => {
              return (
                <>
                  <tr>
                    <td data-label="Year" className="table-data">
                      {tablelist.year}
                    </td>
                    <td
                      data-label="Department/Authority"
                      className="table-data"
                    >
                      {tablelist.description}
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table> */}
            </div>
          </Box>
        );
      })}
    </>
  );
};
export default DepartmentTable;
