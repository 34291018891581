import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Provider } from "react-redux";
import {
  Box,
  NativeBaseProvider,
  extendTheme,
  theme as nbTheme,
} from "native-base";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IconContext } from "react-icons";
import App from "./App";
import "./i18n";
import { Toaster } from "react-hot-toast";
import { store } from "@services/redux";
import "./assets/fonts/Montserrat-Regular.ttf";
import { alignItems, fontWeight } from "styled-system";

const theme = extendTheme({
  colors: {
    primary: {
      50: "#024724",
      100: "#024724",
      200: "#024724",
      300: "#024724",
      400: "#024724",
      500: "#024724",
      600: "#024724",
      700: "#024724",
      800: "#024724",
      900: "#024724",
    },
    primaryContent: nbTheme.colors.warmGray,
    inputBorder: nbTheme.colors.coolGray,
    error: nbTheme.colors.red,
  },
  breakpoints: {
    base: 0,
    xs: 320,
    sm: 480,
    md: 768,
    lg: 1024,
    xl: 1440,
    "2xl": 2560,
    "3xl": 3840,
  },
  components: {
    Box: {
      variants: {
        contents_box: () => {
          return {
            px: { base: "50px", xs: "50px", lg: "100px" },
            py: "100px",
          };
        },
        heading_box: () => {
          return {
            alignItems: "center",
            marginTop: "-90px",
          };
        },
      },
      page_loading: () => {
        return {
          marginBottom: "35px",
        };
      },
      UpcomingEventBox: () => {
        return {
          padding: { xs: "2", lg: "4" },
          borderRadius: "16",
          // shadow: "5",    boxShadow: "10px",
          // right={["0px", "60px", "0px"]}
          backgroundColor: "#ffffff",
          // width: {
          //   xs: "347px",
          //   sm: "100px",
          //   md: "400px",
          //   lg: "600px",
          //   xl: "660px",
          //   // "100%",
          // },
          width: [
            "347px",
            "347px",
            "500px",
            "400px",
            "600px",
            "600px",
            "800px",
            "900px",
          ],
          height: {
            xs: "80px",
            lg: "128px",
          },
          marginBottom: "3",
          // mt: {
          //   xs: "-3rem",
          //   md: "-2rem",
          //   lg: "-4rem",
          // },
          ml: {
            xs: "0",
            lg: "-80px",
            xl: "-100",
            md: 0,
          },
          zIndex: "5",
        };
      },
      UpcomingContentBox: () => {
        return {
          width: {
            xs: "16",
            lg: "24",
          },
          height: {
            xs: "16",
            lg: "24",
          },
          rounded: "xl",
          bg: ["#E6E6FA"],
        };
      },
    },

    Text1: {
      variants: {
        text_book_board_heading_text: () => {
          return {
            fontSize: "md",
            fontWeight: "bold",
          };
        },
      },
    },
    Text: {
      variants: {
        department_history_heading_text: () => {
          return {
            fontSize: {
              base: "var(--font-size-20)",
              xs: "var(--font-size-20)",
              md: "var(--font-size-22)",
              lg: "var(--font-size-26)",
            },
            color: "#002555",
            fontFamily: "Montserrat-Medium",
          };
        },
        description_text: () => {
          return {
            fontSize: {
              base: "var(--font-size-14)",
              xs: "var(--font-size-14)",
              md: "var(--font-size-16)",
              lg: "var(--font-size-18)",
            },
            textAlign: "justify",
          };
        },
        training_heading_text: () => {
          return {
            fontSize: { base: "var(--font-size-20)", xs: "var(--font-size-20)", lg: "var(--font-size-26)" },
            color: "#002555",
            fontFamily: "Montserrat-Medium",
          };
        },
        training_title_text: () => {
          return {
            fontSize: { base: "18px", lg: "24px", xs: "18px" },
            textAlign: "justify",
            fontWeight: "bold",
            color: "#002555",
            fontFamily: "Montserrat-SemiBold",
          };
        },
        training_description_text: () => {
          return {
            fontSize: { base: "12px", lg: "16px", xs: "12px" },
            textAlign: "justify",
            fontFamily: "Montserrat-Medium",
          };
        },
        function_heading_text: () => {
          return {
            fontSize: { base: "var(--font-size-20)", xs: "var(--font-size-20)", lg: "var(--font-size-26)" },
            color: "#002555",
            fontFamily: "Montserrat-Medium",
          };
        },
        function_title_text: () => {
          return {
            fontSize: { lg: "24px", xs: "18px" },
            textAlign: "justify",
            fontWeight: "bold",
            color: "#002555",
            fontFamily: "Montserrat-SemiBold",
          };
        },
        function_description_text: () => {
          return {
            fontSize: { lg: "16px", xs: "12px" },
            textAlign: "justify",
            fontFamily: "Montserrat-Medium",
          };
        },
        auditable_institution_heading: () => {
          return {
            fontSize: { xs: "var(--font-size-20)",lg: "var(--font-size-26)" },
            color: "#002555",
            fontFamily: "Montserrat-Medium",
          };
        },
        audit_status_head_text: () => {
          return {
            fontSize: { lg: "26px", xs: "22px" },
            color: "#002555",
            fontFamily: "Montserrat-Medium",
          };
        },
        audit_status_subtitle_text: () => {
          return {
            fontSize: { lg: "18px", xs: "14px" },
            color: "#002555",
            fontFamily: "Montserrat-Medium",
          };
        },
        audit_fees_heading_text: () => {
          return {
            fontSize: { lg: "26px", xs: "22px" },
            color: "#002555",
            fontFamily: "Montserrat-Medium",
          };
        },
        charitable_heading_text: () => {
          return {
            fontSize: { lg: "var(--font-size-26)", xs: "var(--font-size-22)" },
            color: "#002555",
            fontFamily: "Montserrat-Medium",
          };
        },
        charitable_description_text: () => {
          return {
            fontSize: { lg: "var(--font-size-16)", xs: "var(--font-size-12)" },
            textAlign: "justify",
            fontFamily: "Montserrat-Medium",
          };
        },
        certificate_heading_text: () => {
          return {
            fontSize: { lg: "var(--font-size-26)", xs: "var(--font-size-22)" },
            color: "#002555",
            fontFamily: "Montserrat-Medium",
          };
        },
        certificate_title_text: () => {
          return {
            fontSize: { lg: "24px", xs: "20px" },
            textAlign: "justify",
            fontWeight: "bold",
            color: "#002555",
            fontFamily: "Montserrat-SemiBold",
          };
        },
        certificate_subtitle_text: () => {
          return {
            fontSize: { lg: "20px", xs: "16px" },
            textAlign: "justify",
            fontWeight: "bold",
            color: "#002555",
            fontFamily: "Montserrat-SemiBold",
          };
        },
        certificate_description_text: () => {
          return {
            fontSize: { lg: "16px", xs: "12px" },
            textAlign: "justify",
            fontFamily: "Montserrat-Medium",
          };
        },
        lfad_act_heading_text: () => {
          return {
            fontSize: { lg: "var(--font-size-26)", xs: "var(--font-size-20)" },
            color: "#002555",
            fontFamily: "Montserrat-Medium",
          };
        },
        lfad_act_title_text: () => {
          return {
            fontSize: { lg: "var(--font-size-24)", xs: "var(--font-size-18)" },
            textAlign: "justify",
            fontWeight: "bold",
            color: "#002555",
            fontFamily: "Montserrat-SemiBold",
          };
        },
        lfad_act_subtitle_text: () => {
          return {
            fontSize: { lg: "var(--font-size-24)", xs: "var(--font-size-18)" },
            textAlign: "justify",
            fontWeight: "bold",
            color: "#002555",
            fontFamily: "Montserrat-SemiBold",
          };
        },
        lfad_act_description_text: () => {
          return {
            fontSize: { lg: "var(--font-size-15)", xs: "var(--font-size-12)" },
            textAlign: "justify",
            fontFamily: "Montserrat-Medium",
          };
        },
        service_rules_heading_text: () => {
          return {
            fontSize: { lg: "var(--font-size-26)", xs: "var(--font-size-20)" },
            color: "#002555",
            fontFamily: "Montserrat-Medium",
          };
        },
        service_rules_title_text: () => {
          return {
            fontSize: { lg: "24px", xs: "18px" },
            textAlign: "justify",
            fontWeight: "bold",
            color: "#002555",
            fontFamily: "Montserrat-SemiBold",
          };
        },
        service_rules_subtitle_text: () => {
          return {
            fontSize: { lg: "20px", xs: "16px" },
            textAlign: "justify",
            fontFamily: "bold",
            color: "#002555",
            fontFamily: "Montserrat-SemiBold",
          };
        },
        service_rules_description_text: () => {
          return {
            fontSize: { lg: "16px", xs: "12px" },
            textAlign: "justify",
            fontFamily: "Montserrat-Medium",
            marginLeft: { lg: "50px", xs: "0px" },
          };
        },
        special_rules_heading_text: () => {
          return {
            fontSize: { lg: "var(--font-size-26)", xs: "var(--font-size-20)" },
            color: "#002555",
            fontFamily: "Montserrat-Medium",
          };
        },
        special_rules_subheading_text: () => {
          return {
            fontSize: { lg: "20px", xs: "16px" },
            textAlign: "justify",
            fontWeight: "bold",
            color: "#002555",
            fontFamily: "Montserrat-SemiBold",
          };
        },
        special_rules_description_text: () => {
          return {
            fontSize: { lg: "16px", xs: "12px" },
            textAlign: "justify",
            fontFamily: "Montserrat-Medium",
            marginLeft: { lg: "50px", xs: "0px" },
          };
        },
        pension_details_head_text: () => {
          return {
            fontSize: { lg: "var(--font-size-26)", xs: "var(--font-size-20)" },
            color: "#002555",
            fontFamily: "Montserrat-Medium",
          };
        },
        pension_details_title_text: () => {
          return {
            fontSize: { lg: "var(--font-size-24)", xs: "var(--font-size-18)" },
            textAlign: "justify",
            fontWeight: "bold",
            color: "#002555",
            fontFamily: "Montserrat-SemiBold",
          };
        },
        pension_details_heading_text: () => {
          return {
            fontSize: { lg: "var(--font-size-22)", xs: "var(--font-size-16)" },
            textAlign: "justify",
            fontWeight: "bold",
            color: "#002555",
            fontFamily: "Montserrat-SemiBold",
          };
        },
        pension_details_subtitle_text: () => {
          return {
            fontSize: { lg: "20px", xs: "16px" },
            textAlign: "justify",
            fontFamily: "Montserrat-Medium",
          };
        },
        pension_details_text: () => {
          return {
            fontSize: { lg: "16px", xs: "12px" },
            textAlign: "justify",
            fontFamily: "Montserrat-Medium",
          };
        },
        pension_detail_sub_head: () => {
          return {
            fontSize: { lg: "20px", xs: "16px" },
            textAlign: "justify",
            fontFamily: "Montserrat-Medium",
            color: "#002555",
            fontWeight: "bold",
          };
        },
        status_pension_heading_text: () => {
          return {
            fontSize: { lg: "26px", xs: "20px" },
            color: "#002555",
            fontFamily: "Montserrat-Medium",
          };
        },
        status_health_heading_text: () => {
          return {
            fontSize: { lg: "var(--font-size-26)", xs: "var(--font-size-20)" },
            color: "#002555",
            fontFamily: "Montserrat-Medium",
          };
        },
        status_health_title_text: () => {
          return {
            fontSize: { lg: "24px", xs: "18px" },
            textAlign: "justify",
            fontWeight: "bold",
            color: "#002555",
            fontFamily: "Montserrat-SemiBold",
          };
        },
        status_health_text: () => {
          return {
            fontSize: { lg: "15px", xs: "12px" },
            textAlign: "justify",
            fontFamily: "Montserrat-Medium",
          };
        },
        status_fsf_heading_text: () => {
          return {
            fontSize: { lg: "26px", xs: "20px" },
            color: "#002555",
            fontFamily: "Montserrat-Medium",
          };
        },
        app_process_heading_text: () => {
          return {
            fontSize: { lg: "26px", xs: "20px" },
            color: "#002555",
            fontFamily: "Montserrat-Medium",
          };
        },
        pension_form_heading_text: () => {
          return {
            fontSize: { lg: "var(--font-size-26)", xs: "var(--font-size-20)" },
            color: "#002555",
            fontFamily: "Montserrat-Medium",
          };
        },
        pension_sub_text:()=>{
          return{
            fontSize:{ lg: "var(--font-size-24)", xs: "var(--font-size-18)" },
            textAlign:"justify",
            fontWeight:"bold",
            color:"#002555",
            fontFamily:"Montserrat-SemiBold",
          }
        },
        important_links_heading_text: () => {
          return {
            fontSize: { lg: "var(--font-size-26)", xs: "var(--font-size-20)" },
            color: "#002555",
            fontFamily: "Montserrat-Medium",
          };
        },
        important_link_text: () => {
          return {
            fontSize: { lg: "var(--font-size-16)", xs: "var(--font-size-12)" },
            textAlign: "justify",
            fontFamily: "Montserrat-Medium",
            color: "blue.600",
          };
        },
        rti_heading_text: () => {
          return {
            fontSize: { lg: "var(--font-size-26)", xs: "var(--font-size-20)" },
            color: "#002555",
            fontFamily: "Montserrat-Medium",
          };
        },
        list_of_pio_heading:()=>{
          return{
            fontSize:{ lg: "var(--font-size-26)", xs: "var(--font-size-20)" },
            color:"#002555",
            fontFamily:"Montserrat-Medium"
          }
        },
        list_of_pio_sub_text:()=>{
          return{
            fontSize:"var(--font-size-18)",
            color:"#002555",
            fontFamily:"Montserrat-Medium"
          }
        },
        rti_nodel_heading_text:()=>{
          return{
            fontSize:{ lg: "var(--font-size-26)", xs: "var(--font-size-20)" },
            color:"#002555",
            fontFamily:"Montserrat-Medium"
          }
        },
        rti_nodel_sub_title:()=>{
          return{
            fontSize:"var(--font-size-18)",
            color:"#002555",
            fontFamily:"Montserrat-Medium"
          }
        },
        contact_us_heading_text:()=>{
          return{
            fontSize:{ lg: "var(--font-size-26)", xs: "var(--font-size-20)" },
            color:"#002555",
            fontFamily:"Montserrat-Medium"
          }
        },
        rti_title_text: () => {
          return {
            fontSize: { lg: "24px", xs: "18px" },
            textAlign: "justify",
            fontWeight: "bole",
            color: "#002555",
            fontFamily: "Montserrat-SemiBold",
          };
        },
        format_text: () => {
          return {
            fontSize: { lg: "16px", xs: "12px" },
            textAlign: "justify",
            fontFamily: "Montserrat-Medium",
          };
        },
        YearFormat: () => {
          return {
            fontFamily: "BebasNeue",
            fontSize: { xs: "20px", lg: "30px" },
            fontWeight: "400",
          };
        },
        RightHeadLine: () => {
          return {
            color: "#3B1B7E",
            fontWeight: "light",
            fontSize: { xs: "14px", lg: "21px" },
            fontFamily: "BebasNeue",
          };
        },
        RightSecondHeadLine: () => {
          return {
            fontWeight: "bold",
            fontSize: { xs: "10px", lg: "14px" },
            fontFamily: "Montserrat-Medium",
            color: "white",
          };
        },
        RightDescription: () => {
          return {
            fontFamily: "Montserrat-Medium",
            fontSize: { xs: "8px", lg: "12px" },
            color: "white",
          };
        },
        MonthFormat: () => {
          return {
            fontFamily: "Montserrat-Medium",
            fontSize: { xs: "12px", lg: "16px" },
            fontWeight: "38px",
          };
        },
        rti_description: () => {
          return {
            fontSize: { lg: "16px", xs: "12px" },
            textAlign: "justify",
            fontFamily: "Montserrat-Medium",
            color: "blue.600",
          };
        },
        heading_size_text: () => {
          return {
            fontSize: {
              xs: "var(--font-size-38)",
              lg: "var(--font-size-36)",
              xl: "var(--font-size-46)",
            },
            fontFamily: "Montserrat-SemiBold",
            fontWeight: "bold",
            color: "#002555",
          };
        },
        description_size_text: () => {
          return {
            fontSize: {
              xs: "var(--font-size-14)",
              lg: "var(--font-size-16)",
              xl: "var(--font-size-18)",
            },
            fontFamily: "Montserrat-Medium",
            textAlign: "justify",
            color: "#002555",
          };
        },
        heading_style_text: () => {
          return {
            fontSize: {
              xs: "var(--font-size-38)",
              lg: "var(--font-size-36)",
              xl: "var(--font-size-46)",
            },
            fontFamily: "Montserrat-SemiBold",
            fontWeight: "bold",
            color: "#ffffff",
          };
        },
        description_style_text: () => {
          return {
            fontSize: {
              xs: "var(--font-size-14)",
              lg: "var(--font-size-16)",
              xl: "var(--font-size-18)",
            },
            fontFamily: "Montserrat-Medium",
            textAlign: "justify",
            color: "#ffffff",
          };
        },
        post_and_staff_text: () => {
          return {
            fontSize: { xs: "var(--font-size-22)", lg: "var(--font-size-26)" },
            color: "#002555",
            fontFamily: "Montserrat-Medium",
          };
        },
        post_and_staff_sub_text: () => {
          return {
            fontSize: { xs: "var(--font-size-14)", lg: "var(--font-size-18)" },
            color: "#002555",
            fontFamily: "Montserrat-Medium",
          };
        },
        organization_heading_text: () => {
          return {
            fontSize: { xs: "var(--font-size-20)", lg: "var(--font-size-26)" },
            color: "#002555",
            fontFamily: "Montserrat-Medium",
          };
        },
        organization_sub_text: () => {
          return {
            fontSize: { xs: "var(--font-size-14)", lg: "var(--font-size-18)" },
            color: "#002555",
            fontFamily: "Montserrat-Medium",
          };
        },
      },
    },
    VStack: {
      variants: {
        text_book_board_top_vstack: () => {
          return {
            space: "5",
          };
        },
      },
    },
    HStack: {
      variants: {
        add_text_book_board_button_hstack: () => {
          return {
            display: "flex",
            space: "3",
            justifyContent: "flex-end",
            marginLeft: "-363px",
            marginBottom: "20px",
            marginTop: "20px",
            marginRight: "20px",
            height: "40px",
            // width: "50%"
          };
        },
        submit_hstack: () => {
          return {
            space: "10",
            justifyContent: "flex-end",
          };
        },
      },
    },
  },
  fonts: {
    heading: "Roboto",
    body: "Roboto",
    mono: "Roboto",
    project_header: "revert",
  },
});
ReactDOM.render(
  <Provider store={store}>
    <NativeBaseProvider theme={theme}>
      <IconContext.Provider value={{ className: "global-class-name" }}>
        <App />
        <ToastContainer newestOnTop pauseOnFocusLoss />
        <Toaster position="top-center" reverseOrder={false} />
      </IconContext.Provider>
    </NativeBaseProvider>
  </Provider>,
  document.getElementById("root")
);
