import { Box, Circle, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import Document from "@assets/images/document.png";
import DepartmentTable from "./department_table";
import { Card } from "antd";
import { get_cms_department_history_query } from "@services/redux/slices/graphql/graphql_cms_department_history_query";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { useDispatch } from "react-redux";
import { FilterItems } from "@helpers/constants";
import ReactHtmlParser from "react-html-parser";
import { get_cms_department_history_table_query } from "@services/redux/slices/graphql/graphql_cms_department_history_table";
import { useTranslation } from "react-i18next";
import PageLoading from "./page_loading";

const AboutUs = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [history_data, setHistoryData] = useState([]);
  const [table_data, setTableData] = useState([]);

  const { items: history_items, loading: history_loading } = useDynamicSelector(
    "get_cms_department_history"
  );
  const { items: table_items, loading: table_loading } = useDynamicSelector(
    "get_cms_department_history_table"
  );
  const contents = [
    {
      description1:
        "The Local Fund Audit Department marked its genesis in 1880 under the Madras Local Board Act, 1844 to audit the Accounts of Local Boards.",
      description2:
        "The post of Examiner of Local Fund Accounts was created in 1885 to inspect the Local Bodies which eventuated in formation of an independent department, called Local Fund Accounts in 1921. Later in 1945, the audit of State Trading Schemes was entrusted to this department and the Examiner of Local Fund Accounts was also appointed as the Chief Auditor of State Trading Schemes.",
      description3:
        "Considering the overburdened responsibility, the functions of Chief Auditor of State Trading Schemes was carved out from Examiner of Local Fund Accounts to form a separate department in 1969.",
      description4:
        "Notwithstanding the bifurcation, both the department of Local Fund Audit and the Internal Audit & Statutory Boards Audit Department enjoy common cadre structure and the cadre control authority vested in the Director of Local Fund Audit.",
      description5:
        "The nomenclature of Local Fund Audit Department was rename as Department of Local Fund Audit and the Examiner of Local Fund Accounts was re-designated as Director of Local Fund Audit.",
      description6:
        "This Department was endowed with statutory powers by the Local Fund Act, 2014 and the Local Fund Audit Rules, 2016.",
      description7:
        "The Director of Local Fund Audit is the ex-officio Treasurer of Charitable Endowments since 1952.",
    },
  ];

  const get_department_history_query = () => {
    let keys = [{ key: "get_cms_department_history", loading: true }];
    let variables = {
      json: {
        system_cms_key: "department_history",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_department_history_query, variables));
  };
  const get_department_history_table_query = () => {
    let keys = [{ key: "get_cms_department_history_table", loading: true }];
    let variables = {
      json: {
        system_cms_key: "department_history_table",
      },
    };
    dispatch(
      dynamicRequest(keys, get_cms_department_history_table_query, variables)
    );
  };
  useEffect(() => {
    get_department_history_query();
    get_department_history_table_query();
  }, []);

  useEffect(() => {
    let values = FilterItems(history_items);
    setHistoryData(values);
  }, [history_items]);

  useEffect(() => {
    let values = FilterItems(table_items);
    setTableData(values);
  }, [table_items]);
  return (
    <Box variant="contents_box">
      <Card
        style={{
          backgroundColor: "#fcfcfc",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        }}
      >
        <Box variant="heading_box">
          <Circle bg={"#fcfcfc"} size={"140px"}>
            <img src={Document} width={"65px"} />
          </Circle>
          <Text variant={"department_history_heading_text"}>
            {t("department_history")}
          </Text>
        </Box>
        {history_loading || table_loading ? (
          <PageLoading />
        ) : (
          <>
            <VStack space={"30px"} p="20px" marginTop={"10px"}>
              {history_data.map((e) => {
                return (
                  <>
                    <Text variant={"description_text"}>
                      {ReactHtmlParser(e?.content?.value)}
                    </Text>

                    {/* <Text variant={"description_text"}>{e.description2}</Text>
                <Text variant={"description_text"}>{e.description3}</Text>
                <Text variant={"description_text"}>{e.description4}</Text>
                <Text variant={"description_text"}>{e.description5}</Text>
                <Text variant={"description_text"}>{e.description6}</Text>
                <Text variant={"description_text"}>{e.description7}</Text> */}
                  </>
                );
              })}
            </VStack>
            <Box padding="20px">
              <DepartmentTable table_data={table_data} />
            </Box>
          </>
        )}
      </Card>
    </Box>
  );
};
export default AboutUs;
