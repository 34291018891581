import CharitableEndowments from "@views/components/charitable_endowments/charitable_component";
import { Box } from "native-base";
import React from "react";

const CharitablePage = () => {
  return (
    <Box>
      <CharitableEndowments />
    </Box>
  );
};
export default CharitablePage;
