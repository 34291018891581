import AuditStatus from "@views/components/audit_status/audit_status_component";
import { Box } from "native-base";
import React from "react";

const AuditStatusPage = () => {
  return (
    <Box>
      <AuditStatus />
    </Box>
  );
};
export default AuditStatusPage;
