import AboutUs from "@views/components/about_us/about_us";
import { Box } from "native-base";
import React from "react";

const AboutUsPage = () => {
  return (
    <Box>
      <AboutUs />
    </Box>
  );
};
export default AboutUsPage;
