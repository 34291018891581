import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { generatePageMutation } from "./graphql";

const initialState = {
    loading: false,
    error: null,
    id: null,
    status: null,
};

const slice = createSlice({
    name: "pageGenerate",
    initialState,
    reducers: {
        _generate_page: (state) => {
            state.loading = true;
            state.id = null;
            state.status = null;
        },
        _generate_page_reset: (state) => {
            state.loading = false;
            state.id = null;
            state.status = null;
            state.error = null;
        },
        _generate_page_success: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.id = payload.id;
            state.status = payload.status;
        },
        _generate_page_failure: (state, { payload }) => {
            state.loading = false;
            state.error = payload.message;
        },
    },
});

const {
    _generate_page,
    _generate_page_reset,
    _generate_page_success,
    _generate_page_failure,
} = slice.actions;

export const generatePageSelector = (state) => state.pageGenerate;

export const generatePageReducer = slice.reducer;

export function generate_page(variables) {
    return async (dispatch) => {
        dispatch(_generate_page());
        try {
            const response = await MutateRequest(
                generatePageMutation,
                variables,
                dispatch
            );
            if (
                response?.data?.generatePage &&
                !response?.data?.generatePage.error
            ) {
                dispatch(_generate_page_success(response?.data?.generatePage));
            } else if (response?.data?.generatePage?.error) {
                dispatch(
                    _generate_page_failure(response?.data?.generatePage.error)
                );
            }
        } catch (error) {
            dispatch(_generate_page_failure(error));
        }
    };
}
export function generate_page_clear() {
    return async (dispatch) => {
        dispatch(_generate_page_reset());
    };
}
