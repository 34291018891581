import { fontSizeSelector } from "@services/redux/slices/font";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

function FontScaler({ children }) {
  const font_size_20 = 20;
  const font_size_14 = 14;
  const font_size_15 = 15;
  const font_size_13 = 13;
  const font_size_28 = 28;
  const font_size_12 = 12;
  const font_size_16 = 16;
  const font_size_17 = 17;
  const font_size_24 = 24;
  const font_size_25 = 25;
  const header_selection_large_screen_size = 32;
  const overview_paragraph = 16;
  const font_size_22 = 22;
  const font_size_19 = 19;
  const font_size_36 = 36;
  const font_size_30 = 30;
  const font_size_18 = 18;
  const font_size_38 = 38;
  const font_size_46 = 46;
  const font_size_26 = 26;
  const font_size_10 = 10;
  const { scale, font_size_change } = useSelector(fontSizeSelector);
  console.log("font_size_change", font_size_change);
  useEffect(() => {
    document.documentElement.style.setProperty(
      "--font-size-24",
      `${font_size_24 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-25",
      `${font_size_25 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-17",
      `${font_size_17 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-12",
      `${font_size_12 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-28",
      `${font_size_28 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-14",
      `${font_size_14 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-16",
      `${font_size_16 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-13",
      `${font_size_13 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-18",
      `${font_size_18 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-30",
      `${font_size_30 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-36",
      `${font_size_36 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-19",
      `${font_size_19 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-22",
      `${font_size_22 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-20",
      `${font_size_20 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--menubar-fontSize",
      `${font_size_15 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-18",
      `${font_size_18 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-38",
      `${font_size_38 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-46",
      `${font_size_46 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-26",
      `${font_size_26 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-10",
      `${font_size_10 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--header-selection-large",
      `${header_selection_large_screen_size + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--overview-paragraph",
      `${overview_paragraph + font_size_change}px`
    );
  }, [font_size_change]);

  const scaledStyle = {
    fontSize: `${scale}em`,
  };

  return (
    <div>
      <div style={scaledStyle}>{children}</div>
    </div>
  );
}

export default FontScaler;
