import { getPageListReducer } from "./page_list";
import { createPageReducer } from "./page_create";
import { deletePageReducer } from "./page_delete";
import { updatePageReducer } from "./page_update";
import { getPageReducer } from "./page_getOne";
import { generatePageReducer } from "./page_generate";
import { generateAllPageReducer } from "./page_generate_all";
export const pageReducers = {
    pageList: getPageListReducer,
    pageCreate: createPageReducer,
    pageDelete: deletePageReducer,
    pageUpdate: updatePageReducer,
    pageGet: getPageReducer,
    pageGenerateAll: generateAllPageReducer,
    pageGenerate: generatePageReducer,
};

export * from "./page_list";
export * from "./page_create";
export * from "./page_delete";
export * from "./page_update";
export * from "./page_getOne";
export * from "./page_generate";
export * from "./page_generate_all";
