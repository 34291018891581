import { Card } from "antd";
import { Box, Circle, Image, Text } from "native-base";
import React from "react";
import FlowChart1 from "@assets/images/flowchart_1.png";
import FlowChart2 from "@assets/images/flowchart_2.png";
import FlowChart3 from "@assets/images/flowchart_3.png";
import Organization from "@assets/images/Organization setup.png";
import { useTranslation } from "react-i18next";

const OrganizationStaff = () => {
  const {t}=useTranslation();
  return (
    <Box variant={"contents_box"}>
      <Card
        style={{
          backgroundColor: "#fcfcfc",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        }}
      >
        <Box variant={"heading_box"}>
          <Circle bg={"#fcfcfc"} size={"140px"}>
            <img src={Organization} width={"65px"} />
          </Circle>
          <Text
           variant={"organization_heading_text"}
          >
            {t("organization_staff_setup")}
          </Text>
        </Box>
        <Box alignItems={"center"} py={"25px"}>
          <Text
            variant={"organization_sub_text"}
            underline
          >
            ORGANIZATION STAFF DETAILS
          </Text>
        </Box>
        <Box padding={"20px"} gap={"20px"}>
          <img src={FlowChart1} width={"100%"} />
          <img src={FlowChart2} width={"100%"} />
          <img src={FlowChart3} width={"100%"} />
        </Box>
      </Card>
    </Box>
  );
};
export default OrganizationStaff;
