import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_page_query } from "./graphql";

const initialState = {
    loading: false,
    error: null,
    items: [],
    total_items: null,
    page_number: null,
    page_limit: null,
    sort_column: null,
    sort_order: null,
};

const slice = createSlice({
    name: "pageList",
    initialState,
    reducers: {
        _get_page: (state) => {
            state.loading = true;
            state.id = null;
            state.status = null;
        },
        _get_page_success: (state, { payload }) => {
            state.loading = false;
            state.items = payload.items;
            state.error = null;
            state.total_items = payload.pagination?.total_count;
            state.page_number = payload.pagination?.page_number;
            state.page_limit = payload.pagination?.page_limit;
        },
        _get_page_failure: (state, { payload }) => {
            state.loading = false;
            state.error = payload.message;
            state.total_items = [];
        },
    },
});

const { _get_page, _get_page_success, _get_page_failure } = slice.actions;

export const getPageListSelector = (state) => state.pageList;

export const getPageListReducer = slice.reducer;

export function get_page(variables) {
    return async (dispatch) => {
        dispatch(_get_page());
        try {
            const response = await QueryRequest(
                get_page_query,
                variables,
                dispatch
            );
            if (response?.data?.getPages && !response?.data?.getPages.error) {
                dispatch(_get_page_success(response?.data?.getPages));
            } else if (response?.data?.getPages?.error) {
                dispatch(_get_page_failure(response?.data?.getPages.error));
            }
        } catch (error) {
            dispatch(_get_page_failure(error));
        }
    };
}
