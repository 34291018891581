import { combineReducers } from "redux";
import { dynamicRequestReducer } from "./slices";
import { cmsReducers } from "./slices/cms";
import { pageReducers } from "./slices/page";
import { systemCMSFieldReducers } from "./slices/system_cms_field";
import {systemCMSValueReducers} from "./slices/system_cms_value";
import {loginReducers} from "./slices/login"
import {fontsReducers} from "./slices/font"

export const rootReducer = combineReducers({
  dynamicRequest: dynamicRequestReducer,
  ...cmsReducers,
  ...pageReducers,
  ...systemCMSFieldReducers,
  ...systemCMSValueReducers,
  ...loginReducers,
  ...fontsReducers
});
