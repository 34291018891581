import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { updatecmsMutation } from "./graphql";

const initialState = {
    loading: false,
    error: null,
    id: null,
    status: null,
};

const slice = createSlice({
    name: "cmsUpdate",
    initialState,
    reducers: {
        _update_cms: (state) => {
            state.loading = true;
            state.id = null;
            state.status = null;
        },
        _update_cms_reset: (state) => {
            state.loading = false;
            state.id = null;
            state.status = null;
            state.error = null;
        },
        _update_cms_success: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.id = payload.id;
            state.status = payload.status;
        },
        _update_cms_failure: (state, { payload }) => {
            state.loading = false;
            state.error = payload.message;
        },
    },
});

const {
    _update_cms,
    _update_cms_reset,
    _update_cms_success,
    _update_cms_failure,
} = slice.actions;

export const updatecmsSelector = (state) => state.cmsUpdate;

export const updatecmsReducer = slice.reducer;

export function update_cms(variables) {
    return async (dispatch) => {
        dispatch(_update_cms());
        try {
            const response = await MutateRequest(
                updatecmsMutation,
                variables,
                dispatch
            );
            if (
                response?.data?.updateSystemCms &&
                !response?.data?.updateSystemCms.error
            ) {
                dispatch(
                    _update_cms_success(response?.data?.updateSystemCms)
                );
            } else if (response?.data?.updateSystemCms?.error) {
                dispatch(
                    _update_cms_failure(
                        response?.data?.updateSystemCms.error
                    )
                );
            }
        } catch (error) {
            dispatch(_update_cms_failure(error));
        }
    };
}
export function update_one_cms_clear() {
    return async (dispatch) => {
        dispatch(_update_cms_reset());
    };
}
