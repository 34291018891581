import { Card } from "antd";
import { Box, Circle, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import FunctionImage from "@assets/images/functions.png";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { useDispatch } from "react-redux";
import { get_cms_function_query } from "@services/redux/slices/graphql/graphql_cms_function_query";
import { FilterItems } from "@helpers/constants";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import PageLoading from "../about_us/page_loading";

const Functions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [function_data, setFunctionData] = useState([]);
  const { items: function_items, loading: function_loading } =
    useDynamicSelector("get_cms_function");
  const Content1 = [
    {
      title: "Audit functions:",
      description1:
        "The Local Fund Audit Department carries out the audit of Urban Local Bodies, Panchayat Raj Institutions and various miscellaneous institutions. The Municipalities, Municipal Corporations and Town Panchayats are Urban Local Bodies and the Panchayat Unions, District Panchayats are Panchayat Raj Institutions. The prime objects of these bodies are providing amenities to the general public of the Local area. It becomes incumbent on their part to raise funds through levy of taxes and fees to meet the expenditure incurred for providing various services which are either obligatory or discretionary in nature. In addition to the funds raised, the grants released by the State and Central Governments for implementation of various welfare schemes are also credited to their funds. Consequently huge sum of money is received and spent every year by the Local bodies and the transactions are audited by the Local Fund Audit Department applying the general principles of audit.",
      description2:
        "The different types of audit done by the staff of the Director of Local Fund Audit are detailed below:",
      description3: "1.Field Audit",
      description4:
        "2.Concurrent Audit Corporations / Municipalities / Panchayat Unions.",
    },
  ];
  const content2 = [
    {
      title: "Field Audit",
      description1:
        "The audit of the Municipalities (other than the concurrent Municipalities) Town Panchayats, District Panchayats, Market Committees, Local Library Authorities and other miscellaneous institutions are done by the field staff from the office of the Assistant Directors in each district.",
    },
  ];
  const Content3 = [
    {
      title: "Concurrent Audit",
      description1:
        "The audit of all the Municipal Corporations, Special Grade Municipalities certain Selection Grade Municipalities, Panchayat Unions and that of all the Universities are done concurrently by the audit staff stationed in the respective Institutions.",
      description2:
        "Apart from the regular audit functions, the Local Fund Audit Department is also entrusted with the following special functions.",
      description3:
        "● Administration of Municipal, Panchayat Union and Town Panchayat Employees Pension Fund and authorization of payment of pension benefits and disbursement of monthly pension to the retired employees of the local bodies.",
      description4:
        "● Sanction of PF interest on T.Deposits to non-provincialised staff of the local bodies.",
      description5:
        "● The Director of Local Fund Audit is also the ex-officio Treasurer of Charitable Endowments.",
      description6:
        "● Sanction and disbursement of funds under Municipal Pensioner's Health Fund Scheme and Panchayat Union pensioners Health Fund scheme.",
    },
  ];
  const content4 = [
    {
      title:
        "AUDIT ACTIVITIES OF THE REGIONAL JOINT DIRECTOR OF LOCAL FUND AUDIT:",
      description1:
        "● The Regional Joint Directors are entrusted with the overall supervision and review of the audit activities of the district Assistant Directors in their region.",
      description2:
        "● Regional Joint director is the authority to issue special letters and special reports based on the audit reports.",
    },
  ];

  const get_function_query = () => {
    let keys = [{ key: "get_cms_function", loading: true }];
    let variables = {
      json: {
        system_cms_key: "function_content",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_function_query, variables));
  };

  useEffect(() => {
    get_function_query();
  }, []);
  useEffect(() => {
    let values = FilterItems(function_items);
    setFunctionData(values);
  }, [function_items]);

  return (
    <Box variant="contents_box">
      <Card
        style={{
          backgroundColor: "#fcfcfc",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        }}
      >
        <Box variant="heading_box">
          <Circle bg={"#fcfcfc"} size={"140px"}>
            <img src={FunctionImage} width={"65px"} />
          </Circle>
          <Text variant={"function_heading_text"}>{t("function")}</Text>
        </Box>
        <VStack space="20px" p="20px" mt="10px">
          <Box
            style={{
              width: "100%",
              gap: "20px",
            }}
          >
            {function_loading ? (
              <PageLoading />
            ) : (
              <>
                {function_data.map((item) => {
                  return (
                    <>
                      <Text>{ReactHtmlParser(item?.content?.value)}</Text>
                      {/* <Text
                  variant={"function_title_text"}
                  >
                    {e.title}
                  </Text>
                  <Text
                  variant={"function_description_text"}
                  >
                    {e.description1}
                  </Text>
                  <Text
                     variant={"function_description_text"}
                  >
                    {e.description2}
                  </Text>
                  <Text
                     variant={"function_description_text"}
                  >
                    {e.description3}
                  </Text>
                  <Text
                     variant={"function_description_text"}
                  >
                    {e.description4}
                  </Text> */}
                    </>
                  );
                })}
              </>
            )}
          </Box>
          {/* <Box
            style={{
              width: "100%",
              gap: "20px",
            }}
          >
            {content2.map((e) => {
              return (
                <>
                  <Text
                   variant={"function_title_text"}
                  >
                    {e.title}
                  </Text>
                  <Text
                     variant={"function_description_text"}
                  >
                    {e.description1}
                  </Text>
                </>
              );
            })}
          </Box>
          <Box
            style={{
              width: "100%",
              gap: "20px",
            }}
          >
            {Content3.map((e) => {
              return (
                <>
                  <Text
                   variant={"function_title_text"}
                  >
                    {e.title}
                  </Text>
                  <Text
                     variant={"function_description_text"}
                  >
                    {e.description1}
                  </Text>
                  <Text
                     variant={"function_description_text"}
                  >
                    {e.description2}
                  </Text>
                  <Text
                     variant={"function_description_text"}
                  >
                    {e.description3}
                  </Text>
                  <Text
                     variant={"function_description_text"}
                  >
                    {e.description4}
                  </Text>
                  <Text
                     variant={"function_description_text"}
                  >
                    {e.description5}
                  </Text>
                  <Text
                     variant={"function_description_text"}
                  >
                    {e.description6}
                  </Text>
                </>
              );
            })}
          </Box>
          <Box
            style={{
              width: "100%",
              gap: "20px",
            }}
          >
            {content4.map((e) => {
              return (
                <>
                  <Text
                   variant={"function_title_text"}
                  >
                    {e.title}
                  </Text>
                  <Text
                     variant={"function_description_text"}
                  >
                    {e.description1}
                  </Text>
                  <Text
                     variant={"function_description_text"}
                  >
                    {e.description2}
                  </Text>
                </>
              );
            })}
          </Box> */}
        </VStack>
      </Card>
    </Box>
  );
};
export default Functions;
