import { Box } from "native-base";
import React from "react";
import ReactHtmlParser from "react-html-parser";

const LocalFundAuditTable = (props) => {
  const { table_list } = props;
  // const contents = [
  //   {
  //     name: "DIRECTOR",
  //     lead: "1",
  //     internal_audit: "0",
  //     total: "1",
  //   },
  //   {
  //     name: "CIA & CA",
  //     lead: "0",
  //     internal_audit: "1",
  //     total: "1",
  //   },
  //   {
  //     name: "JOINT DIRECTOR",
  //     lead: "9",
  //     internal_audit: "0",
  //     total: "10",
  //   },
  //   {
  //     name: "DEPUTY DIRECTOR",
  //     lead: "17",
  //     internal_audit: "4",
  //     total: "21",
  //   },
  //   {
  //     name: "ASSISTANT DIRECTOR",
  //     lead: "72",
  //     internal_audit: "37",
  //     total: "109",
  //   },
  //   {
  //     name: "INSPECTOR",
  //     lead: "318",
  //     internal_audit: "101",
  //     total: "419",
  //   },
  //   {
  //     name: "DEPUTY INSPECTOR",
  //     lead: "293",
  //     internal_audit: "75",
  //     total: "368",
  //   },
  //   {
  //     name: "ASSISTANT INSPECTOR",
  //     lead: "293",
  //     internal_audit: "558",
  //     total: "851",
  //   },
  //   {
  //     name: "JUNIOR ASSISTANT",
  //     lead: "87",
  //     internal_audit: "33",
  //     total: "120",
  //   },
  //   {
  //     name: "TYPIST",
  //     lead: "57",
  //     internal_audit: "36",
  //     total: "93",
  //   },
  //   {
  //     name: "STENO GRADEI, I,II, & III",
  //     lead: "2",
  //     internal_audit: "2",
  //     total: "4",
  //   },
  //   {
  //     name: "TELEPHONE OPERATOR",
  //     lead: "1",
  //     internal_audit: "1",
  //     total: "2",
  //   },
  //   {
  //     name: "DRIVER",
  //     lead: "9",
  //     internal_audit: "1",
  //     total: "10",
  //   },
  //   {
  //     name: "RECORD CLERK",
  //     lead: "47",
  //     internal_audit: "5",
  //     total: "52",
  //   },
  //   {
  //     name: "OFFICE ASSISTANT",
  //     lead: "105",
  //     internal_audit: "43",
  //     total: "148",
  //   },
  //   {
  //     name: "WATCHMAN",
  //     lead: "13",
  //     internal_audit: "3",
  //     total: "16",
  //   },
  //   {
  //     name: "SWEEPER",
  //     lead: "1",
  //     internal_audit: "1",
  //     total: "2",
  //   },
  //   {
  //     name: "TOTAL",
  //     lead: "1325",
  //     internal_audit: "902",
  //     total: "2227",
  //   },
  // ];
  return (
    <Box>
      <div>
        {ReactHtmlParser(table_list)}
        {/* <table class="table">
          <thead className="table-header">
            <tr>
              <th className="radius-right">{"NAME OF POST"}</th>
              <th className="radius-left">{"SANCTIONED"}</th>
            </tr>
            <tr>
              <th className="radius-right" />
              <th
                // className="radius-left"
                style={{ textAlign: "-webkit-center" }}
              >
                <th className="sub-header">LFAD</th>
                <th className="sub-header second-header-title">
                  INTERNAL AUDIT
                </th>
                <th className="sub-header radius-left third-header-title">
                  TOTAL
                </th>
              </th>
            </tr>
          </thead>
          <tbody>
            <>
              {contents.map((e) => {
                return (
                  <tr>
                    <td
                      data-label="NAME OF POST"
                      className="table-column"
                      style={{ width: "400px" }}
                    >
                      {e.name}
                    </td>

                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <td data-label="LFAD" className="column-width">
                        {e.lead}
                      </td>
                      <td data-label="INTERNAL AUDIT" className="column-width">
                        {e.internal_audit}
                      </td>
                      <td data-label="TOTAL" className="column-width ">
                        {e.total}
                      </td>
                    </td>
                  </tr>
                );
              })}
            </>
          </tbody>
        </table> */}
      </div>
    </Box>
  );
};
export default LocalFundAuditTable;
