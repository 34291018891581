import { Card } from "antd";
import { Box, Circle, Text } from "native-base";
import React, { useEffect, useState } from "react";
import RtiOfficerTable from "./rti_officer_table";
import ListImage from "@assets/images/List.png";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { FilterItems } from "@helpers/constants";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { get_cms_rti_nodal_officers_query } from "@services/redux/slices/graphql/graphql_cms_rti_nodal_officers_query";
import ReactHtmlParser from "react-html-parser";
import PageLoading from "../about_us/page_loading";

const RtiNodalOfficer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [nodal_data, setNodalData] = useState([]);

  const { items: nodal_items, loading: nodal_loading } = useDynamicSelector(
    "get_cms_rti_nodal_officers"
  );

  const get_rti_nodal_officers_query = () => {
    let keys = [{ key: "get_cms_rti_nodal_officers", loading: true }];
    let variables = {
      json: {
        system_cms_key: "rti_nodal_officers",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_rti_nodal_officers_query, variables));
  };

  useEffect(() => {
    get_rti_nodal_officers_query();
  }, []);
  useEffect(() => {
    let values = FilterItems(nodal_items);
    setNodalData(values.sort((a, b) => a?.order?.value - b?.order?.value));
  }, [nodal_items]);
  return (
    <Box px="100px" py="100px">
      <Card
        style={{
          backgroundColor: "#fcfcfc",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        }}
      >
        <Box
          alignItems={"center"}
          style={{
            marginTop: "-90px",
          }}
        >
          <Circle bg={"#fcfcfc"} size={"140px"}>
            <img src={ListImage} width={"65px"} />
          </Circle>
          <Text
            variant={"rti_nodel_heading_text"}
          >
            {t("rti_nodal_officers")}
          </Text>
        </Box>
        {nodal_loading ? (
          <PageLoading />
        ) : (
          <>
            {nodal_data?.map((e) => {
              return (
                <>
                  <Box alignItems={"center"} py={"25px"}>
                    <Text
                      variant={"rti_nodel_sub_title"}
                      // underline
                    >
                      {e.title.value}
                    </Text>
                  </Box>

                  <Box padding={"20px"}>
                    <Text>{ReactHtmlParser(e.content.value)}</Text>
                    {/* <RtiOfficerTable /> */}
                  </Box>
                </>
              );
            })}
          </>
        )}
      </Card>
    </Box>
  );
};
export default RtiNodalOfficer;
