import Training from "@views/components/training_page/training";
import { Box } from "native-base";
import React from "react";

const TrainingPage = () => {
  return (
    <Box>
      <Training />
    </Box>
  );
};
export default TrainingPage;
