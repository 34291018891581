import { gql } from "@apollo/client";
export const file_query = gql`
  query getUploadUrl($type: String, $fileName: String) {
    getUploadUrl(type: $type, fileName: $fileName) {
      status
      url
      error {
        status_code
        message
      }
    }
  }
`;
export const create_submission_document = gql`
  mutation createSubmissionDocument($data: create_submission_document_input) {
    createSubmissionDocument(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const update_submission_document = gql`
  mutation updateSubmissionDocument(
    $data: update_submission_document_input
    $id: String!
  ) {
    updateSubmissionDocument(data: $data, id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const delete_submission_document = gql`
  mutation deleteSubmissionDocument($id: String!) {
    deleteSubmissionDocument(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
