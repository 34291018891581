import AuditableInstitution from "@views/components/audit/auditable_institution";
import { Box } from "native-base";
import React from "react";

const AuditableInstitutionPage = () => {
  return (
    <Box>
      <AuditableInstitution />
    </Box>
  );
};
export default AuditableInstitutionPage;
