import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_system_cms_value_query } from "./graphql";

const initialState = {
    loading: false,
    error: null,
    items: [],
    total_items: null,
    page_number: null,
    page_limit: null,
    sort_column: null,
    sort_order: null,
};

const slice = createSlice({
    name: "systemCMSValueList",
    initialState,
    reducers: {
        _get_system_cms_value: (state) => {
            state.loading = true;
            state.id = null;
            state.status = null;
        },
        _get_system_cms_value_success: (state, { payload }) => {
            state.loading = false;
            state.items = payload.items;
            state.error = null;
            state.total_items = payload.pagination?.total_count;
            state.page_number = payload.pagination?.page_number;
            state.page_limit = payload.pagination?.page_limit;
        },
        _get_system_cms_value_failure: (state, { payload }) => {
            state.loading = false;
            state.error = payload.message;
            state.total_items = [];
        },
    },
});

const {
    _get_system_cms_value,
    _get_system_cms_value_success,
    _get_system_cms_value_failure,
} = slice.actions;

export const getsystemCMSValueListSelector = (state) =>
    state.systemCMSValueList;

export const getsystemCMSValueListReducer = slice.reducer;

export function get_system_cms_value(variables) {
    return async (dispatch) => {
        dispatch(_get_system_cms_value());
        try {
            const response = await QueryRequest(
                get_system_cms_value_query,
                variables,
                dispatch
            );
            if (
                response?.data?.getSystemCmsValues &&
                !response?.data?.getSystemCmsValues.error
            ) {
                dispatch(
                    _get_system_cms_value_success(
                        response?.data?.getSystemCmsValues
                    )
                );
            } else if (response?.data?.getSystemCmsValues?.error) {
                dispatch(
                    _get_system_cms_value_failure(
                        response?.data?.getSystemCmsValues.error
                    )
                );
            }
        } catch (error) {
            dispatch(_get_system_cms_value_failure(error));
        }
    };
}
