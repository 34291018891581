import { Box, HStack, Stack, Text, VStack } from "native-base";
import React from "react";
import AuthorityImage from "@assets/images/authority.webp";
import ReactHtmlParser from "react-html-parser";

const Authority = (props) => {
  const { title, description1, focus, authority_data } = props;
  return (
    <>
      {authority_data?.map((item) => {
        return (
          <>
            <Stack
              display={"flex"}
              flexDirection={{ xs: "column", lg: "row" }}
              space={"10px"}
              justifyContent={"space-evenly"}
              alignItems={"center"}
              backgroundColor={"#152254"}
              p="20px"
            >
              <VStack>
                <Box
                  width={{ xs: "300px", md: "500px", lg: "500px" }}
                  style={{ gap: "20px" }}
                  // px={"50px"}
                >
                  <Text variant={"heading_style_text"}>
                    {item?.title?.value}
                  </Text>
                  <Text variant={"description_style_text"}>
                    {ReactHtmlParser(item?.content?.value)}
                  </Text>
                </Box>
              </VStack>
              <Box w={{ xs: "80%", lg: "35%", xl: "40%", "2xl": "20%" }}>
                <img
                  src={item?.image?.value}
                  size={"100%"}
                  style={{ opacity: "0.6" }}
                />
              </Box>
            </Stack>
            <Box
              height={{ xs: "100px", lg: "50px", xl: "50px", "2xl": "50px" }}
              backgroundColor={"#152254"}
            ></Box>
          </>
        );
      })}
    </>
  );
};
export default Authority;
