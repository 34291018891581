import AppProcessingStatus from "@views/components/app_processing_status/app_processing_status";
import { Box } from "native-base";
import React from "react";

const AppProcessingStatusPage = () => {
  return (
    <Box>
      <AppProcessingStatus />
    </Box>
  );
};
export default AppProcessingStatusPage;
