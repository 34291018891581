import {
  create_system_cms_value,
  getsystemCMSFieldListSelector,
  get_system_cms_field,
  update_system_cms_value,
  dynamicRequest,
  useDynamicSelector,
  dynamicClear,
  createsystemCMSValueSelector,
} from "@services/redux/index.js";
import { Button, Form } from "antd";
import { startCase } from "lodash";
import { Box, HStack, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import VForm from "../antd_form/antd_form.jsx";
import moment from "moment";
import { FilterItems, table_initial_content } from "@helpers/constants.js";
import { update_system_cms_values_mutation } from "@services/redux/slices/system_cms_value/graphql.js";
import { get_system_cms_field_stadium_query } from "@services/redux/slices/graphql/graphql_get_cms.js";

const CmsDetails = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    setData,
    id,
    close,
    get_cms,
    submit,
    language,
    get_one,
    model_header,
    type,
    key_name,
    row_number: row_index,
    onCancel,
    // form,
  } = props;
  const { items } = useSelector(getsystemCMSFieldListSelector);
  console.log("get_oneget_one", get_one);
  const { status: cmsValueCreate } = useSelector(createsystemCMSValueSelector);

  const { status, loading: cms_value_create_loading } = useDynamicSelector(
    "createSystemCmsValues"
  );

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  let row_number = items?.map((list) => {
    let Lists = get_one?.[list?.key];
    return Lists?.row_number;
  });
  const Ids = (id) => {
    // let find_id = items?.filter((list) => list?.id === id)
    // return find_id?.[0]?.system_cms_values?.[0]?.id
    let value_id = null;
    Object.keys(get_one)?.map((it) => {
      console.log("object", get_one, it);
      if (get_one[it].system_cms_field_id === id) value_id = get_one[it].id;
      console.log("value_id", get_one[it].system_cms_field_id, id);
    });
    return value_id;
  };

  const get_right_to_person_disabilities_menu = (data) => {
    let keys = [{ key: "createSystemCmsValues", loading: true }];
    let variables = {
      json: {
        data,
      },
    };
    dispatch(
      dynamicRequest(keys, update_system_cms_values_mutation, variables)
    );
  };

  const handleSubmit = (value) => {
    debugger;
    let convert_keys = Object.entries(value);
    let Update;
    let Create;
    if (model_header) {
      Create = convert_keys.map((s, index) => {
        let values = {
          system_cms_language_id: language,
          row_number: row_index + 1,
          system_cms_field_id: s[0],
          value: Array.isArray(s[1]) ? s[1]?.[0]?.url : s[1]?.toString() || "",
        };
        return values;
      });
    } else {
      Update = convert_keys.map((s, index) => {
        console.log("update", s?.[0]);
        let values = {
          id: Ids(s?.[0]),
          system_cms_language_id: language,
          row_number: row_number[index] ? row_number[index] : 1,
          system_cms_field_id: s[0],
          value: Array.isArray(s[1]) ? s[1]?.[0]?.url : s[1]?.toString() || "",
        };
        return values;
      });
    }
    model_header
      ? dispatch(
          create_system_cms_value({
            json: {
              data: Create,
            },
          })
        )
      : get_right_to_person_disabilities_menu(Update);

    // close(false);
  };
  // const handleInputChange = (values) => {};

  const handleClose = () => {
    close(false);
    form.resetFields();
  };

  useEffect(() => {
    let val = {};
    if (get_one && type === "Edit CMS List") {
      Object?.keys(get_one)?.map((it, index) => {
        if (get_one[it]?.type === "datetime") {
          val[get_one[it]?.system_cms_field_id] = moment(
            new Date(get_one[it]?.value)
          );
        } else if (get_one[it]?.type === "file") {
          if (get_one[it]?.value) {
            val[get_one[it]?.system_cms_field_id] = [
              {
                uid: "-1",
                url: get_one[it]?.value || "",
                status: "done",
                name: "View",
              },
            ];
          } else {
            val[get_one[it]?.system_cms_field_id] = [];
          }
        } else {
          // Set a default value when none of the conditions are met
          val[get_one[it]?.system_cms_field_id] = get_one[it]?.value;
        }
      });

      console.log("valvalvalvalvalvalval", val);
      form.setFieldsValue(val);
    } else {
      form.resetFields();
    }
  }, [get_one, type, model_header]);

  useEffect(() => {
    if (cmsValueCreate === "Success") {
      form.resetFields();
    } else if (status === "Success") {
      form.resetFields();
    }
  }, [cmsValueCreate, status]);
  const onChange = (val, values) => {
    console.log("onChange", val, values);
  };

  return (
    <Box>
      {/* <VForm form={form} onFinish={handleSubmit} onValueChange={onChange}> */}
      <Form
        form={form}
        onFinish={handleSubmit}
        onValuesChange={onChange}
        layout="vertical"
      >
        <div className="form-container">
          {items?.map((list) => {
            return (
              <>
                {list?.type === "text" ? (
                  <VForm.TextBox
                    label={startCase(list?.key)}
                    field={list?.id}
                    rules={[
                      {
                        required: true,
                        message: `${startCase(list?.key)} is required`,
                      },
                    ]}
                  />
                ) : list?.type === "textarea" ? (
                  <VForm.TextArea
                    label={startCase(list?.key)}
                    field={list?.id}
                    rules={[
                      {
                        required: true,
                        message: `${startCase(list?.key)} is required`,
                      },
                    ]}
                  />
                ) : list?.type === "file" ? (
                  <VStack>
                    <VForm.FormFile
                      label={startCase(list?.key)}
                      field={list?.id || ""}
                      getValueFromEvent={normFile}
                      allowed_file_formats={["pdf", "png", "jpg"]}
                      form={form}
                      rules={[
                        {
                          required: true,
                          message: `${startCase(list?.key)} is required`,
                        },
                      ]}
                    />
                    <span style={{ color: "red" }}>
                      {`Allowed file formats ${[
                        "pdf",
                        "png",
                        "jpg",
                        "docx",
                        "doc",
                      ]?.map((s) => s)}`?.replace(/,/g, ", ")}
                    </span>
                  </VStack>
                ) : list?.type === "number" ? (
                  <VForm.Number
                    label={startCase(list?.key)}
                    field={list?.id}
                    rules={[
                      {
                        required: true,
                        message: `${startCase(list?.key)} is required`,
                      },
                    ]}
                  />
                ) : list?.type === "datetime" ? (
                  <VForm.Date
                    label={startCase(list?.key)}
                    field={list?.id}
                    rules={[
                      {
                        required: true,
                        message: `${startCase(list?.key)} is required`,
                      },
                    ]}
                  />
                ) : list.type === "rich_text" ? (
                  <VForm.RichText
                    label={startCase(list?.key)}
                    field={list?.id}
                    rules={[
                      {
                        required: true,
                        message: `${startCase(list?.key)} is required`,
                      },
                    ]}
                  />
                ) : null}
              </>
            );
          })}
        </div>
        <HStack space={3} justifyContent={"flex-end"}>
          <VForm.Button isLoading={cms_value_create_loading} />
          <Button onClick={handleClose}>Close</Button>
        </HStack>
      </Form>
    </Box>
  );
};

export default CmsDetails;
