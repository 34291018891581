import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "../../../apollo/api_service";
import { useState } from "react";
// const [font_size_change, set_font_size_change] = useState(0);
const initialState = {
  scale: 1,
  font_size_change: 0,
};

const slice = createSlice({
  name: "font",
  initialState,
  reducers: {
    _font_size_increase: (state, { payload }) => {
      state.scale = state.scale + 0.1;
      state.font_size_change = state.font_size_change + 1;
    },
    _font_size_default: (state, { payload }) => {
      state.scale = 1;
      state.font_size_change = 0;
    },
    _font_size_decrease: (state, { payload }) => {
      state.scale = state.scale - 0.1;
      state.font_size_change = state.font_size_change - 1;
    },
  },
});

const { _font_size_increase, _font_size_default, _font_size_decrease } =
  slice.actions;

export const fontSizeSelector = (state) => state.font;

export const fontSizeReducer = slice.reducer;

export function increaseFontSize() {
  return _font_size_increase();
}
export function decreaseFontSize() {
  return _font_size_decrease();
}
export function setDefaultFontSize() {
  return _font_size_default();
}
