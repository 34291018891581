import React from "react";
import { Box, HStack, VStack, Text, Center } from "native-base";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";

const UpcomingEvent = ({events_data}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const [events_data, setEventsData] = useState([]);
  // const { items: events_items, loading } = useDynamicSelector(
  //   "get_cms_upcoming_events"
  // );
  // set_loading_status(loading);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    large_laptop: {
      breakpoint: { max: 2560, min: 1640 },
      items: 1,
      slidesToSlide: 1,
    },
    laptop: {
      breakpoint: { max: 1024, min: 480 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet1: {
      breakpoint: { max: 768, min: 480 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  // const get_upcoming_events = () => {
  //   let keys = [{ key: "get_cms_upcoming_events", loading: true }];
  //   let variables = {
  //     json: {
  //       system_cms_key: "upcoming_events",
  //     },
  //   };
  //   dispatch(dynamicRequest(keys, get_cms_upcoming_events_query, variables));
  // };

  // useEffect(() => {
  //   get_upcoming_events();
  // }, []);

  // useEffect(() => {
  //   let values = FilterItems(events_items);
  //   setEventsData(values);
  // }, [events_items]);

  // const events_data = [
  //   {
  //     event_name: "Local Audit Fund",
  //     description: "Local Audit Fund",
  //     date:"12",
  //     EventMonth: "Feb",
  //     EventYear: 2024,
  //   },
  // ];

  return (
    <Box
      alignItems={{ xs: "center", lg: "none" }}
        mt={{ xs: "-12", md: "-4", lg: "-16" }}
    >
      <Box
        padding={{ xs: "2", lg: "4" }}
        borderRadius="10"
        backgroundColor="#002555"
        // opacity={"0.6"}
        width={[
          "310px",
          "310px",
          "400px",
          "350px",
          "480px",
          "600px",
          "800px",
          "900px",
        ]}
        height={{
          xs: "80px",
          lg: "100px",
        }}
        zIndex="5"
        style={{
          boxShadow: "-10px 0px 10px 0px  rgb(0 0 0 / 17%) ",
        }}
      >
        <Carousel
          responsive={responsive}
          customLeftArrow={<></>}
          infinite={true}
        >
          {events_data?.map((event, index) => {
            // const EventMonth = event?.month?.substring(0, 3);
            const date = new Date(event?.date?.value);
            console.log("date",date);
            const EventDay = date.getDate();
            const EventMonth = date.toLocaleString("default", {
              month: "short",
            });
            const EventYear = date.getFullYear();
            return (
              <div className="carousel-height">
                <HStack space={3}>
                  <VStack space={2}>
                    <Box
                      width={{ xs: "60px", lg: "75px" }}
                      height={{ xs: "55px", lg: "70px" }}
                      rounded="12"
                      bg="#E6E6FA"
                    >
                      <Center>
                        <Box >
                          <Text variant="MonthFormat" fontWeight="bold">{EventDay}</Text>
                        </Box>
                        <Box>
                          <Text variant="MonthFormat">{EventMonth}</Text>
                        </Box>

                        <Box height={{ xs: "30px", lg: "30px" }}>
                          <Text
                            fontFamily="Montserrat-SemiBold"
                            fontSize={{ xs: "12px", lg: "16px" }}
                            fontWeight="bold"
                          >
                            {EventYear}
                          </Text>
                        </Box>
                      </Center>
                    </Box>
                  </VStack>
                  <VStack space={1}>
                    <Box>
                      <Text
                        color="white"
                        fontWeight="light"
                        fontSize={{ xs: "14px", lg: "16px" }}
                        fontFamily="Montserrat-SemiBold"
                      >
                        {t("upcoming_event")}
                      </Text>
                    </Box>
                    <Text variant="RightSecondHeadLine">
                      {event?.event_name?.value}
                    </Text>
                    <Text variant="RightDescription">{event?.description?.value}</Text>
                  </VStack>
                </HStack>
              </div>
            );
          })}
        </Carousel>
      </Box>
    </Box>
  );
};
export default UpcomingEvent;
