import { gql } from "@apollo/client";

export const query_get_captcha = gql`
  query getCaptcha {
    getCaptcha {
      captcha
      error {
        status_code
        message
      }
    }
  }
`;