import {
  CloseCircleOutlined,
  DeleteOutlined,
  SaveOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { AiOutlineDelete, AiTwotoneEdit } from "react-icons/ai";
import {
  Button,
  Col,
  DatePicker,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  Upload,
} from "antd";
import JoditEditor from "jodit-react";
import React, { useState } from "react";
import produce from "immer";
import axios from "axios";
import { create_system_cms_value } from "@services/redux";
import { useDispatch } from "react-redux";
import { findIndex } from "lodash";
import { useEffect } from "react";
import moment from "moment";
import { QueryRequest } from "@services/apollo/api_service";
import { fileQuery } from "@services/redux/slices/cms/graphql";
import { showToast } from "@helpers/functions";

const DynamicForm = (props) => {
  const {
    Form,
    cms_field,
    setData,
    data,
    normFile,
    setIsEditing,
    isEditing,
    fileList,
    setFileList,
    headers,
    setHeaders,
    setIndex,
    index: rowIndex,
    handleSubmit,
    language,
    onDelete,
    onSubmit,
    setOpen,
    setRecord,
    setModelHeader,
    SetGetOne,
    title,
  } = props;
  // ////console.log("props", props);
  let children = [];
  
  const getRender = (item, rowIndex, isEditing, field_index) => {
    if (item?.type === "number") {
      return (text, record, index) => {
        return <Col>{Number(record[item.key]?.value)}</Col>;
      };
    } else if (item?.type === "text") {
      return (text, record, index) => {
        return <Col>{record[item.key]?.value}</Col>;
      };
    } else if (item?.type === "textarea") {
      return (text, record, index) => {
        return <Col>{record[item.key]?.value}</Col>;
      };
    } else if (item?.type === "file") {
      let final_value;
      let fileUploadresponse = "";
      const handleBeforeUpload = async ({ name, type }) => {
        let fileupload = {
          type: "photo",
          fileName: name,
        };
        fileUploadresponse = await QueryRequest(fileQuery, fileupload);
      };
      const handleFileChanged = ({ file }) => {
        if (file?.status === "removed") {
          setFileList([]);
        } else if (file.status === "uploading") {
          setFileList([file]);
        } else if (file.status === "done") {
          const newFile = {
            ...file,
            url: fileUploadresponse?.data?.getUploadUrl?.url,
          };
          setFileList([newFile]);
          showToast({
            type: "success",
            message: "Image updated successfully",
          });
          return file?.url;
        }
      };
      const updateData = (url, index, item) => {
        let row_data = data;
        row_data[rowIndex][item?.key] = {
          system_cms_field_id: item?.id,
          system_cms_language_id: language,
          value: url,
          row_number: index,
          id: row_data?.[rowIndex]?.[item?.key]?.id || undefined,
        };
        //setData(row_data);
      };
      const handleUpload = ({ onSuccess, onError, file }, index, item) => {
        axios
          .put(fileUploadresponse?.data?.getUploadUrl?.url, file, {
            headers: { "Content-Type": file?.type },
          })
          .then((res) => {
            if (res.status.toString() === "200") {
              file.url =
                fileUploadresponse?.data?.getUploadUrl?.url.split("?")[0];
              final_value = file.url;
              updateData(file.url, index, item);
              onSuccess(null, file);
            } else {
              onError(err, err, file);
            }
          })
          .catch((err) => {
            onError(err, err, file);
          });
      };
      const action = fileUploadresponse?.data?.getUploadUrl;

      return (text, record, index) => {
        if (isEditing && index === rowIndex) {
          return (
            <Col>
              <Form.Item
                name={[`record.${item?.key}`, index]}
                initialValue={[
                  {
                    uid: "-3",
                    name: record[item?.key]?.value || "",
                    status: "done",
                    url: record[item?.key]?.value || "",
                  },
                ]}
                valuePropName="fileList"
                getValueFromEvent={normFile}
                extra=""
              >
                <Upload
                  maxCount={1}
                  action={action}
                  value={record[item?.key]?.value}
                  defaultValue={record[item?.key]?.value}
                  headers={headers}
                  fileList={fileList}
                  customRequest={(e) => handleUpload(e, index, item)}
                  beforeUpload={(args) => handleBeforeUpload(args)}
                  onChange={(e) => {
                    handleFileChanged(e);
                  }}
                >
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              </Form.Item>
            </Col>
          );
        } else {
          return (
            <Col>
              <Upload
                disabled
                maxCount={1}
                action={action}
                headers={headers}
                fileList={[
                  {
                    uid: "-3",
                    name: "View",
                    status: "done",
                    url: record[item.key]?.value || "",
                  },
                ]}
              >
                {/* <Button icon={<UploadOutlined />}>Click to upload</Button> */}
              </Upload>
            </Col>
          );
        }
      };
    } else if (item?.type === "dropdown") {
      ////console.table("Tables", item?.system_cms_field_options);
      return (text, record, index) => {
        return <Col>{record[item?.key]?.value}</Col>;
      };
    } else if (item?.type === "datetime") {
      return (text, record, index) => {
        return (
          <Col>
            <DatePicker
              disabled
              format={"DD/MM/YYYY"}
              defaultValue={
                record[item?.key]?.value
                  ? moment(new Date(record[item?.key]?.value), "DD/MM/YYYY")
                  : moment(new Date(), "DD/MM/YYYY")
              }
            />
          </Col>
        );
      };
    }
  };
  cms_field?.map((item) => {
    let rchilder = getRender(item, rowIndex, isEditing);
    children.push({
      title: item?.system_cms_field_names?.find(
        (item) => item?.system_cms_language_id === language
      )?.name,
      dataIndex: item?.key,
      sorter: false,
      // width: 150,
      editable: true,
      render: rchilder,
    });
  });
  children.push({
    title: "Action",
    width: 200,
    fixed: "right",
    render: (text, record, index) => {
      debugger
      // record = { ...record, type: item?.type };
      console.log("AiTwotoneEdit", record, text, index);
      // SetGetOne(record);
      if (!isEditing) {
        return (
          <Row key={index}>
            <Col span={12}>
              <Button
                type="text"
                // shape="circle"
                icon={<AiTwotoneEdit color="#06b506" size={20} />}
                // size={"large"}
                // className={"edit-button"}
                onClick={() => {
                  console.log("record", record)
                    // setIndex(index);
                    setOpen(true);
                  SetGetOne(record);
                  setModelHeader(false);
                }}
                key={index}
              />
            </Col>
            <Col span={12}>
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => {
                  onDelete(index, record);
                }}
              >
                <Button
                  // shape="circle"
                  type="text"
                  icon={<AiOutlineDelete color="red" size={20} />}
                  size={"large"}
                // className="delete-button"
                />
              </Popconfirm>
            </Col>
          </Row>
        );
      }
    },
  });
  return children;
};
export default DynamicForm;
