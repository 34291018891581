import PensionDetails from "@views/components/pension_details/pension_details";
import { Box } from "native-base";
import React from "react";

const PensionDetailsPage = () => {
  return (
    <Box>
      <PensionDetails />
    </Box>
  );
};
export default PensionDetailsPage;
