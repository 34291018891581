import { Menu, Drawer } from "antd";
import React, { useEffect, useState } from "react";

import {
  HStack,
  Center,
  Box,
  Image,
  Hidden,
  Pressable,
  Stack,
} from "native-base";
import { SideMenu, TopMenu } from "@helpers/constants";
import { HiMenu } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { color_gradients } from "@helpers/constants";
import "@src/index.css";
import { useHistory, useLocation } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";

import { useDispatch, useSelector } from "react-redux";

export const MenuBarContent = (props) => {
  const [current, setCurrent] = useState("");

  const { t } = useTranslation();
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const handleHome = () => {
    history.push(ROUTES.HOME);
  };

  const onMenuClick = (e) => {
    console.log("onMenuClick", e.key);
    if (e?.key == "home") {
      return history.push(ROUTES.HOME);
    } else if (e?.key == "department_of_history") {
      return history.push(ROUTES.ABOUT_US);
    } else if (e?.key == "post_staff_details") {
      return history.push(ROUTES.POST_AND_STAFF);
    } else if (e?.key == "training") {
      return history.push(ROUTES.TRAINING);
    } else if (e?.key == "functions") {
      return history.push(ROUTES.FUNCTIONS);
    } else if (e?.key == "auditable_institutions") {
      return history.push(ROUTES.AUDITABLE_INSTITUTION);
    } else if (e?.key == "charitable_endowments") {
      return history.push(ROUTES.CHARITABLE_ENDOWMENTS);
    } else if (e?.key == "organization_staff_setup") {
      return history.push(ROUTES.ORGANIZATION_STAFF_SETUP);
    } else if (e?.key == "audit_status") {
      return history.push(ROUTES.AUDIT_STATUS);
    } else if (e?.key == "audit_fees") {
      return history.push(ROUTES.AUDIT_FEES);
    } else if (e?.key == "aims") {
      return window.open("https://lfad.tn.gov.in/aimslfad/login");
    } else if (e?.key == "certificate_of_account") {
      return history.push(ROUTES.CERTIFICATE_OF_ACCOUNTS);
    } else if (e?.key == "lfad_act") {
      return history.push(ROUTES.LFAD_ACT);
    } else if (e?.key == "service_rules") {
      return history.push(ROUTES.ACTS_AND_RULES);
    } else if (e?.key == "pension_details") {
      return history.push(ROUTES.PENSION_DETAILS);
    } else if (e?.key == "status_of_pension") {
      return history.push(ROUTES.STATUS_OF_PENSION);
    } else if (e?.key == "contact_us") {
      return history.push(ROUTES.CONTACT_US);
    } else if (e?.key == "status_of_health_fund") {
      return history.push(ROUTES.STATUS_OF_HEALTH_FUND);
    } else if (e?.key == "status_of_fsf") {
      return history.push(ROUTES.STATUS_OF_FSF);
    } else if (e?.key == "app_processing_status") {
      return history.push(ROUTES.APP_PROCESSING_STATUS);
    } else if (e?.key == "pension_forms") {
      return history.push(ROUTES.PENSION_FORMS);
    } else if (e?.key == "important_links") {
      return history.push(ROUTES.IMPORTANT_LINKS);
    } else if (e?.key == "right_to_information_manual") {
      return history.push(ROUTES.RTI);
    } else if (e?.key == "list_of_pio_apio") {
      return history.push(ROUTES.LIST_OF_PIO_APIO);
    } else if (e?.key == "rti_nodal_officers") {
      return history.push(ROUTES.RTI_NODAL_OFFICERS);
    } else if (e?.key == "special_rules") {
      return history.push(ROUTES.SPECIAL_RULES);
    }
  };
  useEffect(() => {
    //console.log(pathname);
    if (pathname?.key == "home") {
      return history.push(ROUTES.HOME);
    } else if (pathname?.Key == "department_of_history") {
      return history.push(ROUTES.ABOUT_US);
    } else if (pathname?.key == "post_staff_details") {
      return history.push(ROUTES.POST_AND_STAFF);
    } else if (pathname?.key == "training") {
      return history.push(ROUTES.TRAINING);
    } else if (pathname?.key == "functions") {
      return history.push(ROUTES.FUNCTIONS);
    } else if (pathname?.key == "auditable_institutions") {
      return history.push(ROUTES.AUDITABLE_INSTITUTION);
    } else if (pathname?.key == "charitable_endowments") {
      return history.push(ROUTES.CHARITABLE_ENDOWMENTS);
    } else if (pathname?.key == "organization_staff_setup") {
      return history.push(ROUTES.ORGANIZATION_STAFF_SETUP);
    } else if (pathname?.key == "audit_status") {
      return history.push(ROUTES.AUDIT_STATUS);
    } else if (pathname?.key == "audit_fees") {
      return history.push(ROUTES.AUDIT_FEES);
    } else if (pathname?.key == "aims") {
      return window.open("https://lfad.tn.gov.in/aimslfad/login");
    } else if (pathname?.key == "certificate_of_account") {
      return history.push(ROUTES.CERTIFICATE_OF_ACCOUNTS);
    } else if (pathname?.key == "lfad_act") {
      return history.push(ROUTES.LFAD_ACT);
    } else if (pathname?.key == "service_rules") {
      return history.push(ROUTES.ACTS_AND_RULES);
    } else if (pathname?.key == "app_processing_status") {
      return history.push(ROUTES.APP_PROCESSING_STATUS);
    } else if (pathname?.key == "pension_forms") {
      return history.push(ROUTES.PENSION_FORMS);
    } else if (pathname?.key == "important_links") {
      return history.push(ROUTES.IMPORTANT_LINKS);
    } else if (pathname?.key == "right_to_information_manual") {
      return history.push(ROUTES.RTI);
    } else if (pathname?.key == "rti_nodal_officers") {
      return history.push(ROUTES.RTI_NODAL_OFFICERS);
    } else if (pathname?.key == "list_of_pio_apio") {
      return history.push(ROUTES.LIST_OF_PIO_APIO);
    } else if (pathname?.key == "special_rules") {
      return history.push(ROUTES.SPECIAL_RULES);
    }
  }, [pathname]);

  return (
    <Box bgImage={color_gradients.transparent_color}>
      <HStack
        // alignContent={"space-evenly"}
        justifyContent={"center"}
        // space={{ lg: "20rem", xl: "44rem" }}
        mt={"1rem"}
        ml={"31px"}
        mr={"31px"}
      >
        <Box
          width={{
            xl: "auto",
          }}
          position={"sticky"}
          top={"0px"}
        >
          <Hidden only={"lg"}>
            <Center>
              <Menu
                className="home_menu_bar"
                selectedKeys={[current]}
                style={{
                  background: color_gradients.transparent_color,
                  color: "white",
                  border: "0px",
                  width: "100%",
                  fontFamily: "Poppins",
                  gap: "0.8rem",
                  fontWeight: "400",
                }}
                mode={"horizontal"}
                items={TopMenu(t)}
                onClick={onMenuClick}
              />
            </Center>
          </Hidden>
        </Box>
      </HStack>
      <HStack
        justifyContent={"center"}
        alignItems={"center"}
        display={{ xl: "none" }}
        color={"white"}
        className={"menu_scroll"}
      >
        <div style={{ position: "sticky", top: "0px" }}>
          <Menu
            className="home_menu_bar"
            selectedKeys={[current]}
            style={{
              background: color_gradients.transparent_color,
              color: "white",
              border: "0px",
              width: "100%",
              fontFamily: "Poppins",
              gap: "2.2px",
              fontWeight: "400",
              fontSize: "22px !important",
            }}
            mode={"horizontal"}
            items={TopMenu(t)}
            onClick={onMenuClick}
          />
        </div>
      </HStack>
    </Box>
  );
};
const MenuBar = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [current, setCurrent] = useState("");
  const [open_menu, setOpenMenu] = useState(false);
  const { pathname } = useLocation();

  const showDrawer = () => {
    setOpenMenu(true);
  };
  const onClose = () => {
    setOpenMenu(false);
  };

  const onMenuClick = (e) => {
    console.log("onMenuClick", e.key);
    if (e?.key == "home") {
      return history.push(ROUTES.HOME);
    } else if (e?.key == "department_of_history") {
      return history.push(ROUTES.ABOUT_US);
    } else if (e?.key == "post_staff_details") {
      return history.push(ROUTES.POST_AND_STAFF);
    } else if (e?.key == "training") {
      return history.push(ROUTES.TRAINING);
    } else if (e?.key == "functions") {
      return history.push(ROUTES.FUNCTIONS);
    } else if (e?.key == "auditable_institutions") {
      return history.push(ROUTES.AUDITABLE_INSTITUTION);
    } else if (e?.key == "charitable_endowments") {
      return history.push(ROUTES.CHARITABLE_ENDOWMENTS);
    } else if (e?.key == "organization_staff_setup") {
      return history.push(ROUTES.ORGANIZATION_STAFF_SETUP);
    } else if (e?.key == "audit_status") {
      return history.push(ROUTES.AUDIT_STATUS);
    } else if (e?.key == "audit_fees") {
      return history.push(ROUTES.AUDIT_FEES);
    } else if (e?.key == "aims") {
      return window.open("https://lfad.tn.gov.in/aimslfad/login");
    } else if (e?.key == "certificate_of_account") {
      return history.push(ROUTES.CERTIFICATE_OF_ACCOUNTS);
    } else if (e?.key == "lfad_act") {
      return history.push(ROUTES.LFAD_ACT);
    } else if (e?.key == "service_rules") {
      return history.push(ROUTES.ACTS_AND_RULES);
    } else if (e?.key == "pension_details") {
      return history.push(ROUTES.PENSION_DETAILS);
    } else if (e?.key == "status_of_pension") {
      return history.push(ROUTES.STATUS_OF_PENSION);
    } else if (e?.key == "contact_us") {
      return history.push(ROUTES.CONTACT_US);
    } else if (e?.key == "status_of_health_fund") {
      return history.push(ROUTES.STATUS_OF_HEALTH_FUND);
    } else if (e?.key == "status_of_fsf") {
      return history.push(ROUTES.STATUS_OF_FSF);
    } else if (e?.key == "app_processing_status") {
      return history.push(ROUTES.APP_PROCESSING_STATUS);
    } else if (e?.key == "pension_forms") {
      return history.push(ROUTES.PENSION_FORMS);
    } else if (e?.key == "important_links") {
      return history.push(ROUTES.IMPORTANT_LINKS);
    } else if (e?.key == "right_to_information_manual") {
      return history.push(ROUTES.RTI);
    } else if (e?.key == "list_of_pio_apio") {
      return history.push(ROUTES.LIST_OF_PIO_APIO);
    } else if (e?.key == "rti_nodal_officers") {
      return history.push(ROUTES.RTI_NODAL_OFFICERS);
    } else if (e?.key == "special_rules") {
      return history.push(ROUTES.SPECIAL_RULES);
    }
  };
  useEffect(() => {
    if (pathname?.key == "home") {
      return history.push(ROUTES.HOME);
    } else if (pathname?.Key == "department_of_history") {
      return history.push(ROUTES.ABOUT_US);
    } else if (pathname?.key == "post_staff_details") {
      return history.push(ROUTES.POST_AND_STAFF);
    } else if (pathname?.key == "training") {
      return history.push(ROUTES.TRAINING);
    } else if (pathname?.key == "functions") {
      return history.push(ROUTES.FUNCTIONS);
    } else if (pathname?.key == "auditable_institutions") {
      return history.push(ROUTES.AUDITABLE_INSTITUTION);
    } else if (pathname?.key == "charitable_endowments") {
      return history.push(ROUTES.CHARITABLE_ENDOWMENTS);
    } else if (pathname?.key == "organization_staff_setup") {
      return history.push(ROUTES.ORGANIZATION_STAFF_SETUP);
    } else if (pathname?.key == "audit_status") {
      return history.push(ROUTES.AUDIT_STATUS);
    } else if (pathname?.key == "audit_fees") {
      return history.push(ROUTES.AUDIT_FEES);
    } else if (pathname?.key == "aims") {
      return window.open("https://lfad.tn.gov.in/aimslfad/login");
    } else if (pathname?.key == "certificate_of_account") {
      return history.push(ROUTES.CERTIFICATE_OF_ACCOUNTS);
    } else if (pathname?.key == "lfad_act") {
      return history.push(ROUTES.LFAD_ACT);
    } else if (pathname?.key == "service_rules") {
      return history.push(ROUTES.ACTS_AND_RULES);
    } else if (pathname?.key == "app_processing_status") {
      return history.push(ROUTES.APP_PROCESSING_STATUS);
    } else if (pathname?.key == "pension_forms") {
      return history.push(ROUTES.PENSION_FORMS);
    } else if (pathname?.key == "important_links") {
      return history.push(ROUTES.IMPORTANT_LINKS);
    } else if (pathname?.key == "right_to_information_manual") {
      return history.push(ROUTES.RTI);
    } else if (pathname?.key == "rti_nodal_officers") {
      return history.push(ROUTES.RTI_NODAL_OFFICERS);
    } else if (pathname?.key == "list_of_pio_apio") {
      return history.push(ROUTES.LIST_OF_PIO_APIO);
    } else if (pathname?.key == "special_rules") {
      return history.push(ROUTES.SPECIAL_RULES);
    }
  }, [pathname]);
  return (
    <>
      <Hidden from="lg" till="3xl">
        <Box
          justifyContent="center"
          height="50px"
          bgImage={color_gradients.transparent_color}
        >
          <HStack space={2} mt={{ xs: "1rem" }} ml={{ xs: "1rem" }}>
            <Box height={"100%"} justifyContent="center">
              <HiMenu color="white" size={"24px"} onClick={showDrawer} />
            </Box>
          </HStack>
        </Box>
      </Hidden>
      <Box>
        <Hidden from="base" till="lg">
          <MenuBarContent />
        </Hidden>
        <Drawer placement="right" open={open_menu} onClose={onClose}>
          <Menu
            selectedKeys={[current]}
            // className="home_menu_bar"
            style={{
              background: "rgb(31 0 95 / 0%)",
              color: "black",
              border: "0px",
              width: "100%",
              fontFamily: "Poppins",
              gap: "1.2rem",
              fontWeight: "400",
              fontSize: "20px !important",
            }}
            onClick={onMenuClick}
            mode={"inline"}
            items={SideMenu(t)}
          />
        </Drawer>
      </Box>
    </>
  );
};
export default MenuBar;
