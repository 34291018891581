import CertificateAccount from "@views/components/certificate_account/certificate_account";
import { Box } from "native-base";
import React from "react";

const CertificateAccountPage = () => {
  return (
    <Box>
      <CertificateAccount />
    </Box>
  );
};
export default CertificateAccountPage;
