import ImportantLinks from "@views/components/important_links/important_links";
import { Box } from "native-base";
import React from "react";

const ImportantLinksPage = () => {
  return (
    <Box>
      <ImportantLinks />
    </Box>
  );
};
export default ImportantLinksPage;
