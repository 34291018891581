import ActsRules from "@views/components/acts_and_rules/service_rules";
import { Box } from "native-base";
import React from "react";

const ActsRulesPage = () => {
  return (
    <Box>
      <ActsRules />
    </Box>
  );
};
export default ActsRulesPage;
