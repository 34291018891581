import { Card } from "antd";
import { Box, Circle, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import Charitable from "@assets/images/charitable.png";
import { FilterItems } from "@helpers/constants";
import { get_cms_charitable_endowments_query } from "@services/redux/slices/graphql/graphql_cms_charitable_endowments_query";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { useDispatch } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import PageLoading from "../about_us/page_loading";

const CharitableEndowments = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [charitable_data, setCharitableData] = useState([]);
  const { items: charitable_items, loading: charitable_loading } =
    useDynamicSelector("get_cms_charitable_endowments");

  const get_charitable_endowments_query = () => {
    let keys = [{ key: "get_cms_charitable_endowments", loading: true }];
    let variables = {
      json: {
        system_cms_key: "charitable_endowments",
      },
    };
    dispatch(
      dynamicRequest(keys, get_cms_charitable_endowments_query, variables)
    );
  };
  useEffect(() => {
    get_charitable_endowments_query();
  }, []);

  useEffect(() => {
    let values = FilterItems(charitable_items);
    setCharitableData(values);
  }, [charitable_items]);

  const Contents = [
    {
      description1:
        "1.The Director of Local Fund Audit, Tamil Nadu is the ex-officio Treasurer of Charitable Endowments for the State. The Government authorises the creation /institution of an endowment through a Government order and the details are published in the Government Gazette. The scheme of administration of the endowment is specified in the Government order.",
      description2:
        "2.The Treasurer of Charitable Endowments is the sole custodian of the endowment funds of the State. The endowment funds are invested in financial institutions Undertaken by Government of Tamil Nadu.",
      description3:
        "3.The interests earned from the investments are disbursed to the Endowment Administrators concerned annually at the behest of the Endowment Administrators.",
      description4:
        "4.There are 4 Central Government Endowments and more than 880 State Government Endowments vested in the Treasurer with the total corpus exceeding Rs.27 Crores.",
    },
  ];

  return (
    <Box variant="contents_box">
      <Card
        style={{
          backgroundColor: "#fcfcfc",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        }}
      >
        <Box variant="heading_box">
          <Circle bg={"#fcfcfc"} size={"140px"}>
            <img src={Charitable} width={"65px"} />
          </Circle>
          <Text variant={"charitable_heading_text"}>
            {t("charitable_endowments")}
          </Text>
        </Box>
        <VStack space={"20px"} p="20px" marginTop={"10px"}>
          {charitable_loading ? (
            <PageLoading />
          ) : (
            <>
              {charitable_data?.map((e) => {
                return (
                  <>
                    <Text variant={"charitable_description_text"}>
                      {ReactHtmlParser(e?.content?.value)}
                    </Text>
                    {/* <Text
                 variant={"charitable_description_text"}
                >
                  {e.description2}
                </Text>
                <Text
                 variant={"charitable_description_text"}
                >
                  {e.description3}
                </Text>
                <Text
                 variant={"charitable_description_text"}
                >
                  {e.description4}
                </Text> */}
                  </>
                );
              })}
            </>
          )}
        </VStack>
      </Card>
    </Box>
  );
};
export default CharitableEndowments;
