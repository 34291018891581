import { Box } from "native-base";
import React from "react";

const AuditStatusTable = () => {
  return (
    <Box>
      <div>
        <table class="table">
          <thead className="table-header">
            <tr>
              <th className="radius-right">{"Sl.No."}</th>
              <th className="radius-left">{"Name of Institution"}</th>
              <th className="radius-left">
                {"Number of auditee institutions"}
              </th>
              <th className="radius-left">
                {"Number of institutions audited"}
              </th>
              <th className="radius-left">
                {"Number of accounts due for audit"}
              </th>
              <th className="radius-left">
                {
                  "Number of Accounts remained to be audited at the close of 2013-2014"
                }
              </th>
            </tr>
          </thead>
          <tbody>
            {/* {TableData?.map((tablelist, index) => {
              return ( */}
            <>
              <tr>
                <td data-label="" className="table-data">
                  {/* {tablelist.year} */}
                </td>
                <td data-label="" className="table-data">
                  {/* {tablelist.description} */}
                </td>
                <td className="table-data"></td>
                <td className="table-data"></td>

                <td className="table-data"></td>
              </tr>
            </>
            {/* );
            })} */}
          </tbody>
        </table>
      </div>
    </Box>
  );
};
export default AuditStatusTable;
