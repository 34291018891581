import Layout1 from "@views/layouts/layout1";
import HomePage from "@views/pages/home/home_page";
import AboutUsPage from "@views/pages/about_us/about_us";
import PostAndStaffPage from "@views/pages/post_and_staff/post_and_staff";
import TrainingPage from "@views/pages/training/training_page";
import FunctionPage from "@views/pages/functions_page/functions";
import AuditableInstitutionPage from "@views/pages/audit/auditable_institution";
import CharitablePage from "@views/pages/charitable_endowments/charitable_page";
import OrganizationStaffPage from "@views/pages/organization_staff_setup/organization_staff_page";
import AuditStatusPage from "@views/pages/audit_status/audit_status_page";
import AuditFeesPage from "@views/pages/audit_fees/audit_fees_page";
import CertificateAccountPage from "@views/pages/certificate_accounts/certificate_accounts_page";
import ActsRulesPage from "@views/pages/acts_and_rules/acts_rules_page";
import PensionDetailsPage from "@views/pages/pension_details/pension_details_page";
import StatusPensionPage from "@views/pages/status_of_pension/status_pension_page";
import ContactUsPage from "@views/pages/contact_us/contact_us";
import StatusOfHealthPage from "@views/pages/status_of_health_fund/status_of_health_page";
import StatusOfFsfPage from "@views/pages/status_of_fsf/status_of_fsf_page";
import AppProcessingStatusPage from "@views/pages/app_processing_status/app_processing_status_page";
import PensionFormsPage from "@views/pages/pension_forms/pension_form_page";
import LfadActPage from "@views/pages/lfad_act/lfad_act_page";
import ImportantLinksPage from "@views/pages/important_links_page/important_links";
import RtiPage from "@views/pages/rti/rti_page";
import ListOfPioApioPage from "@views/pages/list_of_pio_apio_page/list_of_pio_apio";
import RtiNodalOfficerPage from "@views/pages/rti_nodal_officers/rti_nodal_officers_page";
import SpecialRulesPage from "@views/pages/special_rules/special_rules";
import AdminLayout from "@views/layouts/admin_layout";
import ScreenReader from "@views/components/screen_reader/screen_reader";
import cmsList from "@views/pages/cms_input/cms_list";
import cmsDetails from "@views/pages/cms_input/cms_details";
import SystemCMS from "@views/pages/cms/system_cms";
import SystemCmsPage from "@views/pages/cms_content_page/system_cms_content_page";
import PageList from "@views/pages/page/page_list";
import LoginScreen from "@views/pages/sign_in";


// import AccountsSheets from "@views/pages/accounts_sheets";

export const ROUTES = {
  HOME: "/",
  ABOUT_US: "/about-us",
  POST_AND_STAFF: "/post-and-staff",
  ORGANIZATION_STAFF_SETUP: "/organization-staff-setup",
  TRAINING: "/training",
  FUNCTIONS: "/functions",
  AUDITABLE_INSTITUTION: "/auditable-institution",
  AUDIT_STATUS: "/audit-status",
  AUDIT_FEES: "/audit-fees",
  CHARITABLE_ENDOWMENTS: "/charitable-endowments",
  CERTIFICATE_OF_ACCOUNTS: "/certification-of-accounts",
  LFAD_ACT: "/lfad-act",
  ACTS_AND_RULES: "/service-rules",
  SPECIAL_RULES: "/special-rules",
  PENSION_DETAILS: "/pension-details",
  STATUS_OF_PENSION: "/status-of-pension",
  CONTACT_US: "/contact-us",
  STATUS_OF_HEALTH_FUND: "/status-of-health-fund",
  STATUS_OF_FSF: "/status-of-fsf",
  APP_PROCESSING_STATUS: "/app-processing-status",
  PENSION_FORMS: "/pension-forms",
  IMPORTANT_LINKS: "/important-links",
  RTI: "/rti",
  LIST_OF_PIO_APIO: "/list-of-pio-apio",
  RTI_NODAL_OFFICERS: "/rti-nodal-officers",
  SCREEN_READER_ACCESS: "/screen-reader-access",
  //admin pages
  LOGIN: "/admin",
  PAGE: "/page",
  CMS_LIST: "/admin/cms_list",
  CMS_DETAILS: "/admin/cms_details",
  SYSTEM_CMS: "/admin/system-cms",
};

const myRoutes = [
  {
    path: ROUTES.HOME,
    exact: true,
    page_key: "home",
    component: HomePage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.ABOUT_US,
    exact: true,
    page_key: "about_us",
    component: AboutUsPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.POST_AND_STAFF,
    exact: true,
    page_key: "post_and_staff",
    component: PostAndStaffPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.ORGANIZATION_STAFF_SETUP,
    exact: true,
    page_key: "organization_staff",
    component: OrganizationStaffPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.TRAINING,
    exact: true,
    page_key: "training",
    component: TrainingPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.FUNCTIONS,
    exact: true,
    page_key: "functions",
    component: FunctionPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.AUDITABLE_INSTITUTION,
    exact: true,
    page_key: "auditable_institution",
    component: AuditableInstitutionPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.AUDIT_STATUS,
    exact: true,
    page_key: "audit_status",
    component: AuditStatusPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.AUDIT_FEES,
    exact: true,
    page_key: "audit_fees",
    component: AuditFeesPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.CHARITABLE_ENDOWMENTS,
    exact: true,
    page_key: "charitable_endowments",
    component: CharitablePage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.CERTIFICATE_OF_ACCOUNTS,
    exact: true,
    page_key: "certification_of_accounts",
    component: CertificateAccountPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.LFAD_ACT,
    exact: true,
    page_key: "lfad_act",
    component: LfadActPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.ACTS_AND_RULES,
    exact: true,
    page_key: "acts_and_rules",
    component: ActsRulesPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.SPECIAL_RULES,
    exact: true,
    page_key: "special-rules",
    component: SpecialRulesPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.PENSION_DETAILS,
    exact: true,
    page_key: "pension_details",
    component: PensionDetailsPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.STATUS_OF_PENSION,
    exact: true,
    page_key: "status_of_pension",
    component: StatusPensionPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.CONTACT_US,
    exact: true,
    page_key: "contact_us",
    component: ContactUsPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.STATUS_OF_HEALTH_FUND,
    exact: true,
    page_key: "status_of_health",
    component: StatusOfHealthPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.STATUS_OF_FSF,
    exact: true,
    page_key: "status_of_fsf",
    component: StatusOfFsfPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.APP_PROCESSING_STATUS,
    exact: true,
    page_key: "app_processing_status",
    component: AppProcessingStatusPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.PENSION_FORMS,
    exact: true,
    page_key: "pension_forms",
    component: PensionFormsPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.IMPORTANT_LINKS,
    exact: true,
    page_key: "important_links",
    component: ImportantLinksPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.RTI,
    exact: true,
    page_key: "rti",
    component: RtiPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.LIST_OF_PIO_APIO,
    exact: true,
    page_key: "list_of_pio_apio",
    component: ListOfPioApioPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.RTI_NODAL_OFFICERS,
    exact: true,
    page_key: "rti_nodal_officers",
    component: RtiNodalOfficerPage,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.SCREEN_READER_ACCESS,
    exact: true,
    page_key: "screen_reader_access",
    component: ScreenReader,
    authenticate: false,
    layout: Layout1,
  },
  {
    path: ROUTES.LOGIN,
    exact: true,
    page_key: "Login",
    component: LoginScreen,
    // layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.PAGE,
    exact: true,
    page_key: "page",
    component: PageList,
    layout: AdminLayout,
    authenticate: false,
  },
  {
    path: `${ROUTES.CMS_LIST}`,
    exact: true,
    page_key: "cms-list",
    component: cmsList,
    layout: AdminLayout,
    authenticate: false,
  },
  {
    path: `${ROUTES.CMS_DETAILS}/:id?`,
    exact: true,
    page_key: "cms-details",
    component: cmsDetails,
    layout: AdminLayout,
    authenticate: false,
  },
  {
    path: ROUTES.SYSTEM_CMS,
    exact: true,
    page_key: "system-cms",
    component: SystemCMS,
    // layout: AdminLayout,
    authenticate: false,
  },
  {
    path: `${ROUTES.SYSTEM_CMS}/:id`,
    exact: true,
    page_key: "system-cms-details",
    component: SystemCmsPage,
    authenticate: false,
  },
  // {
  //   key: "Accounts Sheets",
  //   component: UserLayout,
  //   authenticate: true,
  //   children: [
  //     {
  //       path: ROUTES.ACCOUNTS_SHEETS,
  //       exact: true,
  //       key: "Accounts Sheets",
  //       authenticate: true,
  //       component: AccountsSheets,
  //     },
  //   ],
  // },
];

export default myRoutes;
