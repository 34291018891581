import RtiNodalOfficer from "@views/components/rti_nodal_officers/rti_nodal_officers";
import { Box } from "native-base";
import React from "react";

const RtiNodalOfficerPage = () => {
  return (
    <Box>
      <RtiNodalOfficer />
    </Box>
  );
};
export default RtiNodalOfficerPage;
