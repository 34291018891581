import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, Text, HStack, Pressable, Link } from "native-base";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";

import {
  getcmsListSelector,
  get_cms,
  deletecmsSelector,
  delete_one_cms_modal,
  delete_cms,
  get_one_cms_clear,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { Button, Pagination, Popconfirm, Space, Table } from "antd";
import { startCase } from "lodash";
import Wrapper from "@views/components/system/ui/wrapper";
import Dialog from "@views/components/system/ui/dialog";
import { showToast } from "@helpers/functions";
import SearchBox from "@views/components/system/ui/search_box/search_box";
import ActionButton from "@views/components/system/ui/dialog/action_button";
import { countFormat } from "@helpers/constants";
import { get_cms_query } from "@services/redux/slices/cms/graphql";

const cmsList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [search_string, set_search_string] = useState('');
  const [actionItem, setActionItem] = useState({});
  const [manageModalVisible, setManageModalVisible] = useState(false);
  const { loading: cms_list_loading, total_items, page_number: cms_page_number, page_limit: cms_page_limit, items: cms_list, } = useSelector(getcmsListSelector);

  const [page_limit, setPageLimit] = useState(cms_page_limit || 10);
  const [page_number, setPageNumber] = useState(cms_page_number || 1);
  const { t } = useTranslation();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [key, setKeys] = useState();

  const handleAdd = () => {
    dispatch(get_one_cms_clear());
    history.push({
      pathname: `${ROUTES.CMS_DETAILS}`,
      // state: `${item.name}`,
    });
  };

  const handleEdit = (item) => {
    console.log("handleEdit", item);
    history.push({
      pathname: `${ROUTES.CMS_DETAILS}/${item.id}`,
      // state: `${item.name}`,
    });
  };

  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };

  const handleModalClose = () => {
    setManageModalVisible(false);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };
  const handleKeys = (keys) => {
    console.log("keys", keys);
    setKeys(keys.key);
  };
  const handleSearch = (letter) => {

    if (letter.length > 2||letter.length ===0) {
      set_search_string(letter)
    }
    
  };

  // const :cls = [
  //   {
  //     name:"About us",
  //     key: "About us",
  //   },
  // ];

  // console.log(loading, :cls);
  const {
    loading: deleteLoading,
    status: deleteStatus,
    error: deleteError,
  } = useSelector(deletecmsSelector);
  // console.table(items);
  console.log("deleteStatus", deleteStatus);
  useEffect(() => {
    if (deleteError) {
      setDialogVisible(false);
      showToast({
        type: "error",
        message: deleteError,
      });
    } else if (deleteStatus === "success") {
      showToast({
        type: "success",
        message: `Cms ${t("deleted_successfully")}`,
      });
      setDialogVisible(false);
      dispatch(get_cms());
      dispatch(delete_one_cms_modal());
      handleDialogClose();
    }
  }, [deleteStatus]);

  const formatName = (value) => {
    console.log("value", value);
    value.map((y) => {
      return y.name;
    });
  };

  let columns = [
    {
      title: t("table:name"),
      dataIndex: "",
      key: "name",
      sortable: false,
      align: "left",
      justifyContent: "center",
      render: (e) => {
        console.log("name", e);
        return <Text>{startCase(e?.system_cms_names?.[0]?.name)}</Text>;
      },
    },
    {
      title: t("table:key"),
      dataIndex: "key",
      key: "key",
      sortable: false,
      align: "left",
      justifyContent: "center",
    },
    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "center",
      type: "actions",
      width: 200,
      render: (e) => {
        return (
          <>
            <Space space={2}>
              <Pressable
                onPress={() => {
                  handleEdit(e);
                }}
              >
                <AiOutlineEdit color="#06b506" size={20} />
                {/* <CustomizeButton.IconButton icon={<AiOutlineEdit />} /> */}
              </Pressable>
              <Pressable
                onPress={() => {
                  handleDelete(e);
                }}
              >
                <AiOutlineDelete color="red" size={20} />
              </Pressable>
            </Space>
          </>
        );
      },
    },
  ];
  useEffect(() => {
    dispatch(
      get_cms({
        json: {
          page_number: page_number,
          page_limit: page_limit,
          search_string: search_string
        }
      })
    );
  }, [page_number, page_limit, search_string]);

  let header_actions = [
    {
      height: "50px",
      label: t("table:add_cms"),
      colorScheme: "primary",
      variant: "outline",
      _text: { color: "green" },
      style: { border: "1px solid green" },
      onPress: handleAdd,
    },
  ];
  const fetchData = (value, value1) => {
    setPageNumber(value);
    setPageLimit(value1);
  };

  return (
    <>
      <Box width="95%" marginLeft="20px" marginRight="20px">
        <Box position="sticky" top="0px" zIndex="2" bg="white">
          <Text fontSize="md" fontWeight="bold" py={4}>
            {t("CMS")}
          </Text>
        </Box>
        <HStack
          variant="admin_search_component"
          alignItems={"flex-end"}
          justifyContent={"flex-end"}
          space={3}
          padding={"10px"}
        >
          {header_actions?.map((headerAction, index) => (
            <Box key={`header-action-${index}`}>
              <ActionButton {...headerAction} />
            </Box>
          ))}
          <Box>
            <SearchBox
              width={300}
              label="Search"
              onSearch={handleSearch}
              onKeyPress={handleKeys}
            />
          </Box>
        </HStack>
        <VStack space={5}>
          <Table
            loading={cms_list_loading}
            dataSource={cms_list}
            columns={columns}
            pagination={false}
            className="table-admin"
          />
          {!cms_list_loading &&
            <HStack justifyContent={"flex-end"}>
              <Pagination
                total={total_items}
                page_number={cms_page_number}
                hideOnSinglePage={false}
                onChange={fetchData}
                showTotal={(total, range) => `Total ${countFormat(total_items)} items`}
                showSizeChanger
              />
            </HStack>
          }
        </VStack>
      </Box>
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("error:delete_cms")}
        content={t("error:delete_cms_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: deleteLoading,
            formErrorMessage: t("error:error_message"),
            submit: delete_cms,
          },
        ]}
      />
    </>
  );
};
export default cmsList;
