import Vision from "@views/components/vision_page/vision_page";
import { Box } from "native-base";
import React from "react";

const VisionPage = (props) => {
  const { title, text1, text2, text3, text4 ,vision_data} = props;
  console.log("focus1", focus);
  return (
    <Box height="auto">
      <Vision
      vision_data={vision_data}
        // title={title}
        // text1={text1}
        // text2={text2}
        // text3={text3}
        // text4={text4}
      />
    </Box>
  );
};
export default VisionPage;
