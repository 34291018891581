import { Card } from "antd";
import { Box, Circle, HStack, Stack, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import ContactImage from "@assets/images/contact.png";
import { useDispatch } from "react-redux";
import { FilterItems } from "@helpers/constants";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { get_cms_contact_us_query } from "@services/redux/slices/graphql/graphql_cms_contact_us_query";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import PageLoading from "../about_us/page_loading";

const ContactUs = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [contact_data, setContactData] = useState([]);
  const { items: contact_items, loading: contact_loading } =
    useDynamicSelector("get_cms_contact_us");

  const get_contact_us_query = () => {
    let keys = [{ key: "get_cms_contact_us", loading: true }];
    let variables = {
      json: {
        system_cms_key: "contact_us",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_contact_us_query, variables));
  };

  useEffect(() => {
    get_contact_us_query();
  }, []);
  useEffect(() => {
    let values = FilterItems(contact_items);
    setContactData(values);
  }, [contact_items]);

  const Contents = [
    {
      title: "Address",
      text1: "DIRECTOR",
      text2: "DIRECTORATE OF LOCAL FUND AUDIT",
      text3: "Integrated Office Complex for Finance Department",
      text4: "Anna Salai,Nandhanam",
      text5: "Chennai - 600 035.",
      text6: "Email: tnlfa@yahoo.co.in",
    },
  ];
  return (
    <Box px={{ lg: "100px", xs: "50px" }} py="100px">
      <Card
        style={{
          backgroundColor: "#fcfcfc",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        }}
      >
        <Box
          alignItems={"center"}
          style={{
            marginTop: "-90px",
          }}
        >
          <Circle bg={"#fcfcfc"} size={"140px"}>
            <img src={ContactImage} width={"65px"} />
          </Circle>
          <Text
           variant={"contact_us_heading_text"}
          >
            {t("contact_us")}
          </Text>
        </Box>
        <Stack
          display={"flex"}
          flexDirection={{ xs: "column", lg: "row" }}
          alignItems={"center"}
          justifyContent={"space-evenly"}
        >
          <Box space={"30px"}>
            {contact_loading ? (
              <PageLoading />
            ) : (
              <>
                {contact_data?.map((e) => {
                  return (
                    <>
                      <Text>{ReactHtmlParser(e?.content?.value)}</Text>
                      {/* <Text
                  fontSize={{ lg: "24px", xs: "20px" }}
                  textAlign={"justify"}
                  bold
                  color={"#002555"}
                  fontFamily={"Montserrat-SemiBold"}
                >
                  {e.title}
                </Text>
                <Box>
                  <Text
                    fontSize={{ lg: "16px", xs: "12px" }}
                    textAlign={"justify"}
                    fontFamily={"Montserrat-Medium"}
                    bold
                  >
                    {e.text1}
                  </Text>
                  <Text
                    fontSize={{ lg: "16px", xs: "12px" }}
                    textAlign={"justify"}
                    fontFamily={"Montserrat-Medium"}
                    bold
                  >
                    {e.text2}
                  </Text>
                  <Text
                    fontSize={{ lg: "16px", xs: "12px" }}
                    textAlign={"justify"}
                    fontFamily={"Montserrat-Medium"}
                    bold
                  >
                    {e.text3}
                  </Text>
                  <Text
                    fontSize={{ lg: "16px", xs: "12px" }}
                    textAlign={"justify"}
                    fontFamily={"Montserrat-Medium"}
                    bold
                  >
                    {e.text4}
                  </Text>
                  <Text
                    fontSize={{ lg: "16px", xs: "12px" }}
                    textAlign={"justify"}
                    fontFamily={"Montserrat-Medium"}
                    bold
                  >
                    {e.text5}
                  </Text>
                  <Text
                    fontSize={{ lg: "16px", xs: "12px" }}
                    textAlign={"justify"}
                    fontFamily={"Montserrat-Medium"}
                    bold
                  >
                    {e.text6}
                  </Text>
                </Box> */}
                    </>
                  );
                })}
              </>
            )}
          </Box>
          <div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.170176333261!2d80.2299474733044!3d13.024832713712541!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a526707f244ae39%3A0xd98d424c72bebc09!2sIntegrated%20Finance%20Complex!5e0!3m2!1sen!2sin!4v1709186896846!5m2!1sen!2sin"
            width="500"
            height="360"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            style={{border:"0px"}}
          ></iframe>
          </div>
        </Stack>
      </Card>
    </Box>
  );
};
export default ContactUs;
