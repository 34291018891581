import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { deletePageMutation } from "./graphql";

const initialState = {
    loading: false,
    error: null,
    id: null,
    status: null,
};

const slice = createSlice({
    name: "pageDelete",
    initialState,
    reducers: {
        _delete_page: (state) => {
            state.loading = true;
            state.id = null;
            state.status = null;
        },
        _delete_page_success: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.id = payload.id;
            state.status = payload.status;
        },
        _delete_page_failure: (state, { payload }) => {
            state.loading = false;
            state.error = payload.message;
        },
    },
});

const { _delete_page, _delete_page_success, _delete_page_failure } =
    slice.actions;

export const deletePageSelector = (state) => state.pageDelete;

export const deletePageReducer = slice.reducer;

export function delete_page(variables) {
    return async (dispatch) => {
        dispatch(_delete_page());
        try {
            const response = await MutateRequest(
                deletePageMutation,
                variables,
                dispatch
            );
            if (
                response?.data?.deletePage &&
                !response?.data?.deletePage.error
            ) {
                dispatch(_delete_page_success(response?.data?.deletePage));
            } else if (response?.data?.deletePage?.error) {
                dispatch(
                    _delete_page_failure(response?.data?.deletePage.error)
                );
            }
        } catch (error) {
            dispatch(_delete_page_failure(error));
        }
    };
}
export function delete_one_page_modal() {
    return async (dispatch) => {
        dispatch(_delete_page());
    };
}
