import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Box, Stack, VStack } from "native-base";
import HeaderBar from "@views/pages/home/header_bar";
import MenuBar from "@views/components/mission_page/menu_bar";
import FooterPage from "@views/components/footer_page/footer";
import ScrollView from "@views/components/system/ui/scroll_view/scroll_view";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { dynamicSet, useDynamicSelector } from "@services/redux";
import Banner from "@views/components/mission_page/banner";
import FontScaler from "../../font_scaler";

const Layout1 = ({ children }) => {
  const { pathname } = useLocation();
  const page = useDynamicSelector("page_second");

  const [isMissionPage, setIsMissionPage] = useState(false);
  const [shouldShowBoxShadow, setShouldShowBoxShadow] = useState(false);
  console.log("shouldShowBoxShadow");

  useEffect(() => {
    setIsMissionPage(pathname === "/mission");
  }, [pathname]);
  console.log(
    "hello",
    document.documentElement.scrollTop,
    document.body.scrollTop
  );
  const handleScroll = () => {
    const scrollTop = scrollContainerRef.current.scrollTop || 0;
    const threshold = 100;
    console.log("scrollTop", scrollContainerRef.current.scrollTop);
    setShouldShowBoxShadow(scrollTop > threshold);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box flexDirection="row" flexGrow="1">
      <ScrollView>
        <VStack flex={1}>
          {pathname === "/" ? (
            <>
              <Box backgroundColor={"#002555"} zIndex={10000} height="auto">
                <Box
                  bgImage={"linear-gradient(180deg, #002555,#496385)"}
                  style={{
                    boxShadow: "20px 0px 259px 50px #002555",
                  }}
                >
                  <Box 
                  bgImage={"linear-gradient(180deg, #002555,transparent)"}
                  >
                    <Box zIndex={1}>
                      <Banner />
                    </Box>
                    <Stack
                      display={"flex"}
                      flexDirection={{ xs: "row", lg: "column" }}
                      alignItems={{ xs: "center", lg: "" }}
                    >
                      <Box
                        backgroundColor={"#002555"}
                        style={{
                          // boxShadow: "rgb(0, 37, 85) 21px 41px 182px 220px",
                        }}
                      >
                        <HeaderBar />
                      </Box>
                      <Box
                      backgroundColor={"#002555"}
                        // bgImage={"linear-gradient(180deg, #002555,transparent)"}
                      >
                        <MenuBar />
                      </Box>
                    </Stack>
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box
                // bgImage={"linear-gradient(180deg, #002555,#496385)"}
                backgroundColor={"#002555"}
                // position={"sticky"}
                // top={"0px"}
                zIndex={10000}
                height="auto"
                style={
                  {
                    // boxShadow: "0px 50px 1000px 1px rgb(68 95 130)",
                  }
                }
              >
                <Box>
                  <Banner />
                </Box>
                <Stack
                  display={"flex"}
                  flexDirection={{ xs: "row", lg: "column" }}
                  alignItems={{ xs: "center", lg: "" }}
                >
                  <Box>
                    <HeaderBar />
                  </Box>
                  <Box>
                    <MenuBar />
                  </Box>
                </Stack>
              </Box>
            </>
          )}

          <Box flex={1} minHeight={"500px"} height={"auto"}>
            <FontScaler>{children}</FontScaler>
          </Box>
          <FooterPage />
        </VStack>
      </ScrollView>
    </Box>
  );
};
export default Layout1;
