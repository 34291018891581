import { Button, Form } from "antd";
import JoditEditor from "jodit-react";
import VForm from "../antd_form/antd_form";
import { useEffect } from "react";
import { startCase } from "lodash";
import { HStack } from "native-base";
import { Form as AntdForm } from "antd";

const HTMLForm = ({ htmlForm, items, onFinishHtml, cms_loading }) => {
  const [form] = AntdForm.useForm();
  let html_data =
    items?.find((field) => field.key === "code")?.system_cms_values?.[0] || {};
  htmlForm.resetFields();
  useEffect(() => {
    let values = items?.map((list) => {
      if (list.type === "file")
        if (list?.system_cms_values?.[0]?.value) {
          return {
            [list.id]: [
              {
                uid: "-1",
                url: list?.system_cms_values?.[0]?.value || "",
                status: "done",
                name: "View",
              },
            ],
          };
        } else {
          return [];
        }
      else {
        return { [list.id]: list?.system_cms_values?.[0]?.value };
      }
    });
    console.log("values",values,items);
    let setValues = Object.assign({}, ...values);
    if (setValues) {
      form.setFieldsValue(setValues);
    }
  }, [items]);
  return (
    <VForm form={form} onFinish={onFinishHtml}>
      <div className="form-container">
        {items?.map((list) => {
          console.log("listlist", list);
          return (
            <>
              {list?.type === "text" &&
              list?.key === "stadium_name" &&
              key_name === "Tarrif For Competition 2" ? (
                <VForm.Select
                  label={startCase(list?.key)}
                  field={list?.id}
                  options={stadium_option}
                  valueField="id"
                  labelFiled="name"
                  rules={[
                    {
                      required: true,
                      message: `${startCase(list?.key)} is required`,
                    },
                  ]}
                />
              ) : list?.type === "text" ? (
                <VForm.TextBox
                  label={startCase(list?.key)}
                  field={list?.id}
                  rules={[
                    {
                      required: true,
                      message: `${startCase(list?.key)} is required`,
                    },
                  ]}
                />
              ) : list?.type === "textarea" ? (
                <VForm.TextArea
                  label={startCase(list?.key)}
                  field={list?.id}
                  rules={[
                    {
                      required: true,
                      message: `${startCase(list?.key)} is required`,
                    },
                  ]}
                />
              ) : list?.type === "file" ? (
                <VForm.FormFile
                  label={startCase(list?.key)}
                  field={list?.id || ""}
                  // getValueFromEvent={normFile}
                  allowed_file_formats={["pdf", "png", "jpg"]}
                  rules={[
                    {
                      required: true,
                      message: `${startCase(list?.key)} is required`,
                    },
                  ]}
                />
              ) : list?.type === "number" ? (
                <VForm.Number
                  label={startCase(list?.key)}
                  field={list?.id}
                  rules={[
                    {
                      required: true,
                      message: `${startCase(list?.key)} is required`,
                    },
                  ]}
                />
              ) : list?.type === "datetime" ? (
                <VForm.Date
                  label={startCase(list?.key)}
                  field={list?.id}
                  rules={[
                    {
                      required: true,
                      message: `${startCase(list?.key)} is required`,
                    },
                  ]}
                />
              ) : list.type === "rich_text" ? (
                <VForm.RichText
                  label={startCase(list?.key)}
                  field={list?.id}
                  rules={[
                    {
                      required: true,
                      message: `${startCase(list?.key)} is required`,
                    },
                  ]}
                />
              ) : null}
            </>
          );
        })}
      </div>
      <HStack space={3} justifyContent={"flex-end"}>
        <VForm.Button isLoading={cms_loading} />
      </HStack>
    </VForm>
  );
};

export default HTMLForm;
