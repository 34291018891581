import { Box, HStack, Stack, Text, VStack } from "native-base";
import React from "react";
import VisionImage from "@assets/images/vision.png";
import ReactHtmlParser from "react-html-parser";

const Vision = (props) => {
  const { vision_data } = props;
  console.log("vision_data", vision_data);
  return (
    <Stack
      display={"flex"}
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent={"space-evenly"}
      alignItems={"center"}
      backgroundColor={"#002555"}
      p="20px"
    >
      {vision_data?.map((item) => {
        return (
          <>
            <VStack>
              <Box
                w={{ xs: "300px", md: "500px", lg: "500px" }}
                style={{ gap: "20px" }}
                // px="50px"
              >
                <Text variant={"heading_style_text"}>{item?.title?.value}</Text>
                <Text variant={"description_style_text"}>
                  {ReactHtmlParser(item?.content?.value)}
                </Text>
                {/* <Text
            fontSize={{ xs: "14px", lg: "16px",xl:"18px" }}
            fontFamily={"Montserrat-Medium"}
            textAlign="justify"
            color="white"
          >
            {text2}
          </Text>
          <Text
           fontSize={{ xs: "14px", lg: "16px",xl:"18px" }}
            fontFamily={"Montserrat-Medium"}
            textAlign="justify"
            color="white"
          >
            {text3}
          </Text>
          <Text
            fontSize={{ xs: "14px", lg: "16px",xl:"18px" }}
            fontFamily={"Montserrat-Medium"}
            textAlign="justify"
            color="white"
          >
            {text4}
          </Text> */}
              </Box>
            </VStack>
            <Box w={{ xs: "80%", lg: "35%", xl: "50%" }}>
              <img src={VisionImage} size={"100%"} style={{ opacity: "0.6" }} />
            </Box>
          </>
        );
      })}
    </Stack>
  );
};
export default Vision;
