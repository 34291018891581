import { Box, HStack, Stack, Text, VStack } from "native-base";
import React from "react";
import ObjectiveImage from "@assets/images/Objective.jpg";
import ReactHtmlParser from "react-html-parser";

const Objective = (props) => {
  const { objective_data } = props;
  return (
    <Stack
      display={"flex"}
      flexDirection={{ xs: "column", lg: "row" }}
      // space="10px"
      justifyContent={"space-evenly"}
      alignItems={"center"}
      backgroundColor={"#f0f1f5"}
      py="20px"
    >
      {objective_data?.map((item) => {
        return (
          <>
            <Box w={{ xs: "80%", lg: "35%", xl: "50%" }}>
              <img
                src={item?.image?.value}
                size={"100%"}
                style={{ opacity: "0.6" }}
              />
            </Box>
            <VStack>
              <Box
                width={{ xs: "300px", md: "500px", lg: "500px" }}
                style={{ gap: "20px" }}
                // px="50px"
              >
                <Text variant={"heading_size_text"}>{item?.title?.value}</Text>
                <Text variant={"description_size_text"}>
                  {ReactHtmlParser(item?.content?.value)}
                </Text>
                {/* <Text
                  fontSize={{ xs: "14px", lg: "16px", xl: "18px" }}
                  fontFamily="Montserrat-Medium"
                  textAlign="justify"
                  color="#002555"
                >
                  {text2}
                </Text>
                <Text
                  fontSize={{ xs: "14px", lg: "16px", xl: "18px" }}
                  fontFamily={"Montserrat-Medium"}
                  textAlign="justify"
                  color="#002555"
                >
                  {text3}
                </Text>
                <Text
                  fontSize={{ xs: "14px", lg: "16px", xl: "18px" }}
                  fontFamily={"Montserrat-Medium"}
                  textAlign="justify"
                  color="#002555"
                >
                  {text4}
                </Text>
                <Text
                  fontSize={{ xs: "14px", lg: "16px", xl: "18px" }}
                  fontFamily={"Montserrat-Medium"}
                  textAlign="justify"
                  color="#002555"
                >
                  {text5}
                </Text>
                <Text
                  fontSize={{ xs: "14px", lg: "16px", xl: "18px" }}
                  fontFamily={"Montserrat-Medium"}
                  textAlign="justify"
                  color="#002555"
                >
                  {text6}
                </Text>
                <Text
                  fontSize={{ xs: "14px", lg: "16px", xl: "18px" }}
                  fontFamily={"Montserrat-Medium"}
                  textAlign="justify"
                  color="#002555"
                >
                  {text7}
                </Text>
                <Text
                  fontSize={{ xs: "14px", lg: "16px", xl: "18px" }}
                  fontFamily={"Montserrat-Medium"}
                  textAlign="justify"
                  color="#002555"
                >
                  {text8}
                </Text>
                <Text
                  fontSize={{ xs: "14px", lg: "16px", xl: "18px" }}
                  fontFamily={"Montserrat-Medium"}
                  textAlign="justify"
                  color="#002555"
                >
                  {text9}
                </Text>
                <Text
                  fontSize={{ xs: "14px", lg: "16px", xl: "18px" }}
                  fontFamily={"Montserrat-Medium"}
                  textAlign="justify"
                  color="#002555"
                >
                  {text10}
                </Text>
                <Text
                  fontSize={{ xs: "14px", lg: "16px", xl: "18px" }}
                  fontFamily={"Montserrat-Medium"}
                  textAlign="justify"
                  color="#002555"
                >
                  {text11}
                </Text> */}
              </Box>
            </VStack>
          </>
        );
      })}
    </Stack>
  );
};
export default Objective;
