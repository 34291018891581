import { Box, HStack, Stack, Text, VStack } from "native-base";
import React from "react";
import Focus from "@assets/images/focus.jpg";
import ReactHtmlParser from "react-html-parser";

const Mission = (props) => {
  const { mission_data } = props;

  return (
    <>
      {mission_data?.map((item) => {
        return (
          <Stack
            // space={"10px"}
            display={"flex"}
            flexDirection={{ xs: "column", lg: "row", xl: "row", "2xl": "row" }}
            justifyContent={"space-evenly"}
            alignItems={"center"}
            backgroundColor={"#f0f1f5"}
            p="20px"
          >
            <Box
              w={{ xs: "80%", lg: "35%", xl: "40%", "2xl": "40%" }}
              // style={{ boxShadow: "10pX 0px 0px #f0f1f5" }}
            >
              <img
                src={item?.image?.value}
                size={"100%"}
                style={{ opacity: "0.6" }}
              />
            </Box>
            <VStack>
              <Box
                width={{ xs: "300px", md: "500px", lg: "500px" }}
                style={{ gap: "20px" }}
                // px="50px"
              >
                <Text variant={"heading_size_text"}>{item?.title?.value}</Text>
                <Text
                 variant={"description_size_text"}
                >
                  {ReactHtmlParser(item?.content?.value)}
                </Text>
              </Box>
            </VStack>
          </Stack>
        );
      })}
    </>
  );
};
export default Mission;
