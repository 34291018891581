import { Box, HStack, Image, Stack, Text } from "native-base";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import HeaderBar from "./header_bar";
import MenuBar from "../../components/mission_page/menu_bar";
import MissionPage from "../mission_page/mission_page";
import VisionPage from "../vision_page/vision_page";
import ObjectivePage from "../objective_page/objective_page";
import AuthorityPage from "../Autority_page/autority_page";
import HomepageImage from "@assets/images/homeimage.jpg";
import HomepageImage2 from "@assets/images/auditimage.jpg";
import HomepageImage3 from "@assets/images/1599225624880.png";
import HomepageImage4 from "@assets/images/homeslideimage.jpg";
import HomepageImage5 from "@assets/images/financialaudit.jpg";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { useDispatch } from "react-redux";
import { get_cms_mission_query } from "@services/redux/slices/graphql/graphql_cms_mission_query";
import { FilterItems } from "@helpers/constants";
import { get_cms_vision_query } from "@services/redux/slices/graphql/graphql_cms_vision_query";
import { get_cms_objective_query } from "@services/redux/slices/graphql/graphql_cms_objective_query";
import { get_cms_authority_query } from "@services/redux/slices/graphql/graphql_cms_authority_query";
import HomePageLoading from "./home_page_loading";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import LatestNews from "@views/components/upcoming_events/latest_news";
import UpcomingEvent from "@views/components/upcoming_events/upcoming_events";
import { get_cms_upcoming_events_query } from "@services/redux/slices/graphql/graphql_cms_upcoming_event_query";
import { get_cms_latest_news_query } from "@services/redux/slices/graphql/graphql_cms_latest_news";
import { get_cms_home_page_slider_query } from "@services/redux/slices/graphql/graphql_homepage_slider_query";

const HomePage = () => {
  const dispatch = useDispatch();
  // const  page  = useDynamicSelector("page_second");
  const [mission_data, setMissionData] = useState([]);
  const [vision_data, setVisionData] = useState([]);
  const [objective_data, setObjectiveData] = useState([]);
  const [authority_data, setAuthorityData] = useState([]);
  const [events_data, setEventsData] = useState([]);
  const [latest_data, setLatestData] = useState([]);
  const [slide_data, setSlideData] = useState([]);

  const { items: mission_items, loading: mission_loading } =
    useDynamicSelector("get_cms_mission");
  const { items: vision_items, loading: vision_loading } =
    useDynamicSelector("get_cms_vision");
  const { items: objective_items, loading: objective_loading } =
    useDynamicSelector("get_cms_objective");
  const { items: authority_items, loading: authority_loading } =
    useDynamicSelector("get_cms_authority");
  const { items: events_items, loading: upcoming_loading } = useDynamicSelector(
    "get_cms_upcoming_events"
  );
  const { items: news_items, loading: news_loading } = useDynamicSelector(
    "get_cms_latest_news"
  );
  const { items: slider_items, loading: slider_loading } = useDynamicSelector(
    "get_cms_homepage_slider"
  );
  console.log("slider_items", slide_data);

  const get_mission_query = () => {
    let keys = [{ key: "get_cms_mission", loading: true }];
    let variables = {
      json: {
        system_cms_key: "Mission",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_mission_query, variables));
  };

  const get_vision_query = () => {
    let keys = [{ key: "get_cms_vision", loading: true }];
    let variables = {
      json: {
        system_cms_key: "vision_content",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_vision_query, variables));
  };
  const get_objective_query = () => {
    let keys = [{ key: "get_cms_objective", loading: true }];
    let variables = {
      json: {
        system_cms_key: "objective_content",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_objective_query, variables));
  };
  const get_authority_query = () => {
    let keys = [{ key: "get_cms_authority", loading: true }];
    let variables = {
      json: {
        system_cms_key: "authority_content",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_authority_query, variables));
  };

  const get_upcoming_events = () => {
    let keys = [{ key: "get_cms_upcoming_events", loading: true }];
    let variables = {
      json: {
        system_cms_key: "upcoming events",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_upcoming_events_query, variables));
  };
  const get_latest_news = () => {
    let keys = [{ key: "get_cms_latest_news", loading: true }];
    let variables = {
      json: {
        system_cms_key: "latest_news",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_latest_news_query, variables));
  };

  const get_homepage_slider = () => {
    let keys = [{ key: "get_cms_homepage_slider", loading: true }];
    let variables = {
      json: {
        system_cms_key: "home_page_slider",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_home_page_slider_query, variables));
  };

  useEffect(() => {
    get_mission_query();
    get_vision_query();
    get_objective_query();
    get_authority_query();
    get_upcoming_events();
    get_latest_news();
    get_homepage_slider();
  }, []);

  useEffect(() => {
    let values = FilterItems(mission_items);
    setMissionData(values);
  }, [mission_items]);

  useEffect(() => {
    let values = FilterItems(vision_items);
    setVisionData(values);
  }, [vision_items]);
  useEffect(() => {
    let values = FilterItems(objective_items);
    setObjectiveData(values);
  }, [objective_items]);
  useEffect(() => {
    let values = FilterItems(authority_items);
    setAuthorityData(values);
  }, [authority_items]);
  useEffect(() => {
    let values = FilterItems(events_items);
    setEventsData(values);
  }, [events_items]);
  useEffect(() => {
    let values = FilterItems(news_items);
    setLatestData(values);
  }, [news_items]);

  useEffect(() => {
    let values = FilterItems(slider_items);
    setSlideData(
      values.sort((a, b) => Number(a?.order?.value) - Number(b?.order?.value))
    );
  }, [slider_items]);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    large_laptop: {
      breakpoint: { max: 2560, min: 1640 },
      items: 1,
      slidesToSlide: 1,
    },
    laptop: {
      breakpoint: { max: 1024, min: 480 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet1: {
      breakpoint: { max: 768, min: 480 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  // const layer_image = [
  //   {
  //     img: HomepageImage,
  //   },
  //   {
  //     img: HomepageImage2,
  //   },
  //   {
  //     img: HomepageImage3,
  //   },
  //   {
  //     img: HomepageImage4,
  //   },
  //   {
  //     img: HomepageImage5,
  //   },
  // ];
  return (
    <>
      <div>
        <Carousel
          responsive={responsive}
          customLeftArrow={<></>}
          customRightArrow={<></>}
          infinite={true}
          autoPlay={true}
          showDots={false}
        >
          {slide_data?.map((item) => {
            return (
              <>
                <Box
                  height={{
                    xs: "450px",
                    lg: "580px",
                    xl: "600px",
                    "2xl": "800px",
                  }}
                  bgImage={"linear-gradient(180deg, #002555,#496385)"}
                  style={{ boxShadow: "20px 0px 259px 50px #002555" }}
                >
                  <Image
                    source={{ uri: item?.images?.value }}
                    size="100%"
                    // position="absolute"
                    // opacity={0.6}
                    // zIndex={-1}
                  />
                </Box>
                <Box
                  position={"absolute"}
                  zIndex={10}
                  justifyContent={"flex-start"}
                  alignContent={"end"}
                  width={"100%"}
                  maxH={"210px"}
                  minH={"50px"}
                  mt={"-3.2rem"}
                  bgImage={"linear-gradient( #1a6194, #1a6194b3)"}
                  py="6px"
                  px="10px"
                >
                  <Text fontSize={"14px"} color={"white"}>
                    {item?.title?.value}
                  </Text>
                </Box>
              </>
            );
          })}
        </Carousel>
        <Box
          position={"absolute"}
          alignItems={"end"}
          zIndex={100000}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"flex-end"}
          alignContent={"end"}
          width={"95%"}
          mt={"-8rem"}
        >
          <UpcomingEvent events_data={events_data} />
        </Box>

        <Box flex={1}>
          <LatestNews latest_data={latest_data} />
        </Box>

        {mission_loading ||
        vision_loading ||
        objective_loading ||
        authority_loading ? (
          <HomePageLoading />
        ) : (
          <div>
            <MissionPage mission_data={mission_data} />
            <VisionPage vision_data={vision_data} />
            <ObjectivePage objective_data={objective_data} />
            <AuthorityPage
              // title="Authority"
              // description1="As per section 4 & 5 of the Tamil Nadu Local Fund Audit Act, 2014 (Act 24 of 2014), the Director of Local Fund Audit is empowered statutory authority to audit the accounts of local authorities and local funds."
              authority_data={authority_data}
            />
          </div>
        )}
      </div>
    </>
  );
};
export default HomePage;
