import React from "react";
import {
  Box,
  Center,
  Stack,
  Image,
  Text,
  VStack,
  HStack,
  Hidden,
  Pressable,
} from "native-base";
import FooterMapImage from "@assets/images/footer_map_logo.png";
import Logo from "@assets/images/governmentlogo.png";
import { SlLocationPin } from "react-icons/sl";
import { AiOutlinePhone } from "react-icons/ai";
import { Icons } from "@helpers/constants";
import { useTranslation } from "react-i18next";
import { IoIosArrowForward, IoMdMail } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";

import moment from "moment";
const FooterPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const currentYear=moment().year();
  
  return (
    <Box backgroundColor="#EAEAEA" justifyContent="center" flex={1}>
      <VStack space={5}>
        <Stack
          display={"flex"}
          flexDirection={{ xs: "column", lg: "row" }}
          justifyContent={"space-between"}
          alignItems="center"
          space={{ xs: "20px" }}
        >
          <Box
            // padding="32px"
            justifyContent="center"
            width={{xs:"100px",lg:"419px",xl:"419px","2xl":"419px"}}
            height="230px"
          >
            <Hidden only={["xs", "md"]}>
              <Image
                position={"absolute"}
                source={{ uri: FooterMapImage }}
                size="full"
                resizeMode="cover"
              />
            </Hidden>
            <Box
              width={{ lg: "98px", xs: "76px" }}
              // height={{ lg: "97px", xs: "75px" }}
              ml={{ xs: "0rem", lg: "3rem" }}
              mt={{ xs: "0rem", lg: "3rem" }}
            >
              <VStack space={2}>
                <Pressable
                  onPress={() => {
                    history.push(ROUTES.HOME);
                  }}
                >
                  <Box width="85px" height="86px">
                    <Image
                      size={"full"}
                      source={{
                        uri: Logo,
                      }}
                    />
                  </Box>
                </Pressable>
                <Box
                  width={{ xs: "17rem", lg: "12rem" }}
                  ml={{ xs: "-5rem", lg: "0rem" }}
                >
                  <Text
                    width="300px"
                    fontWeight="500"
                    fontSize="var(--font-size-18)"
                    fontFamily="Montserrat-SemiBold"
                  >
                   {t("local_fund_audit_department")}
                  </Text>
                  <Text fontSize="var(--font-size-13)" fontFamily={"Montserrat-Medium"}>
                    {t("government_of_tamil_nadu")}
                  </Text>
                </Box>
              </VStack>
            </Box>
          </Box>
          {/* important-links */}
          <Box >
            <Text fontFamily={"Montserrat-SemiBold"}>{t("quick_links")}</Text>
            {/* <a href="https://yas.nic.in/" target="_blank">
              <Text>
                <HStack alignItems={"center"}>
                  <IoIosArrowForward />
                  {t("ministry_of_youth_affairs_and_sports")}
                </HStack>
              </Text>
            </a> */}
            {/* <a
              href="http://www.sportsauthorityofindia.nic.in/sai/"
              target="_blank"
            >
              <Text>
                <HStack alignItems={"center"}>
                  <IoIosArrowForward />
                  {t("sports_authority_of_india")}
                </HStack>
              </Text>
            </a> */}
            <a href="https://www.tn.gov.in/" target="_blank">
              <Text fontFamily="Montserrat-Medium">
                <HStack alignItems={"center"}>
                  <IoIosArrowForward />
                  {t("government_of_tamil_nadu")}
                </HStack>
              </Text>
            </a>
            {/* <a href="https://www.tnpesu.org/" target="_blank">
              <Text>
                <HStack alignItems={"center"}>
                  <IoIosArrowForward />
                  {t("tamil_nadu_physical_education_and_sports_university")}
                </HStack>
              </Text>
            </a> */}
            <a href="/admin" target="_blank">
              <Text fontFamily="Montserrat-Medium">
                <HStack alignItems={"center"}>
                  <IoIosArrowForward />
                  {t("administration_login")}
                </HStack>
              </Text>
            </a>
          </Box>
          {/* footer-contents */}
          <Box
            alignItems={{ base: "start", xs: "center", lg: "start" }}
            padding={{ lg: "2rem" }}
          >
            <HStack space={5}>
              {/* {Icons.map((e) => {
                return (
                  <Box
                    width="25px"
                    height="25px"
                    backgroundColor="#002555"
                    rounded="20px"
                    cursor="pointer"
                    alignItems="center"
                  >
                    <span className="footer_icon_list">
                      <Center>{e.icon}</Center>
                    </span>
                  </Box>
                );
              })} */}
            </HStack>
            <Box flex={1}>
              <VStack
                space={4}
                alignItems={{ base: "start", xs: "center", lg: "start" }}
              >
                <HStack space={2} alignItems="center">
                  <SlLocationPin size={18} />
                  <Text
                    width="320px"
                    fontFamily="Montserrat-Medium"
                    color="#131313"
                    fontWeight="100"
                    marginTop="10px"
                  >
                    {t("address")}
                  </Text>
                </HStack>
                <HStack space={2}>
                  <IoMdMail size={18} />
                  <a href={`mailto:${"emailAddress"}`}>
                    <Text fontFamily="Montserrat-Medium">
                      tnlfa@yahoo.co.in
                    </Text>
                  </a>
                </HStack>
                {/* <HStack space={2}>
                  <AiOutlinePhone
                    style={{ transform: "rotate(87deg)" }}
                    size={18}
                  />
                  <a href={`tel:${"Mobile No"}`}>
                    <Text fontFamily="Helvetica"></Text>
                  </a>
                </HStack> */}
              </VStack>
            </Box>
          </Box>
        </Stack>
        <Box
          width="100%"
          height="3rem"
          justifyContent="center"
          textAlign="center"
          backgroundColor="#323232"
        >
          <Stack
            justifyContent={"space-between"}
            display={"flex"}
            flexDirection={[
              "column",
              "column",
              "row",
              "row",
              "row",
              "row",
              "row",
            ]}
            alignItems={"center"}
            space={{ xs: "5px" }}
            paddingLeft={{
              base: "20px",
              lg: "60px",
              xl: "60px",
              "2xl": "60px",
            }}
            paddingRight={{
              base: "20px",
              lg: "60px",
              xl: "60px",
              "2xl": "60px",
            }}
          >
            <Text width="300px" fontSize={"12px"} color="white">
              © {currentYear} Copyright: Local Fund Audit Department.
            </Text>
            <HStack gap={2} alignItems={"center"}>
              <Text color="#ffffffb5" fontSize="12px">
                Powered by
              </Text>
              <a href="https://vertace.com/" target="_blank">
                <img
                  src="https://blr1.vultrobjects.com/v-agrigl-assets/vertace_logo.png"
                  alt="vertace-logo"
                  className="vertace-logo"
                />
              </a>
            </HStack>
          </Stack>
        </Box>
      </VStack>
    </Box>
  );
};

export default FooterPage;
