import { Card } from "antd";
import { Box, Circle, Text } from "native-base";
import React, { useEffect, useState } from "react";
import PensionImage from "@assets/images/pension.png";
import GratuityTable from "./gratuity_table";
import { get_cms_pension_details_query } from "@services/redux/slices/graphql/graphql_cms_pension_details_query";
import { FilterItems } from "@helpers/constants";
import { useDispatch } from "react-redux";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import PageLoading from "../about_us/page_loading";

const PensionDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [pension_data, setPensionData] = useState([]);
  const { items: pension_items, loading: pension_loading } = useDynamicSelector(
    "get_cms_pension_details"
  );

  const get_pension_details_query = () => {
    let keys = [{ key: "get_cms_pension_details", loading: true }];
    let variables = {
      json: {
        system_cms_key: "pension_details",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_pension_details_query, variables));
  };

  useEffect(() => {
    get_pension_details_query();
  }, []);
  useEffect(() => {
    let values = FilterItems(pension_items);
    setPensionData(values.sort((a, b) => a?.order?.value - b?.order?.value));
  }, [pension_items]);
  const Contents = [
    {
      title: "PENSION AND OTHER RETIREMENT BENEFITS",
      heading: "Benefits at a glance:",
      subtitle: "1.PENSION:",
      text1:
        "The Director of LF Audit is the pension authorising and payment authority for local body employees on completion of service based on the nature of retirement, duration of service and mode of retirement from the respective pension funds under the relevant pension rules.",
      text2:
        "A minimum qualifying service of 10 years is necessary for eligibility for pension.Pension is calculated at 50% of pay last drawn.For qualifying service of 30 years, full pension is eligible.The minimum pension w.e.f. 1-1-2006 is Rs.3,050/-",
    },
  ];
  const Content1 = [
    {
      subtitle: "2.GRATUITIES :",
      text1:
        "(i)Service gratuity is admissible for those who have put in less than 10 years of qualifying service;",
      text2:
        "(ii)In the event of death in harness, death gratuity is admissible to a minimum of 2 times of monthly emoluments and to a maximum of 33 times of monthly emoluments subject to a maximum of Rs.10.00 lakhs.",
      text3:
        "iii)Retirement gratuity is granted to one who has completed 5 years of qualifying service at the rate of ½ of emoluments for each completed six month period subject to a maximum of 16½ times of monthly emoluments.",
    },
  ];
  const Content2 = [
    {
      subtitle: "GRATUITY",
      text1:
        "5.3If a Government employee dies while in service his family is entitled to the gratuity as follows:-",
    },
  ];
  const Content3 = [
    {
      subtitle: "3.FAMILY PENSION",
      text1:
        "The family pension is allowed to a family of the employee in the event of death of an employee either while in service or after retirement.In the case of death while in service, one year continuous service in a pensionable post is required to become eligible for family pension.Minimum family pension is Rs.3,050/- w.e.f. 1-1-2006.",
    },
    {
      subtitle: "4.COMMUTATION OF PENSION :",
      text1:
        "Pensioners are eligible to commute 1/3 portion of pension into a lumpsum payment.The commutation of pension shall be restored after 15 years from the date of payment of the commutation amount.",
    },
  ];
  return (
    <Box variant="contents_box">
      <Card
        style={{
          backgroundColor: "#fcfcfc",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        }}
      >
        <Box variant="heading_box">
          <Circle bg={"#fcfcfc"} size={"140px"}>
            <img src={PensionImage} width={"65px"} />
          </Circle>
          <Text variant={"pension_details_head_text"}>
            {t("pension_details")}
          </Text>
        </Box>
        <Box
          style={{
            width: "100%",
            gap: "20px",
            marginTop: "20px",
          }}
        >
          {pension_loading ? (
            <PageLoading />
          ) : (
            <>
              {pension_data.map((e) => {
                return (
                  <>
                    <Text variant={"pension_details_title_text"}>
                      PENSION AND OTHER RETIREMENT BENEFITS
                    </Text>
                    <Text variant={"pension_details_heading_text"}>
                      {e?.title?.value}
                    </Text>

                    <Text
                    // variant={"pension_details_subtitle_text"}
                    >
                      {ReactHtmlParser(e?.content?.value)}
                    </Text>
                    {/* <Text
                 variant={"pension_details_text"}
                >
                  {e.text1}
                </Text>
                <Text
                variant={"pension_details_text"}
                >
                  {e.text2}
                </Text> */}
                  </>
                );
              })}
            </>
          )}
        </Box>
        {/* <Box
          style={{
            width: "100%",
            gap: "20px",
            marginTop: "20px",
          }}
        >
          {Content1.map((e) => {
            return (
              <>
                <Text
                 variant={"pension_details_subtitle_text"}
                >
                  {e.subtitle}
                </Text>
                <Text
                variant={"pension_details_text"}
                >
                  {e.text1}
                </Text>
                <Text
                variant={"pension_details_text"}
                >
                  {e.text2}
                </Text>
                <Text
                variant={"pension_details_text"}
                >
                  {e.text3}
                </Text>
              </>
            );
          })}
        </Box>
        <Box
          style={{
            width: "100%",
            gap: "20px",
            marginTop: "20px",
          }}
        >
          {Content2.map((e) => {
            return (
              <>
                <Text
                variant={"pension_detail_sub_head"}
                >
                  {e.subtitle}
                </Text>
                <Text
                variant={"pension_details_text"}
                >
                  {e.text1}
                </Text>
                <Box padding={"20px"}>
                  <GratuityTable />
                </Box>
              </>
            );
          })}
        </Box>
        <Box
          style={{
            width: "100%",
            gap: "20px",
            marginTop: "20px",
          }}
        >
          {Content3.map((e) => {
            return (
              <>
                <Text
                   variant={"pension_detail_sub_head"}
                >
                  {e.subtitle}
                </Text>
                <Text
                variant={"pension_details_text"}
                >
                  {e.text1}
                </Text>
              </>
            );
          })}
        </Box> */}
      </Card>
    </Box>
  );
};
export default PensionDetails;
