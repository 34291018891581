import OrganizationStaff from "@views/components/organization_staff_setup/organization_staff";
import { Box } from "native-base";
import React from "react";

const OrganizationStaffPage = () => {
  return (
    <Box>
      <OrganizationStaff />
    </Box>
  );
};
export default OrganizationStaffPage;
