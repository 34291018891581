import { Card } from "antd";
import { Box, Circle, Text } from "native-base";
import React from "react";
import AuditStatusTable from "./audit_status_table";
import StatusImage from "@assets/images/Audit Status.png";
import { useTranslation } from "react-i18next";

const AuditStatus = () => {
  const {t}=useTranslation();
    return (
    <Box variant="contents_box">
      <Card
        style={{
          backgroundColor: "#fcfcfc",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        }}
      >
        <Box
          variant="heading_box"
        >
          <Circle bg={"#fcfcfc"} size={"140px"}>
            <img src={StatusImage} width={"65px"} />
          </Circle>
          <Text
           variant={"audit_status_head_text"}
          >
            {t("audit_status")}
          </Text>
        </Box>
        <Box alignItems={"center"} py={"25px"}>
          <Text
          variant={"audit_status_subtitle_text"}
            // underline
          >
            Statement showing the details of Accounts Audited during 2013-14 (Up
            to 31.03.2014)
          </Text>
        </Box>
        <Box padding={"20px"}>
          <AuditStatusTable />
        </Box>
      </Card>
    </Box>
  );
};
export default AuditStatus;
