import { Icons, Options } from "@helpers/constants";
import { Box, Center, HStack, Text } from "native-base";
import React from "react";
import { Pressable } from "react-native";
import FontIncreaseIcon from "@assets/images/font-increase.png";
import FontDecreaseIcon from "@assets/images/font-decrease.png";
import FontNormalIcon from "@assets/images/font-normal.png";
import SwitchSelector from "react-switch-selector";
import { useTranslation } from "react-i18next";

import { Route } from "react-router-dom/cjs/react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { MdOutlineVibration } from "react-icons/md";
import { changeLanguage } from "@src/i18n";
import {
  decreaseFontSize,
  fontSizeSelector,
  increaseFontSize,
  setDefaultFontSize,
} from "@services/redux/slices/font";
import { useDispatch, useSelector } from "react-redux";

const Banner = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { scale, font_size_change } = useSelector(fontSizeSelector);
  console.log("font_size_change", scale, font_size_change);

  const initialSelectedIndex = Options(t).findIndex(
    ({ value }) => value === "en"   
  );

  const onChange = (newValue) => {
    if(newValue==="tn"){
      document.documentElement.style.setProperty(
        "--menu-font",
        `10px`
      );
    }else{
      document.documentElement.style.setProperty(
        "--menu-font",
        `14px`
      );
    }
    console.log("newValue", newValue);
    changeLanguage(newValue);
  };
  const handleScreen = () => {
    history.push(ROUTES.SCREEN_READER_ACCESS);
  };
  return (
    <Box
      w={"100%"}
      h={"30px"}
      justifyContent={"center"}
      backgroundColor={"white"}
    >
      <HStack
        justifyContent={"space-between"}
        alignItems="center"
        ml={{ lg: "35px", xs: "15px" }}
        mr={{ lg: "46px", xs: "15px" }}
      >
        <Box>
          <HStack space={2} alignItems={"center"}>
            {/* {Icons?.map((e) => {
              return (
                <Box
                  w="25px"
                  h={"25px"}
                  backgroundColor={"#002555"}
                  rounded={"20px"}
                  cursor={"pointer"}
                  alignItems={"center"}
                >
                  <span style={{ marginTop: "3px" }}>{e.icon}</span>
                </Box>
              );
            })} */}
          </HStack>
        </Box>
        <Box>
          <HStack space={2} alignItems="center">
            <Box
              width="22px"
              height="20px"
              background="gray.300"
              borderRadius="2"
              textAlign="center"
              lineHeight="0px"
            >
              <Center>
                <Pressable
                  onPress={
                    font_size_change >= -2
                      ? (e) => {
                          e.preventDefault();
                          dispatch(decreaseFontSize());
                        }
                      : ""
                  }
                >
                  <img
                    src={FontDecreaseIcon}
                    alt={"FontDecrease"}
                    className={"icons-list"}
                  />
                </Pressable>
              </Center>
            </Box>
            <Box
              width="22px"
              height="20px"
              background="gray.300"
              borderRadius="2"
              textAlign="center"
              lineHeight="0px"
            >
              <Center>
                <Pressable
                  onPress={(e) => {
                    console.log("FontNormal",e);
                    e.preventDefault();
                    dispatch(setDefaultFontSize());
                  }}
                >
                  <img
                    src={FontNormalIcon}
                    alt={"FontNormal"}
                    className={"icons-list"}
                  />
                </Pressable>
              </Center>
            </Box>
            <Box
              width="22px"
              height="20px"
              background="gray.300"
              borderRadius="2"
              textAlign="center"
              lineHeight="0px"
            >
              <Center>
                <Pressable
                  onPress={
                    font_size_change <= 2
                      ? (e) => {
                          e.preventDefault();
                          dispatch(increaseFontSize());
                        }
                      : ""
                  }
                >
                  <img
                    src={FontIncreaseIcon}
                    alt={"FontIncrease"}
                    className={"icons-list"}
                  />
                </Pressable>
              </Center>
            </Box>
            <Box
              width="22px"
              height="20px"
              background="gray.300"
              borderRadius="2"
              textAlign="center"
              lineHeight="0px"
            >
              <Center>
                <Pressable onPress={handleScreen}>
                  <span style={{ textDecoration: "none", color: "black" }}>
                    <MdOutlineVibration size={15} className="vibrate-icon" />
                  </span>
                </Pressable>
              </Center>
            </Box>
            <Box width="6rem" height="1.5rem" fontSize="10px">
              <SwitchSelector
                className="switch-button"
                onChange={onChange}
                options={Options(t)}
                initialSelectedIndex={initialSelectedIndex}
                wrapperBorderRadius={10}
                optionBorderRadius={10}
                fontSize="7px"
              />
            </Box>
          </HStack>
        </Box>
      </HStack>
    </Box>
  );
};
export default Banner;
