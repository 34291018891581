import { Skeleton } from "antd";
import { Box, Card, Center, HStack, Hidden, VStack } from "native-base";
import React from "react";

const HomePageLoading = () => {
  return (
    <Box>
      <Hidden from="base" till="md">
        <VStack space={"20px"}>
          {/* menubar */}
          <Box>
            <Center>
              {/* <HStack space="25px">
                <Skeleton.Avatar active />
                <Skeleton.Button active className="menu-button" />
                <Skeleton.Button active className="menu-button" />
                <Skeleton.Button active className="menu-button" />
                <Skeleton.Button active className="menu-button" />
                <Skeleton.Button active className="menu-button" />
                <Skeleton.Button active className="menu-button" />
                <Skeleton.Button active className="menu-button" />
                <Skeleton.Avatar active />

              </HStack> */}
            </Center>
          </Box>
          {/*homepage image */}
          <Box>
            {/* <Skeleton.Image active style={{ width: "100%", height: "400px" }} /> */}
          </Box>
          {/* homepage */}
          <Box padding={"20px"}>
            <VStack space={"20px"}>
              <HStack space={"20px"} alignItems={"center"}>
                <Skeleton.Image
                  active
                  style={{ width: "300px", height: "200px" }}
                />
                <Skeleton active></Skeleton>
              </HStack>
              <HStack space={"20px"} alignItems={"center"}>
              
                <Skeleton active></Skeleton>
                <Skeleton.Image
                  active
                  style={{ width: "300px", height: "200px" }}
                />
              </HStack>
              <HStack space={"20px"} alignItems={"center"}>
                <Skeleton.Image
                  active
                  style={{ width: "300px", height: "200px" }}
                />
                <Skeleton active></Skeleton>
              </HStack>
              <HStack space={"20px"} alignItems={"center"}>
              
                <Skeleton active></Skeleton>
                <Skeleton.Image
                  active
                  style={{ width: "300px", height: "200px" }}
                />
              </HStack>
            </VStack>
          </Box>

          {/* <Box px="40px">
            <Skeleton active />
          </Box> */}
        </VStack>
      </Hidden>
      <Hidden from="md" till="3xl">
        <VStack space={"20px"}>
          {/* menubar */}
          <Box>
            <Center>
              {/* <HStack space="25px">
                <Skeleton.Button active />
                <Skeleton.Button active />
                <Skeleton.Button active />
              </HStack> */}
            </Center>
          </Box>
          {/*homepage image */}
          <Box>
            {/* <Skeleton.Image active style={{ width: "100%", height: "400px" }} /> */}
          </Box>
          {/* homepage */}
          <Box padding={"20px"}>
            <VStack space={"20px"}>
              <VStack space={"20px"} alignItems={"center"}>
                <Skeleton.Image
                  active
                  style={{ width: "300px", height: "200px" }}
                />
                <Skeleton active></Skeleton>
              </VStack>
              <VStack space={"20px"} alignItems={"center"}>
                <Skeleton active></Skeleton>
                <Skeleton.Image
                  active
                  style={{ width: "300px", height: "200px" }}
                />
              </VStack>
              <VStack space={"20px"} alignItems={"center"}>
                <Skeleton.Image
                  active
                  style={{ width: "300px", height: "200px" }}
                />
                <Skeleton active></Skeleton>
              </VStack>
              <VStack space={"20px"} alignItems={"center"}>
                <Skeleton active></Skeleton>
                <Skeleton.Image
                  active
                  style={{ width: "300px", height: "200px" }}
                />
              </VStack>
            </VStack>
          </Box>
        </VStack>
      </Hidden>
    </Box>
  );
};
export default HomePageLoading;
