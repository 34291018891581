import React, { useEffect, useState } from "react";
import { Input } from "antd";
import PanelItemValue from "./panel_item_value";

const { Search } = Input;
const PanelItem = (props) => {
  const { panel_list, setMenuList, menuList } = props;
  const [search_string, set_string_string] = useState("");

  useEffect(() => {
    setMenuList(panel_list);
  }, []);

  const getSearch = () => {
    return (
      <Search
        placeholder="search"
        allowClear
        // value={search_string}
        onSearch={(value) => {
          onSearch(value, panel_list);
        }}
      />
    );
  };
  const onSearch = (value, list) => {
    console.log("search_string", value);
    set_string_string(value);
    console.log("search", value);
    list = list.filter((list_item) => {
      console.log("list_item", list_item);
      return list_item.key.toLowerCase().includes(value.toLowerCase());
    });
    console.log("list", list);
    setMenuList(list);
  };

  let search = getSearch();
  let panel_menu = menuList?.map((item) => {
    return <PanelItemValue item={item} />;
  });

  panel_menu = [search].concat(panel_menu);
  console.log("panel_list", panel_list, panel_menu);
  return panel_menu;
};
export default PanelItem;
