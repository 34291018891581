import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_system_cms_field_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "systemCMSFieldList",
  initialState,
  reducers: {
    _get_system_cms_field: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_system_cms_field_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_system_cms_field_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = [];
    },
  },
});

const {
  _get_system_cms_field,
  _get_system_cms_field_success,
  _get_system_cms_field_failure,
} = slice.actions;

export const getsystemCMSFieldListSelector = (state) =>
  state.systemCMSFieldList;

export const getsystemCMSFieldListReducer = slice.reducer;

export function get_system_cms_field(variables) {
  return async (dispatch) => {
    dispatch(_get_system_cms_field());
    try {
      const response = await QueryRequest(
        get_system_cms_field_query,
        variables,
        dispatch
      );
      if (
        response?.data?.getSystemCmsFields &&
        !response?.data?.getSystemCmsFields.error
      ) {
        dispatch(
          _get_system_cms_field_success(response?.data?.getSystemCmsFields)
        );
      } else if (response?.data?.getSystemCmsFields?.error) {
        dispatch(
          _get_system_cms_field_failure(
            response?.data?.getSystemCmsFields.error
          )
        );
      }
    } catch (error) {
      dispatch(_get_system_cms_field_failure(error));
    }
  };
}
