import PensionForms from "@views/components/pension_forms/`/pension_forms";
import { Box } from "native-base";
import React from "react";

const PensionFormsPage = () => {
  return (
    <Box>
      <PensionForms />
    </Box>
  );
};
export default PensionFormsPage;
