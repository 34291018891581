import { Card } from "antd";
import { Box, Circle, Text } from "native-base";
import React, { useEffect, useState } from "react";
import SpecialImage from "@assets/images/special rules.png";
import { get_cms_special_rules_query } from "@services/redux/slices/graphql/graphql_cms_special_rules_query";
import { FilterItems } from "@helpers/constants";
import { useDispatch } from "react-redux";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import PageLoading from "../about_us/page_loading";

const SpecialRules = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [special_data, setSpecialData] = useState([]);
  const { items: special_items, loading: special_loading } = useDynamicSelector(
    "get_cms_special_rules"
  );

  const get_special_rules_query = () => {
    let keys = [{ key: "get_cms_special_rules", loading: true }];
    let variables = {
      json: {
        system_cms_key: "special_rules",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_special_rules_query, variables));
  };
  useEffect(() => {
    get_special_rules_query();
  }, []);

  useEffect(() => {
    let values = FilterItems(special_items);
    setSpecialData(values);
  }, [special_items]);
  const SubordinateContent = [
    {
      subtitle:
        "SPECIAL RULES FOR TAMIL NADU LOCAL FUND AUDIT SUBORDINATE SERVICE RULES",
      text1: "1) Constitution:",
      text2:
        "  The Service shall consist of the following categories in the Local Fund Audit Department and in the State Trading Schemes Department, namely:",
      text3: "  Category 1: Deputy Inspector Category",
      text4: "  Category 2: Assistant Inspector",
      text5: "2) Appointment:",
      text6:
        "  (1) Appointment to the categories specified in column (1) of the Table below shall be made by the methods specified in the corresponding entries in column (2) thereof:-",
      text7: "  THE TABLE Categories Method of Appointment (1) (2)",
      text8:
        "  Category I : Deputy Inspector By promotion from among the holders of Category 2.",
      text9:
        "  Assistant Inspector Vacancies in the Category I Deputy Inspector shall be filled up in the strict order of seniority from among the holders of Category 2. Assistant Inspector",
      text10:
        "  Provided, that the second of a cycle of every two vacancies shall be filled by a suitable Assistant Inspector who has passed the Subordinate Accounts Services Examination Part I and II in full in preference to his senior who has not passed the said examination. If a suitable Assistant Inspector is not available such a vacancy also shall be filled by the appointment of the next suitable Assistant Inspector who has not passed the Subordinate Accounts Service Examination Part I and II in full.",
      text11: " Category 2: Assistant Inspector",
      text12:
        "   1. By direct recruitment; or 2(1) By recruitment by transfer from among the holder of the posts of Junior Assistant and Steno-Typists or Typist in the Local Fund Audit Department and in the State Trading Scheme Department.",
      text13:
        " Category 2: Assistant Inspector 2(2) The ratio for appointment, by direct recruitment and by recruitment by transfer, to Category 2. Assistant Inspector shall be 4:1",
      text14:
        " (2) Provided that appointment by recruitment by transfer shall be made from among the holders of the post of Junior Assistant and the post of Steno Typist and Typist in the ratio of 2 : 1",
      text15: "(3) Appointment to Category 2.",
      text16:
        "   Assistant Inspector, by recruitment by transfer from among the holders of the posts of Junior Assistant and Steno Typists or Typist, shall be on grounds of merit and ability. Seniority being considered only where merits and ability are approximately equal. 3. Reservation of appointments. - The rule of reservation of appointments shall apply for direct recruitment to the post of Assistant Inspector in Category 2.",
      text17: "4. (1) Appointing Authority.",
      text18:
        " - Appointments to the categories specified in column (1) of the Table below shall be made by authorities specified in the corresponding entries in column ( 2 ) thereof :-- THE TABLE Categories Appointing Authorities",
      text19:
        " (1) (2) Category 1 Deputy Inspector Examiner of Local Fund Accounts",
      text20:
        " Category 2 Assistant Inspector Deputy Examiner of Local Fund Accounts in the Local Fund Audit Department",
      text21: " (2) Posting and Transfer:",
      text22:
        " (i) The Deputy Examiner of Local Fund Accounts shall be the authority competent to make transfer of Assistant Inspectors under Category 2, from the jurisdiction of one Assistant Examiner of Local Fund Accounts to that of another and the Assistant Examiner of Local Fund Accounts concerned be the authority competent to make transfers within his jurisdiction",
      text23:
        " (ii) The Chief Auditor, State Trading Schemes Department shall issue posting orders in respect of candidates appointed to Category I from the annual list of approved candidates and allotted to the State Trading Schemes Department",
      text24:
        " (iii) The Deputy Examiner of Local Fund Accounts shall issue posting orders in respect of candidates appointed to Category 2 from the annual list of approved candidates and allotted to the State Trading Schemes Department.",
      text25: "5) Qualification:",
      text26:
        "  (1) No person shall be eligible for appointment to Category 2 Assistant Inspector by the methods specified in column (2) of the Table below unless he possesses the qualifications specified in the corresponding entries in column (3) thereof :-",
      text27:
        "  THE TABLE Categories Methods of Appointment Qualification (1) (2) (3) Category 2 Assistant Inspector By direct recruitment Must possess the degree of B.A. or B.Sc. or B.Com. of any University recognised by the University Grants Commission for the purpose of its Grants By recruitment by transfer Must have passed the Departmental Tests prescribed, from time to time. Must be an approved probationer in the post of Junior Assistant or Typist in the Local Fund Audit Department and in the Chief Auditor, State Trading Schemes Department under Categories 14 and 17 respectively of the Tamil Nadu Ministerial Service.",
      text28:
        "  (2) Age.- No person shall be eligible for appointment, by direct recruitment, to Category 2, Assistant Inspector if he has completed 30 years of age on the first day of July of the year in which the selection for such appointment is made.",
      text29:
        "6) Departmental Unit. - For purpose of appointment to the respective categories included in the Service, the Local Fund Audit Department and the State Trading Schemes Department shall be a single unit.",
      text30:
        "7) Preparation of annual list of approved candidates :- (1) For the purpose of preparation of the annual list of approved candidates for appointment by promotion or by recruitment by transfer to the categories in the Service, the crucial date on which the candidates should have acquired the prescribed qualification shall be the 15th March of each year.",
      text31:
        "  (2) List of approved candidates for filling up of the vacancy in Category 2. Assistant Inspector by direct recruitment, in the Departmental Unit referred to in Rule 5 shall be prepared annually by the Tamil Nadu Public Service Commission based on the common estimate of vacancies to be furnished by the Deputy Examiner of Local Fund Accounts.",
      text32:
        "8. Training - (1) Every person selected by the Tamil Nadu Public Service Commission for appointment to Category 2. Assistant Inspector, by direct recruitment, shall, subject to such conditions and for such periods as the State Government may, from time to time, lay down undergo the course of training or instructions, prescribed by them unless he has been specially exempted by the State Government from undergoing such training or instructions and he shall be paid at the minimum of the time scale of pay applicable to the category, from time to time. Any person who has satisfactorily completed the said course of training or instructions is subsequently appointed to that category, the period of such training or instructions shall count for probation, leave and increment. (2) Every person appointed to the category, on completion of the course of training or instructions, shall serve the State Government for a period of not less that five years from the date of completion of the training. If he does not serve the State Government for a period of five years he shall refund to the State Government the entire money spent on him during the course of training or instructions.",
      text33:
        "9. Tests: - (1) Every person appointed to category 2. Assistant Inspector, by direct recruitment, shall within a period of five years from the date of joining duty, pass the following departmental tests, namely:-",
      text34:
        " 1) The Accounts Test for Subordinate Officers, Part I (with books)",
      text35: " 2) The District Office Manual (with books)",
      text36:
        " 3) Local Fund Audit Departmental Tests: i) Local Acts and the Rules framed there under ( with books 4) ii) Commercial Book-Keeping ( without books )",
      text37:
        " (2) A person shall not be deemed to have satisfactorily completed his probation and shall not be entitled to appointment as a full member of service or be eligible for increments, except the first increment, in the time scale of pay applicable to him unless and until he has passed the tests referred to in Sub Rule (1).",
      text38:
        " Such ineligibility for increments shall not have the effect of postponing his future increments after he has passed the prescribed tests. (3) If he fails to pass the tests as required in sub-rule (1), the appointing authority shall forthwith by order terminate his probation and (1) discharge him from service if he was appointed by direct recruitment or (2) revert him to his parent office or department if he was appointed by recruitment by transfer:",
      text39:
        " Provided that the Examiner of Local Fund Accounts or the Chief Auditor, State Trading Schemes Department shall, at his discretion, appoint such of the Assistant Inspectors, who are appointed by direct recruitment in the Local Fund Audit Department or State Trading Schemes Department and who fails to pass the prescribed test or tests as Junior Assistants and the Junior Assistants so appointed shall take their rank below the last Junior Assistant working at the time of issue of orders of appointment and they shall pass all the four papers of the Local Fund Audit Department Tests or the other prescribed tests in addition to the Departmental tests as the case may be for being promoted as Assistant Inspector.",
      text40:
        "  Explanation: Candidates who are holders of the Degree of B.Com. or B.Com (Hons.) of any University recognised by the University Grants Commission for the purpose of its grant and candidates who have passed the Government Technical Certificate Course Examination in Accountancy by the Higher Grade shall not be required to pass the subject Commercial Book Keeping included in the Local Fund Audit Department Tests",
      text41:
        "10) Probation: Subject to the provisions of sub-rule (2) of Rule 8, every person appointed to category 2. Assistant Inspector, by direct recruitment, shall, with effect on and from the date of joining duty, be on probation for a total period of two years on duty within a continuous period of three years.",
      text42:
        " (2) Every person appointed to category 2. Assistant Inspector by recruitment by transfer from among the Junior Assistants and Steno-Typists or Typists in the Local Fund Audit Department and in the State Trading Schemes Department shall, with effect on and from the date of joining duty, be on probation for a total period of one year on duty within a continuous period of two years.",
    },
  ];
  return (
    <Box variant="contents_box">
      <Card
        style={{
          backgroundColor: "#fcfcfc",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        }}
      >
        <Box variant="heading_box">
          <Circle bg={"#fcfcfc"} size={"140px"}>
            <img src={SpecialImage} width={"65px"} />
          </Circle>
          <Text variant={"special_rules_heading_text"}>
            {t("special_rules")}
          </Text>
        </Box>
        <Box
          style={{
            width: "100%",
            gap: "20px",
            marginTop: "10px",
          }}
          p="20px"
        >
          {special_loading ? <PageLoading/> :(
            <>
          {special_data?.map((e) => {
            return (
              <>
                <Text>{ReactHtmlParser(e?.content?.value)}</Text>
              </>
              // <>
              //   <Text variant={"special_rules_subheading_text"}>
              //     {e.subtitle}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text1}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text2}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text3}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text4}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text5}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text6}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text7}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text8}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text9}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text10}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text11}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text12}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text13}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text14}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text15}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text16}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text17}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text18}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text19}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text20}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text21}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text22}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text23}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text24}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text25}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text26}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text27}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text28}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text29}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text30}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text31}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text32}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text33}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text34}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text35}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text36}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text37}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text38}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text39}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text40}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text41}
              //   </Text>
              //   <Text variant={"special_rules_description_text"}>
              //     {e.text42}
              //   </Text>
              // </>
            );
          })}
           </>
          )}
        </Box>
      </Card>
    </Box>
  );
};
export default SpecialRules;
